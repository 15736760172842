export const tutorialSequenceKeys = [
  'main_searchbar',
  'main_marketPreview',
  'main_upPredictedStocks',
  'ai-explore/005930_graph'
];
export const tutorialSequenceMessages = [
  '종목 검색을 통해\n미래 주가흐름을 미리 파악하여\n올바른 투자의사결정에 참고해보세요',
  '인공지능AI의 증시 예측을 통해\n시장의 움직임을 미리 파악하여\n올바른 투자의사결정을 내려보세요',
  '인공지능AI가 선별한\n상승 예측 종목으로 투자에 참고해보세요',
  '클릭하여 미래 주가흐름과\n20일 기대수익률를 확인하고\n올바른 투자의사결정을 내려보세요'
];
export const keyCodes = {
  number: Array.from({ length: 10 }, (_, idx) => idx + 48),
  alphabet: Array.from({ length: 52 }, (_, idx) => idx + 65),
  space: 32
};
