import { useEffect, useRef } from 'react';

import Button from '@components/Button';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const dialogFadeIn = keyframes`
  from {
    transform: translateY(60px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;
const DialogWrapper = styled.dialog({
  border: 'none',
  '&[open]': {
    position: 'relative',
    borderRadius: '12px',
    width: '90%',
    maxWidth: '30rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    transform: 'translateY(0)',
    animation: `${dialogFadeIn} 500ms forwards`
  },
  '::backdrop': {
    pointerEvents: 'all',
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  },
  '& > *:not(:first-of-type, :last-of-type)': {
    marginTop: '1.5rem',
    marginBottom: '1.5rem'
  },
  '.dialog-header': {
    width: '100%',
    textAlign: 'center',
    paddingTop: '0.5rem',
    h1: {
      fontSize: '1.2rem'
    },
    h2: {
      fontSize: '1.2rem'
    },
    h3: {
      fontSize: '1.2rem'
    },
    h4: {
      fontSize: '1.2rem'
    },
    h5: {
      fontSize: '1.2rem'
    },
    h6: {
      fontSize: '1.2rem'
    }
  },
  '.dialog-body': {
    lineHeight: 1.5
  },
  '.dialog-footer': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    button: {
      flex: 1
    },
    'button:not(:last-of-type)': {
      marginRight: '0.5rem'
    }
  }
});

export default function Dialog({ open, close, children }) {
  const dialogRef = useRef();
  useEffect(() => {
    if (open) {
      dialogRef.current.showModal();
    } else {
      dialogRef.current.close();
    }
  }, [open]);
  useEffect(() => {
    dialogRef.current.addEventListener('close', () => {
      close();
    });
  }, []);

  return <DialogWrapper ref={dialogRef}>{children}</DialogWrapper>;
}

function DialogHeader({ children }) {
  return <div className='dialog-header'>{children}</div>;
}
function DialogBody({ children }) {
  return <div className='dialog-body'>{children}</div>;
}
function DialogFooter({ children }) {
  return <div className='dialog-footer'>{children}</div>;
}
Dialog.Header = DialogHeader;
Dialog.Body = DialogBody;
Dialog.Footer = DialogFooter;

function Alert({ open, close, title, children }) {
  const dialogRef = useRef();
  useEffect(() => {
    if (open) {
      dialogRef.current.showModal();
    } else {
      dialogRef.current.close();
    }
  }, [open]);
  useEffect(() => {
    dialogRef.current.addEventListener('close', () => {
      close();
    });
  }, []);
  return (
    <DialogWrapper ref={dialogRef}>
      <Dialog.Header>
        <h1>{title}</h1>
      </Dialog.Header>
      <Dialog.Body>{children}</Dialog.Body>
      <Dialog.Footer>
        <Button text='닫기' onClick={close} color='secondary' />
      </Dialog.Footer>
    </DialogWrapper>
  );
}
Dialog.Alert = Alert;
