/** @jsxImportSource @emotion/react */

import 'swiper/css';
import 'swiper/css/pagination';

import { Swiper, SwiperSlide } from 'swiper/react';

import Box from '@components/Box';
import Button from '@components/Button';
import KakaoLogin from '@sections/KakaoLogin';
import Page from '@components/Page';
import { Pagination } from 'swiper/modules';
import { css } from '@emotion/react';
import { useAuth } from '@contexts/AuthContext';
import { useColorScheme } from '@contexts/ColorSchemeContext';
import { useNavigate } from 'react-router-dom';

export default function IntroPage() {
  const navigate = useNavigate();
  const { isAuth } = useAuth();
  const handleClick = () => navigate('/main');
  return (
    <Page>
      <PreviewScreen />
      <IntroductionSlide />
      <Box hAlign='center' pt={1} pb={2} px={1} gap={1}>
        <Button
          ariaLabel='Discover our services'
          rounded
          onClick={handleClick}
          size='lg'
          text='서비스 둘러보기'
        />
        {!isAuth && <KakaoLogin />}
      </Box>
    </Page>
  );
}

function PreviewScreen() {
  return (
    <div
      css={css`
        width: 100%;
        position: fixed;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        height: 100%;
        background-color: #13182c;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        animation: intro-preview-show-and-hide 500ms 1s 1 forwards;
      `}
    >
      <figure
        css={css`
          opacity: 0;
          transform: translateY(60px);
          width: 200px;
          height: 200px;
          animation: intro-preview-logo 500ms 300ms 1 forwards;
        `}
      >
        <picture>
          <source
            srcSet='/assets/images/logo/logo-symbol.webp'
            width='200'
            height='200'
            type='image/webp'
          />
          <source
            srcSet='/assets/images/logo/logo-symbol.png'
            width='200'
            height='200'
            type='image/png'
          />
          <img
            alt='High Signal logo symbol'
            src='/assets/images/logo/logo-symbol.png'
            width='200'
            height='200'
            loading='lazy'
          />
        </picture>
      </figure>
    </div>
  );
}

function IntroductionSlide() {
  const { isDarkMode } = useColorScheme();
  return (
    <div
      css={css`
        width: 100%;
        max-width: 450px;
        @media (max-height: 50vw) {
          height: 100vh;
          display: flex;
          align-items: center;
        }
        .swiper-slide {
          position: relative;
          display: flex;
          align-items: center;
        }
        .swiper-slide picture {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .swiper-slide img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .swiper-pagination-bullet-active-prev {
          background: var(
            --swiper-pagination-bullet-inactive-color,
            ${isDarkMode ? '#fff' : '#000'}
          );
          opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
        }
        .swiper-pagination-bullet-active-next {
          background: var(
            --swiper-pagination-bullet-inactive-color,
            ${isDarkMode ? '#fff' : '#000'}
          );
          opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
        }
      `}
    >
      <Swiper
        pagination={{
          dynamicBullets: true
        }}
        modules={[Pagination]}
      >
        {Array.from({ length: 5 }).map((_, idx) => {
          const num = idx + 1;
          return (
            <SwiperSlide key={num}>
              <picture>
                <source
                  srcSet={`/assets/images/img/onboarding-small-${num}.webp 375w, 
          /assets/images/img/onboarding-medium-${num}.webp 430w, 
          /assets/images/img/onboarding-large-${num}.webp 1800w`}
                  sizes='(max-width: 375px) 375px, (max-width: 430px) 430px, 1800px'
                  width='375'
                  height='667'
                  type='image/webp'
                />
                <source
                  srcSet={`/assets/images/img/onboarding-small-${num}.png 375w, 
          /assets/images/img/onboarding-medium-${num}.png 430w, 
          /assets/images/img/onboarding-large-${num}.png 1800w`}
                  sizes='(max-width: 375px) 375px, (max-width: 430px) 430px, 1800px'
                  width='375'
                  height='667'
                  type='image/png'
                />
                <img
                  alt={`Introduction image ${num}`}
                  src={`/assets/images/img/onboarding-small-${num}.png`}
                  srcSet={`/assets/images/img/onboarding-small-${num}.png 375w, 
          /assets/images/img/onboarding-medium-${num}.png 430w, 
          /assets/images/img/onboarding-large-${num}.png 1800w`}
                  sizes='(max-width: 375px) 375px, (max-width: 430px) 430px, 1800px'
                  width='375'
                  height='667'
                  loading={idx > 0 ? 'lazy' : undefined}
                />
              </picture>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
