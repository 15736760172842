import { useLocation, useNavigate } from 'react-router-dom';

import RegisterModal from '@sections/RegisterModal';
import styled from '@emotion/styled';
import { useState } from 'react';

const NavWrapper = styled.nav((props) => ({
  zIndex: 100,
  width: '100%',
  height: '4.5rem',
  backgroundColor: 'white',
  borderTop: '1px solid #d9d9d9',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const NavButton = styled.button((props) => ({
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  margin: 0,
  border: 'none',
  outline: 'none',
  backgroundColor: 'transparent',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  span: {
    ':first-of-type': {
      position: 'relative',
      width: '1.5rem',
      height: '1.5rem',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      '> *': {
        position: 'absolute'
      },
      '> :first-of-type': {
        display: props.active ? '' : 'none'
      },
      '> :last-child': {
        display: props.active ? 'none' : ''
      }
    },
    ':last-child': {
      fontSize: '0.75rem',
      fontWeight: 500,
      marginTop: '0.25rem',
      color: props.active ? '#262626' : '#bfbfbf'
    }
  }
}));

export default function BottomTabs() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <NavWrapper>
        <NavButton type='button' active={currentPath === '/main'} onClick={() => navigate('/main')}>
          <span>
            <img src='/assets/images/icon/icon-nav-home-on.svg' alt='nav-home-icon' />
            <img src='/assets/images/icon/icon-nav-home.svg' alt='nav-home-icon' />
          </span>
          <span>홈</span>
        </NavButton>
        <NavButton type='button' active={currentPath === '/ai'} onClick={() => navigate('/ai')}>
          <span>
            <img src='/assets/images/icon/icon-nav-ai-on.svg' alt='nav-ai-icon' />
            <img src='/assets/images/icon/icon-nav-ai.svg' alt='nav-ai-icon' />
          </span>
          <span>AI 예측</span>
        </NavButton>
        <NavButton
          type='button'
          active={currentPath === '/favorite'}
          onClick={() => navigate('/favorite')}
        >
          <span>
            <i className='ico icon-on ico-like-list-on'></i>
            <i className='ico icon-off ico-like-list'></i>
          </span>
          <span>관심종목</span>
        </NavButton>
        <NavButton
          type='button'
          active={currentPath === '/signal'}
          onClick={() => navigate('/signal')}
        >
          <span>
            <i className='ico icon-on ico-signal-on'></i>
            <i className='ico icon-off ico-signal'></i>
          </span>
          <span>신호</span>
        </NavButton>
        <NavButton type='button' onClick={() => setModalOpen(true)}>
          <span>
            <img src='/assets/images/icon/icon-nav-my-on.svg' alt='nav-communication-icon' />
            <img src='/assets/images/icon/icon-nav-my.svg' alt='nav-communication-icon' />
          </span>
          <span>소통</span>
        </NavButton>
      </NavWrapper>
      <RegisterModal open={modalOpen} close={() => setModalOpen(false)} />
    </>
  );
}
