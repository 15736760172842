/** @jsxImportSource @emotion/react */

import Box from '@components/Box';
import KakaoLogin from '@sections/KakaoLogin';
import { css } from '@emotion/react';
import { useAuth } from '@contexts/AuthContext';

export default function AuthGuard({ showGuidance, children }) {
  const { isAuth } = useAuth();

  // isAuth가 false인 경우
  if (!isAuth) {
    // 가입 유도
    if (showGuidance) {
      return (
        <Box
          css={css`
            position: relative;
          `}
        >
          <Box
            css={css`
              opacity: 0.5;
            `}
          >
            {children}
          </Box>
          <Box
            direction='column'
            hAlign='center'
            vAlign='center'
            gap={1}
            p={1}
            radius='var(--radius-1)'
            css={css`
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              width: 100%;
              height: 100%;
              backdrop-filter: blur(4px);
            `}
          >
            <p>로그인 후 이용하실 수 있습니다.</p>
            <KakaoLogin />
          </Box>
        </Box>
      );
    }
    // 아무것도 보이지 않음
    return null;
  }
  return children;
}
