/** @jsxImportSource @emotion/react */

import { Link } from 'react-router-dom';
import { colors } from '@constants/color';
import { css } from '@emotion/react';
import { useColorScheme } from '@contexts/ColorSchemeContext';

export default function Footer() {
  const { isDarkMode } = useColorScheme();
  return (
    <footer
      css={css`
        width: 100%;
        margin-top: auto;
        border-top: 1px solid ${isDarkMode ? 'transparent' : colors.border.default.secondary};
        background-color: ${isDarkMode ? colors.bg.default.inverse : colors.bg.default.secondary};
        padding: 1rem;
      `}
    >
      <h1
        css={css`
          font-size: 1rem;
          color: ${colors.text.default.tertiary};
        `}
      >
        하이시그널
      </h1>
      <p
        css={css`
          margin-top: 0.5rem;
          font-size: 0.8rem;
          letter-spacing: -0.02em;
          color: ${colors.text.default.tertiary};
        `}
      >
        본 서비스는 투자판단에 참고용으로만 사용하실 수 있으며, 모든 투자판단은 투자자 본인의
        책임으로 당사는 그 결과에 대해 법적인 일체의 책임을 지지 않습니다.
      </p>
      <div
        css={css`
          margin-top: 0.5rem;
          color: ${colors.text.default.tertiary};
          * {
            font-size: 0.8rem;
            font-weight: 500;
          }
          a:first-of-type {
            padding-right: 0.5rem;
            margin-right: 0.5rem;
            border-right: 1px solid ${colors.text.default.tertiary};
          }
        `}
      >
        <Link to='#'>이용약관</Link>
        <Link to='#'>개인정보처리방침</Link>
      </div>
      <div
        css={css`
          margin-top: 0.5rem;
          color: ${colors.text.default.tertiary};
          * {
            margin-top: 0.5rem;
            font-size: 0.8rem;
          }

          div {
            margin-top: 0.5rem;
            color: ${colors.text.default.tertiary};
            * {
              font-size: 0.8rem;
              font-weight: 500;
              width: fit-content;
              display: inline-block;
            }
            p:first-of-type {
              margin-right: 0.5rem;
            }
          }
        `}
      >
        <div>
          <p>셀레셜테크놀로지(주)</p>
          <p>대표: 김한철</p>
        </div>
        <p>사업자번호: 474-86-03333</p>
        <Link to='https://celtech.kr' target='_blank'>
          <b>H. </b>celtech.kr
        </Link>
        <p>© 2024 Celestial Technology Inc. All Rights Reserved.</p>
      </div>
    </footer>
  );
}
