export default [
  {
    name: '이마트',
    ticker: '139480',
    src: '/assets/images/logo/logo-ex01.png',
    expected_return_20days: 5.6
  },
  {
    name: '신세계',
    ticker: '004170',
    src: '/assets/images/logo/logo-ex02.png',
    expected_return_20days: 5.6
  },
  {
    name: '삼성전자',
    ticker: '005930',
    src: '/assets/images/logo/005930.png',
    expected_return_20days: 5.6
  }
];
