/** @jsxImportSource @emotion/react */

import 'react-circular-progressbar/dist/styles.css';

import ArrowButton from '@components/ArrowButton';
import Badge from '@components/Badge';
import BottomTabs from '@components/BottomTabs';
import Box from '@components/Box';
import Button from '@components/Button';
import Card from '@components/Card';
import Checkbox from '@components/Checkbox';
import Chip from '@components/Chip';
import CircleButton from '@components/CircleButton';
import CirclePercentage from '@components/CirclePercentage';
import Dialog from '@components/Dialog';
import Hashtag from '@components/Hashtag';
import Header from '@components/Header';
import Icon from '@components/Icon';
import Page from '@components/Page';
import Switch from '@components/Switch';
import { colors } from '@constants/color';
import { css } from '@emotion/react';
import { useColorScheme } from '@contexts/ColorSchemeContext';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

export default function ComponentPage() {
  const navigate = useNavigate();

  const { isDarkMode, toggleColorScheme } = useColorScheme();

  const [dialogBasic, setDialogBasic] = useState(false);
  const [dialogAlert, setDialogAlert] = useState(false);

  return (
    <Page
      gap={1}
      p={1}
      title='컴포넌트'
      onHeaderArrowClick={() => navigate('/')}
      renderHeaderRightContent={() => (
        <Switch
          label='다크모드'
          defaultChecked={isDarkMode}
          onClick={toggleColorScheme}
          size='sm'
          data-theme-toggle
        />
      )}
    >
      <Card title='ArrowButton'>
        <Box gap={0.5} p={0.5}>
          <ArrowButton />
          <ArrowButton dot />
          <ArrowButton outlined />
          <ArrowButton outlined dot />
          <ArrowButton color='secondary' />
          <ArrowButton dot color='secondary' />
          <ArrowButton outlined color='secondary' />
          <ArrowButton outlined dot color='secondary' />
        </Box>
      </Card>

      <Card title='Badge'>
        <Box gap={0.5} p={0.5}>
          <Badge text='5.21%' />
          <Badge color='red' text='5.21%' />
          <Badge color='blue' text='5.21%' />
          <Badge color='green' text='5.21%' />
        </Box>
      </Card>

      <Card title='BottomSheet'>
        <Box gap={0.5} p={0.5}>
          차순위
        </Box>
      </Card>

      <Card title='BottomTabs'>
        <Box gap={0.5} p={0.5}>
          <BottomTabs />
        </Box>
      </Card>

      <Card title='Box'>
        <Box gap={0.5} p={0.5}>
          <Box />
          {/* {[
              'id',
              'children',
              'p',
              'px',
              'py',
              'gap',
              'hAlign',
              'vAlign',
              'maxWidth',
              'bgColor',
              'radius',
              'fullHeight',
              'flex',
              'className',
              'onClick',
              'css',
              'element'
            ].map((prop) => (
              <p key={prop}>{prop}</p>
            ))} */}
        </Box>
      </Card>

      <Card title='Button'>
        <Box gap={0.5} p={0.5}>
          <Card title='size : ' gap={0.5}>
            <Button text='sm' size='sm' />
            <Button text='md' size='md' />
            <Button text='lg' size='lg' />
          </Card>
          {['primary', 'secondary', 'text'].map((color) => (
            <Card key={color} title={`color : ${color}`}>
              <Button text={color} color={color} />
              <Button text={`${color}+wFit`} color={color} wFit />
              <Button text={`${color}+active`} color={color} active />
              <Button text={`${color}+disabled`} color={color} disabled />
            </Card>
          ))}
        </Box>
      </Card>

      <Card title='Card'>
        <Box gap={0.5} p={0.5}>
          <Card title='Card' />
          <Card.Simple
            title='Simple Card'
            date='2024/04/12'
            description='상세설명이 들어가는 자리입니다 상세설명이 들어가는 자리입니다 상세설명이 들어가는 자리입니다 상세설명이 들어가는 자리입니다'
          />
        </Box>
      </Card>

      <Card title='Checkbox'>
        <Box gap={0.5} p={0.5}>
          <Checkbox label='Checkbox' />
          <Checkbox
            squared
            label={`상세설명이 들어가는 자리입니다\n상세설명이 들어가는 자리입니다`}
          />
        </Box>
      </Card>

      <Card title='Chip'>
        <Box gap={0.5} p={0.5}>
          <Chip color='red' text='보유중' />
          <Chip text='관망중' />
          <Chip color='blue' text='12조원' />
          <Chip color='blue' text='12조원' squared />
        </Box>
      </Card>

      <Card title='CircleButton'>
        <Box gap={0.5} p={0.5}>
          <CircleButton icon={<Icon.MagnifyGlass />} helpText='CircleButton' />
          <CircleButton icon={<Icon.MagnifyGlass />} helpText='CircleButton' color='secondary' />
        </Box>
      </Card>

      <Card title='CirclePercentage'>
        <CirclePercentage />
      </Card>

      <Card title='Dialog'>
        <Box gap={0.5} p={0.5}>
          <Button text='Basic Dialog' onClick={() => setDialogBasic(true)} />
          <Dialog open={dialogBasic} close={() => setDialogBasic(false)}>
            <Dialog.Header>AI 실시간 주가 예측 현황</Dialog.Header>
            <Dialog.Body>
              <p>
                1. 누적 예측수는 AI가 예측한 종복의 수입니다. <br />
                2. 예측률 = 예측 달성 종목/누적 예측수 <br />
                3. 투자 수익금은 AI 예측 기반 매수&매도 진행한 결과입니다.
                <br />
                <br />
                *예측 달성 종목의 기준은 예측 이후 특정기간동안 예측한 주가의 기울기와 같이 움직인
                정도입니다
              </p>
            </Dialog.Body>
            <Dialog.Footer>
              <Button text='취소' onClick={() => setDialogBasic(false)} />
              <Button text='확인' onClick={() => setDialogBasic(false)} />
            </Dialog.Footer>
          </Dialog>
        </Box>
        <Box gap={0.5} p={0.5}>
          <Button text='Alert Dialog' onClick={() => setDialogAlert(true)} />
          <Dialog.Alert
            open={dialogAlert}
            close={() => setDialogAlert(false)}
            title='AI 실시간 주가 예측 현황'
          >
            <p>
              1. 누적 예측수는 AI가 예측한 종복의 수입니다. <br />
              2. 예측률 = 예측 달성 종목/누적 예측수 <br />
              3. 투자 수익금은 AI 예측 기반 매수&매도 진행한 결과입니다.
              <br />
              <br />
              *예측 달성 종목의 기준은 예측 이후 특정기간동안 예측한 주가의 기울기와 같이 움직인
              정도입니다
            </p>
          </Dialog.Alert>
        </Box>
      </Card>

      <Card title='Hashtag'>
        <Box gap={0.5} p={0.5}>
          <Hashtag text='non-active' />
          <Hashtag text='active' active />
          <Hashtag text='non-active' onClick={() => alert('hi')} />
          <Hashtag text='active' onClick={() => alert('hi')} active />
        </Box>
      </Card>

      <Card title='Header'>
        <Box gap={0.5} p={0.5}>
          <Header onArrowClick={() => alert('hi')} />
          <Header onArrowClick={() => alert('hi')} title='헤더' />
          <div
            css={css`
              width: 100%;
              background-color: ${colors.black};
            `}
          >
            <Header onArrowClick={() => alert('hi')} title='헤더' dark />
          </div>
        </Box>
      </Card>

      <Card title='Icon'>
        <Box gap={0.5} p={0.5}></Box>
      </Card>

      <Card title='InfoSheet'>
        <Box gap={0.5} p={0.5}>
          차순위
        </Box>
      </Card>

      <Card title='Input'>
        <Box gap={0.5} p={0.5}>
          차순위
        </Box>
      </Card>
    </Page>
  );
}
