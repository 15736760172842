import { Helmet, HelmetProvider } from 'react-helmet-async';
import { RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';
import { instance, instanceAuth } from '@services/api';

import AIExplorePage from '@pages/AIExplorePage';
import AITestAdminPage from '@pages/AITestAdminPage';
import ComponentPage from '@pages/dev/ComponentPage';
import ErrorElement from '@components/ErrorElement';
import IntroPage from '@pages/IntroPage';
import Loading from '@components/Loading';
import LogoutPage from '@pages/LogoutPage';
import MainLayout from '@layouts/MainLayout';
import MainPage from '@pages/MainPage';
import SignInPage from '@pages/SignInPage';
import SignUpPage from '@pages/SignUpPage';
import axios from 'axios';

export default function App() {
  return (
    <HelmetProvider>
      <GATracking />
      <RouterProvider router={router} fallbackElement={<Loading />} />
    </HelmetProvider>
  );
}

function GATracking() {
  if (process.env.REACT_APP_ENV !== 'production') return null;
  return (
    <Helmet>
      <script async src='https://www.googletagmanager.com/gtag/js?id=G-5EJ8FRRW0E'></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'G-5EJ8FRRW0E');`}
      </script>
    </Helmet>
  );
}

const router = createBrowserRouter([
  {
    id: 'root',
    path: '/',
    loader: rootLoader,
    Component: MainLayout,
    errorElement: <ErrorElement />,
    children: [
      {
        index: true,
        Component: IntroPage
      },
      {
        path: 'main',
        Component: MainPage
      },
      {
        path: 'sign-in',
        Component: SignInPage
      },
      {
        path: 'sign-up',
        Component: SignUpPage
      },
      {
        path: 'ai-explore/:ticker',
        Component: AIExplorePage
      },
      {
        path: 'ai-explore',
        Component: AIExplorePage
      },
      {
        path: 'ai-test/admin',
        Component: AITestAdminPage
      },
      {
        path: 'logout',
        Component: LogoutPage
      }

      // {
      //   path: 'ai',
      //   Component: AIMenuPage
      // },
      // {
      //   path: 'ai-search',
      //   Component: AISearchPage
      // },
      // {
      //   path: 'ai/forecast',
      //   Component: AIForecastPage
      // },
      // {
      //   path: 'ai/forecast/popular',
      //   Component: AIForecastPopularPage
      // },
      // {
      //   path: 'ai/forecast/trending',
      //   Component: AIForecastTrendingPage
      // },
      // {
      //   path: 'stock/:ticker',
      //   Component: StockDetailPage
      // },
      // {
      //   path: 'stock/:ticker/analysis/similar',
      //   Component: StockDetailSimilarPage
      // },
      // {
      //   path: 'stock/:ticker/analysis/record',
      //   Component: StockDetailRecordPage
      // },

      // {
      //   path: 'signal',
      //   Component: SignalPage
      // },
      // {
      //   path: 'signal/:ticker',
      //   Component: SignalStockDetailPage
      // },

      // // 중요하지 않음
      // {
      //   path: 'stock/:ticker/analysis/elliott',
      //   Component: StockDetailElliottPage
      // },
      // // 임시 라우팅 페이지
      // {
      //   path: 'detail-elliott-main',
      //   Component: StockDetailElliottPage
      // },
      // {
      //   path: 'investors',
      //   Component: InvestorsPage
      // },
      // {
      //   path: 'investors/:investorId',
      //   Component: InvestorDetailPage
      // },
      // {
      //   path: 'favorite',
      //   Component: FavoritePage
      // },
      // {
      //   path: 'favorite/hold',
      //   Component: FavoriteHoldPage
      // },
      // {
      //   path: 'favorite/edit',
      //   Component: FavoriteEditPage
      // },
      // {
      //   path: 'favorite/search',
      //   Component: FavoriteSearchPage
      // },
      // {
      //   path: 'my-page',
      //   loader: loginLoader,
      //   Component: MyPage
      // }
    ]
  },
  {
    id: 'dev',
    path: '/dev',
    Component: MainLayout,
    children: [
      {
        index: true,
        Component: ComponentPage
      }
    ]
  },
  {
    path: '/sign-out',
    async action() {
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          localStorage.removeItem('HIGHSIGNAL.token');
          resolve();
        }, 3000);
      });
      return redirect('/');
    }
  },
  {
    path: 'oauth/callback',
    loader: oAuthCallbackLoginLoader,
    Component: Loading
  }
]);

// 로그인 및 토큰 갱신 처리
async function rootLoader({ request }, context) {
  let authenticated = false;
  let status;
  let message = '';
  const authenticateUserWithAccessToken = async () => {
    const token = localStorage.getItem('HIGHSIGNAL.token');
    if (!token) return false;
    return await instanceAuth
      .post(
        '/users/refresh-token',
        {},
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      .then((res) => {
        localStorage.setItem('HIGHSIGNAL.token', res.data);
        authenticated = true;
      })
      .catch((err) => {
        authenticated = false;
        status = err?.response?.status;
        message = err?.customMessage;
        if ([401].includes(status)) localStorage.removeItem('HIGHSIGNAL.token');
      });
  };
  await authenticateUserWithAccessToken();
  return { authenticated, status, message };
}

// 카카오 로그인 처리
// authorization code 받기
// callback url에 돌아오면 회원가입 처리
async function oAuthCallbackLoginLoader({ context, params, request }) {
  const urlObj = new URL(request.url);
  const code = urlObj.searchParams.get('code');
  let created = false;
  const getAccessToken = async (code) => {
    const data = {
      grant_type: 'authorization_code',
      client_id: process.env.REACT_APP_KAKAO_REST_API_KEY,
      redirect_uri: window.location.origin + '/oauth/callback',
      code
    };
    const urlEncodedData = new URLSearchParams(data);
    return await axios
      .post('https://kauth.kakao.com/oauth/token', urlEncodedData, {
        'Content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      })
      .then((res) => {
        const {
          access_token,
          expires_in,
          refresh_token,
          refresh_token_expires_in,
          scope,
          token_type
        } = res.data || {};
        return access_token;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  };
  const access_token = await getAccessToken(code);
  const authenticateKakaoUser = async (kakaoAccessToken) => {
    return await instance
      .post('/users', {
        access_token: kakaoAccessToken
      })
      .then((res) => {
        localStorage.setItem('HIGHSIGNAL.token', res.data.token);
        created = res.data.created;
      })
      .catch((err) => {
        console.error(err);
      });
  };
  if (access_token) await authenticateKakaoUser(access_token);
  return redirect(created ? '/main?created=true' : '/main');
}
