import { useLocation, useNavigate } from 'react-router-dom';

import Box from '@components/Box';
import Button from '@components/Button';
import Icon from '@components/Icon';
import { colors } from '@constants/color';
import styled from '@emotion/styled';
import { usePage } from '@contexts/PageContext';
import { useTutorial } from '@contexts/TutorialContext';

const Backdrop = styled('div')((props) => {
  return {
    position: 'fixed',
    top: 0,
    bottom: 0,
    width: '100%',
    maxWidth: '450px',
    backgroundColor: '#00000055',
    backdropFilter: 'blur(1px)',
    zIndex: 'var(--z-index-3)'
  };
});

export default function Tutorial() {
  // react-router-dom
  const { pathname, hash } = useLocation();
  // custom hooks
  const { tutorialPaths, tutorialCompleted } = useTutorial();

  if (tutorialCompleted) return null;
  if (!tutorialPaths.includes(pathname) || hash) return null;
  return (
    <>
      <Backdrop />
      <CloseButton />
      <TutorialBubble />
      <Introduction />
    </>
  );
}

const IntroductionWrapper = styled('div')(() => {
  return {
    position: 'fixed',
    top: 0,
    bottom: 0,
    maxWidth: '450px',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'var(--color-bg)',
    zIndex: 'var(--z-index-7)',
    p: {
      fontWeight: 500,
      fontSize: '1.4rem',
      textAlign: 'center',
      lineHeight: 1.5,
      b: {
        fontSize: 'inherit',
        color: colors.text.default.brand
      }
    },
    '@keyframes hide': {
      '100%': {
        opacity: 0,
        display: 'none'
      }
    },
    animation: 'hide 400ms 2s 1 forwards'
  };
});
function Introduction() {
  const { introShown } = useTutorial();
  if (introShown) return null;
  return (
    <IntroductionWrapper>
      <p>
        <b>하이시그널</b>에 오신 것을 환영합니다 :)
        <br />
        사용법을 알려드릴게요
      </p>
    </IntroductionWrapper>
  );
}

const shouldForwardPropCloseButton = (prop) => !['offsetWidth'].includes(prop);
const CloseButtonWrapper = styled('div', { shouldForwardProp: shouldForwardPropCloseButton })((
  props
) => {
  return {
    position: 'fixed',
    top: 0,
    marginTop: '1rem',
    marginLeft: `${props.offsetWidth / 2}px`,
    maxWidth: '450px',
    transform: 'translate(-50%, 0)',
    borderRadius: '300px',
    backdropFilter: 'blur(2px)',
    zIndex: 'var(--z-index-7)',
    button: {
      backgroundColor: 'black',
      fontWeight: 600,
      color: 'white',
      padding: '1rem 2rem',
      borderRadius: '300px',
      display: 'inline-flex',
      alignItems: 'center',
      transition: '100ms ease-in-out',
      span: {
        marginLeft: '0.5rem',
        whiteSpace: 'nowrap'
      },
      ':active': {
        opacity: 1
      }
    }
  };
});
function CloseButton() {
  const { offsetWidth } = usePage();
  const { completeTutorial } = useTutorial();
  if (offsetWidth === 0) return null;
  return (
    <CloseButtonWrapper offsetWidth={offsetWidth}>
      <button onClick={completeTutorial}>
        <Icon.X color='white' />
        <span>닫기</span>
      </button>
    </CloseButtonWrapper>
  );
}

const shouldForwardPropTutorialBubble = (prop) =>
  !['offsetTop', 'offsetHeight', 'direction'].includes(prop);
const TutorialBubbleWrapper = styled('div', { shouldForwardProp: shouldForwardPropTutorialBubble })(
  (props) => {
    return {
      position: 'absolute',
      width: '100%',
      maxWidth: '450px',
      marginTop:
        props.direction === 'bottom'
          ? `${props.offsetTop - props.offsetHeight - 50}px`
          : `${props.offsetTop + props.offsetHeight + 20}px`,
      top: '-10px',
      zIndex: 'var(--z-index-5)',
      display: 'inline-flex',
      justifyContent: 'center',
      opacity: 0,
      animation: 'intro-preview-logo 300ms 100ms 1 forwards',
      '& > div': {
        backgroundColor: colors.bg.default.brand,
        borderRadius: '0.5rem',
        maxWidth: '300px',
        position: 'relative'
      },
      '& > div > span': {
        position: 'absolute',
        left: 0,
        textAlign: 'center',
        color: 'white',
        fontSize: '0.8rem',
        fontWeight: 500,
        padding: '1rem',
        'span:first-of-type': {
          fontWeight: 'bold'
        }
      },
      '& > div > p': {
        width: '100%',
        padding: '2.5rem 1rem 0',
        color: 'white',
        fontWeight: 500,
        whiteSpace: 'pre-line',
        wordBreak: 'keep-all',
        lineHeight: 1.4
      },
      ...(props.direction === 'bottom'
        ? {
            '& > div::before': {
              content: '""',
              position: 'absolute',
              bottom: 0,
              left: 'calc(50% - 8px)',
              marginBottom: '-8px',
              borderBottom: '0 solid transparent',
              borderLeft: '8px solid transparent',
              borderRight: '8px solid transparent',
              borderTop: `10px solid ${colors.bg.default.brand}`
            }
          }
        : {
            '& > div::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 'calc(50% - 8px)',
              marginTop: '-8px',
              borderTop: '0 solid transparent',
              borderLeft: '8px solid transparent',
              borderRight: '8px solid transparent',
              borderBottom: `10px solid ${colors.bg.default.brand}`
            }
          })
    };
  }
);
function TutorialBubble() {
  const navigate = useNavigate();
  const {
    sequenceTexts,
    initialSequences,
    currentIndex,
    lowerCurrentIndex,
    raiseCurrentIndex,
    prevPage,
    currPage,
    nextPage,
    completeTutorial,
    offsetTop,
    offsetHeight,
    direction,
    leftSequences,
    tutorialBubbleVisible,
    hideMessage
  } = useTutorial();

  if (isNaN(offsetTop) || isNaN(offsetHeight)) return null;
  if (!tutorialBubbleVisible) return null;
  const moveToPrevPage = () => {
    navigate('/' + prevPage);
    hideMessage();
  };
  const moveToPrev = () => {
    hideMessage();
    lowerCurrentIndex();
  };
  const moveToNextPage = () => {
    navigate('/' + nextPage);
    hideMessage();
  };
  const moveToNext = () => {
    hideMessage();
    raiseCurrentIndex();
  };
  return (
    <TutorialBubbleWrapper offsetTop={offsetTop} offsetHeight={offsetHeight} direction={direction}>
      <Box>
        <span>
          <span>{currentIndex + 1}</span> / <span>{initialSequences.length}</span>
        </span>
        <p>{sequenceTexts[currentIndex]}</p>
        <Box p={1} gap={0.5} direction='row'>
          {currentIndex > 0 &&
            (prevPage !== currPage ? (
              <Button text='이전 페이지' color='tertiary' active onClick={moveToPrevPage} />
            ) : (
              <Button text='이전' active disabled={currentIndex === 0} onClick={moveToPrev} />
            ))}
          {!!nextPage && nextPage !== currPage ? (
            <Button
              text='다음 페이지'
              color='tertiary'
              active
              onClick={moveToNextPage}
              disabled={currentIndex >= initialSequences.length}
            />
          ) : leftSequences.length > 1 ? (
            <Button
              text='다음'
              active
              color='tertiary'
              onClick={moveToNext}
              disabled={currentIndex >= initialSequences.length}
            />
          ) : (
            <Button text='시작하기' active onClick={completeTutorial} />
          )}
        </Box>
      </Box>
    </TutorialBubbleWrapper>
  );
}
