import { forwardRef, useMemo } from 'react';

import styled from '@emotion/styled';

const shouldForwardProp = (prop) =>
  ![
    'pt',
    'pb',
    'pl',
    'pr',
    'px',
    'py',
    'p',
    'gap',
    'hAlign',
    'vAlign',
    'mx',
    'my',
    'mt',
    'mb',
    'maxWidth',
    'bgColor',
    'radius',
    'fullHeight',
    'fitWidth',
    'direction'
  ].includes(prop);
const BoxWrapper = styled('div', { shouldForwardProp })((props) => {
  // flex direction row일때 gap 처리
  const gapIfFlexRow = {
    '& > *:not(:last-child)': {
      marginRight: !isNaN(props.gap) ? `${props.gap}rem` : '1rem'
    }
  };
  // flex direction column일때 gap 처리
  const gapIfFlexColumn = {
    '& > *:not(:last-child)': {
      marginBottom: !isNaN(props.gap) ? `${props.gap}rem` : '1rem'
    }
  };

  let padding = {
    pt: !isNaN(props.pt) ? props.pt : !isNaN(props.py) ? props.py : !isNaN(props.p) ? props.p : 0,
    pb: !isNaN(props.pb) ? props.pb : !isNaN(props.py) ? props.py : !isNaN(props.p) ? props.p : 0,
    pl: !isNaN(props.pl) ? props.pl : !isNaN(props.px) ? props.px : !isNaN(props.p) ? props.p : 0,
    pr: !isNaN(props.pr) ? props.pr : !isNaN(props.px) ? props.px : !isNaN(props.p) ? props.p : 0
  };
  let margin = {
    mx: props.mx ? props.mx : props.mx ? props.mx : 0,
    mt: props.mt ? props.mt : props.my ? props.my : 0,
    mb: props.mb ? props.mb : props.my ? props.my : 0
  };
  return {
    width: props.fitWidth ? 'fit-content' : '100%',
    maxWidth: props.maxWidth || '450px',
    height: props.fullHeight ? '100%' : 'fit-content',
    padding: `${padding.pt}rem ${padding.pr}rem ${padding.pb}rem ${padding.pl}rem`,
    ...(props.direction === 'row' ? gapIfFlexRow : gapIfFlexColumn),
    margin: `${!isNaN(margin.mt) ? `${margin.mt}rem` : margin.mt} ${!isNaN(margin.mx) ? `${margin.mx}rem` : 'auto'} ${!isNaN(margin.mb) ? `${margin.mb}rem` : margin.mb} ${!isNaN(margin.mx) ? `${margin.mx}rem` : 'auto'}`,
    borderRadius: !isNaN(props.radius) ? `${props.radius}rem` : props.radius ? props.radius : '',
    backgroundColor: props.bgColor,
    display: 'flex',
    flexDirection: props.direction,
    justifyContent: props.vAlign,
    alignItems:
      props.hAlign === 'center' ? 'center' : props.hAlign === 'end' ? 'flex-end' : 'flex-start'
  };
});

function Box(
  {
    id,
    // name,
    children,
    p,
    px,
    py,
    pt,
    pb,
    pl,
    pr,
    gap = 0,
    hAlign,
    vAlign,
    mx,
    my,
    mt,
    mb,
    maxWidth,
    bgColor,
    radius,
    fullHeight,
    fitWidth,
    direction = 'column',
    className,
    onClick,
    css,
    element = 'div',
    ...props
  },
  ref
) {
  const Element = useMemo(() => BoxWrapper.withComponent(element), [element]);
  return (
    <Element
      ref={ref}
      id={id}
      // name={name}
      p={p}
      px={px}
      py={py}
      pt={pt}
      pb={pb}
      pl={pl}
      pr={pr}
      gap={gap}
      mx={mx}
      my={my}
      mt={mt}
      mb={mb}
      maxWidth={maxWidth}
      radius={radius}
      hAlign={hAlign}
      vAlign={vAlign}
      bgColor={bgColor}
      direction={direction}
      fullHeight={fullHeight}
      fitWidth={fitWidth}
      className={className}
      onClick={onClick}
      css={css}
      {...props}
    >
      {children}
    </Element>
  );
}

export default forwardRef(Box);
