import CircleButton from '@components/CircleButton';
import Icon from '@components/Icon';
import styled from '@emotion/styled';

const shouldForwardPropInfoSheet = (prop) =>
  !['zIndex', 'show', 'bgColor', 'shadowColor'].includes(prop);
const InfoSheetWrapper = styled('div', { shouldForwardProp: shouldForwardPropInfoSheet })((
  props
) => {
  return {
    zIndex: props.zIndex ? props.zIndex : 'var(--z-index-1)',
    backgroundColor: props.bgColor,
    position: 'sticky',
    marginTop: 'auto',
    bottom: 0,
    width: '100%',
    padding: '0 1rem',
    color: 'black',
    borderTopLeftRadius: '1rem',
    borderTopRightRadius: '1rem',
    display: 'flex',
    minHeight: props.show ? 'fit-content' : 0,
    height: props.show ? 'fit-content' : 0,
    maxHeight: props.show ? '100%' : 0,
    transform: props.show ? 'translateY(0)' : 'translateY(30%)',
    opacity: props.show ? 1 : 0,
    transition: `max-height ${props.show ? '300ms' : '100ms'} var(--easing-function-1),
    transform ${props.show ? '300ms' : '100ms'} var(--easing-function-1),
    opacity 100ms var(--easing-function-1)`,
    flexShrink: 0,
    overflow: 'hidden',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    boxShadow: props.shadowColor ? `0 0 12px 0 ${props.shadowColor}` : 'var(--box-shadow-default)'
  };
});
export default function InfoSheet({ children, zIndex, show, bgColor = 'white', shadowColor }) {
  return (
    <InfoSheetWrapper zIndex={zIndex} show={show} bgColor={bgColor} shadowColor={shadowColor}>
      {children}
    </InfoSheetWrapper>
  );
}

const ContentWrapper = styled('div')((props) => {
  return {
    width: '100%',
    padding: '1.2rem 0'
  };
});
function Content({ children }) {
  return <ContentWrapper>{children}</ContentWrapper>;
}

const shouldForwardPropActions = (prop) => !['position'].includes(prop);
const ActionsWrapper = styled('div', { shouldForwardProp: shouldForwardPropActions })((props) => {
  return {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: typeof props.title === 'string' ? 'space-between' : 'space-around',
    padding: '1rem 0.5rem',
    ...(props.position === 'bottom'
      ? { borderTop: '1px solid #f5f5f5' }
      : props.position === 'top'
        ? { borderBottom: '1px solid #f5f5f5' }
        : {}),
    '& > p': {
      fontWeight: 600
    }
  };
});
function Actions({
  title,
  position = 'bottom', // top, bottom
  textNegative,
  onNegativeBtnClick,
  textPositive,
  onPositiveBtnClick,
  buttonSize,
  positiveButtonIconColor,
  negativeButtonIconColor
}) {
  return (
    <ActionsWrapper title={title} position={position}>
      {title && <p>{title}</p>}
      {typeof onNegativeBtnClick === 'function' && (
        <CircleButton
          icon={<Icon.X color={positiveButtonIconColor} />}
          color='secondary'
          helpText={textNegative}
          onClick={onNegativeBtnClick}
          size={buttonSize}
        />
      )}
      {typeof onPositiveBtnClick === 'function' && (
        <CircleButton
          icon={<Icon.MagnifyGlass size='1.2rem' color={negativeButtonIconColor} />}
          helpText={textPositive}
          onClick={onPositiveBtnClick}
          size={buttonSize}
        />
      )}
    </ActionsWrapper>
  );
}

InfoSheet.Content = Content;
InfoSheet.Actions = Actions;
