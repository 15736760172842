import styled from '@emotion/styled';

const shouldForwardProp = (prop) => !['color'].includes(prop);
const BadgeWrapper = styled('span', { shouldForwardProp })((props) => {
  let textColor = '#595959';
  let bgColor = '#f5f5f5';
  switch (props.color) {
    case 'red':
      textColor = '#f54747';
      bgColor = '#f2dee4';
      break;
    case 'blue':
      textColor = '#2f54eb';
      bgColor = '#e2f4ff';
      break;
    case 'green':
      textColor = '#08979c';
      bgColor = '#e6fffb';
      break;
    default:
      // default
      break;
  }
  return {
    color: textColor,
    backgroundColor: bgColor,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    padding: '0.4rem 0.6rem',

    userSelect: 'none',

    fontWeight: 500,
    letterSpacing: '-0.2px',
    borderRadius: '100px'
  };
});

export default function Badge({
  text = 'Badge',
  color = 'default' // default, red, blue, green
}) {
  return <BadgeWrapper color={color}>{text}</BadgeWrapper>;
}
