// 컬러 스킴 컨텍스트

import React, { createContext, useContext, useEffect, useState } from 'react';

import { useMediaQuery } from 'react-responsive';

const ColorSchemeContext = createContext();

export const useColorScheme = () => useContext(ColorSchemeContext);

export const ColorSchemeProvider = ({ children }) => {
  const isSystemSettingDark = useMediaQuery({ query: '(prefers-color-scheme: dark)' });

  const [colorScheme, setColorScheme] = useState();
  const isDarkMode = colorScheme === 'dark';

  const toggleColorScheme = () => setColorScheme((prev) => (prev === 'dark' ? 'light' : 'dark'));
  const setColorSchemeDark = () => setColorScheme('dark');
  const setColorSchemeLight = () => setColorScheme('light');

  useEffect(() => {
    // OS 테마 가져오는것
    // 다크모드 모두 완성되면, + 다크모드 테마 사용하도록 서비스 하려면
    // 아래 detectColorScheme 함수 실행할 것
    const detectColorScheme = () => {
      const currentColorScheme = localStorage.getItem('HIGHSIGNAL.color_scheme');
      switch (currentColorScheme) {
        case 'light':
          setColorScheme(currentColorScheme);
          break;
        case 'dark':
          setColorScheme(currentColorScheme);
          break;
        default:
          {
            const nextColorScheme = isSystemSettingDark ? 'dark' : 'light';
            localStorage.setItem('HIGHSIGNAL.color_scheme', nextColorScheme);
            setColorScheme(nextColorScheme);
          }
          break;
      }
    };
    // detectColorScheme();
  }, [isSystemSettingDark]);

  useEffect(() => {
    const controlBodyAttrColorScheme = () => {
      if (!colorScheme) return;
      const body = document.querySelector('body');
      switch (colorScheme) {
        case 'light':
          body.removeAttribute('data-color-scheme');
          localStorage.setItem('HIGHSIGNAL.color_scheme', 'light');
          break;
        case 'dark':
          body.removeAttribute('data-color-scheme');
          body.setAttribute('data-color-scheme', 'dark');
          localStorage.setItem('HIGHSIGNAL.color_scheme', 'dark');
          break;
        default:
          break;
      }
    };
    controlBodyAttrColorScheme();
  }, [colorScheme]);

  return (
    <ColorSchemeContext.Provider
      value={{
        isSystemSettingDark,
        colorScheme,
        isDarkMode,
        toggleColorScheme,
        setColorSchemeDark,
        setColorSchemeLight
      }}
    >
      {children}
    </ColorSchemeContext.Provider>
  );
};
