import Footer from '@components/Footer';
import Header from '@components/Header';
import { Helmet } from 'react-helmet-async';
import Tutorial from '@components/Tutorial';
import styled from '@emotion/styled';
import { useColorScheme } from '@contexts/ColorSchemeContext';
import { useLocation } from 'react-router-dom';
import { usePage } from '@contexts/PageContext';

const shouldForwardProp = (prop) =>
  ![
    'pt',
    'pb',
    'pl',
    'pr',
    'px',
    'py',
    'p',
    'gap',
    'disableBottomMargin',
    'forceUseHeightFull'
  ].includes(prop);
const MainWrapper = styled('main', { shouldForwardProp })((props) => {
  let padding = {
    pt: !isNaN(props.pt) ? props.pt : !isNaN(props.py) ? props.py : !isNaN(props.p) ? props.p : 0,
    pb: !isNaN(props.pb) ? props.pb : !isNaN(props.py) ? props.py : !isNaN(props.p) ? props.p : 0,
    pl: !isNaN(props.pl) ? props.pl : !isNaN(props.px) ? props.px : !isNaN(props.p) ? props.p : 0,
    pr: !isNaN(props.pr) ? props.pr : !isNaN(props.px) ? props.px : !isNaN(props.p) ? props.p : 0
  };
  return {
    position: 'relative',
    width: '100%',
    minHeight: props.forceUseHeightFull ? '100vh' : undefined,
    padding: `${padding.pt}rem ${padding.pr}rem ${padding.pb}rem ${padding.pl}rem`,
    marginLeft: 'auto',
    marginRight: 'auto',
    scrollBehavior: 'smooth',
    display: 'flex',
    flexBasis: '100vh',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    '::-webkit-scrollbar': {
      display: 'none'
    },
    ...(props.disableBottomMargin
      ? {
          '& > div:not(:last-of-type),& > hr:not(:last-of-type),& > form:not(:last-of-type),& > ul:not(:last-of-type),& > ol:not(:last-of-type)':
            {
              marginBottom: !isNaN(props.gap) ? `${props.gap}rem` : 0
            }
        }
      : {
          '& > div,& > hr,& > form,& > ul,& > ol': {
            marginBottom: !isNaN(props.gap) ? `${props.gap}rem` : 0
          }
        }),
    footer: {
      width: `calc(100% + ${padding.pl + padding.pr}rem * 2)`,
      marginLeft: `-${padding.pl + padding.pr}rem`,
      marginBottom: `-${padding.pt + padding.pb}rem`
    }
  };
});
export default function Page({
  children,
  p,
  px,
  py,
  pt,
  pb,
  pl,
  pr,
  headerBgColor,
  disableBottomMargin,
  forceUseHeightFull,
  gap,
  title,
  canonicalURI = '',
  showTitle,
  onHeaderArrowClick,
  renderHeaderRightContent
}) {
  const { isDarkMode } = useColorScheme();
  const { setPageHTMLElement } = usePage();
  const { pathname } = useLocation();
  const origin = window.location.origin;
  const canonicalHref = origin + canonicalURI;
  const urlsToShowFooter = ['/', '/main'];
  return (
    <div id='page' ref={setPageHTMLElement} className='scroll'>
      <Helmet>
        <title>{title ? `HighSignal | ${title}` : 'HighSignal'}</title>
        <meta property='og:url' href={canonicalHref} />
        <link rel='canonical' href={canonicalHref} />
      </Helmet>
      <Tutorial />
      {onHeaderArrowClick && (
        <Header
          title={showTitle ? title : undefined}
          dark={isDarkMode}
          onArrowClick={onHeaderArrowClick}
          renderRightContent={renderHeaderRightContent}
          bgColor={headerBgColor}
        />
      )}
      <MainWrapper
        p={p}
        px={px}
        py={py}
        pt={pt}
        pb={pb}
        pl={pl}
        pr={pr}
        gap={gap}
        disableBottomMargin={disableBottomMargin}
        forceUseHeightFull={forceUseHeightFull}
      >
        {children}
      </MainWrapper>
      {urlsToShowFooter.includes(pathname) && <Footer />}
    </div>
  );
}
