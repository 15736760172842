// 튜토리얼 컴포넌트 컨텍스트

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const TutorialContext = createContext();

export const useTutorial = () => useContext(TutorialContext);

export const TutorialProvider = ({
  initialSequences = [],
  sequenceTexts = [],
  noRedirectUrls = [],
  key = 'HIGHSIGNAL.tutorial_completed',
  children
}) => {
  const tutorialPaths = Array.from(new Set(initialSequences.map((s) => '/' + s.split('_')[0])));

  const navigate = useNavigate();
  const { pathname, hash } = useLocation();

  const tutorialRefs = useRef(
    (() => {
      const obj = {};
      initialSequences.forEach((key) => (obj[key] = undefined));
      return obj;
    })()
  );

  const [tutorialCompleted, setTutorialCompleted] = useState(localStorage.getItem(key) === 'true');

  const [sequences, setSequences] = useState(initialSequences || []);
  const leftSequences = useMemo(() => sequences.filter((o) => !!o), [sequences, pathname, hash]);
  const nextSequence = leftSequences[0] || '';

  const [introShown, setIntroShown] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [tutorialBubbleVisible, setTutorialBubbleVisible] = useState(false);
  const [offsetTop, setOffsetTop] = useState(null);
  const [offsetHeight, setOffsetHeight] = useState(null);
  const [direction, setDirection] = useState('top'); // top,

  const [prevPage, setPrevPage] = useState();
  const [currPage, setCurrPage] = useState();
  const [nextPage, setNextPage] = useState();

  const showIntroduction = () => setIntroShown(true);

  const lowerCurrentIndex = () => setCurrentIndex((prev) => --prev);
  const raiseCurrentIndex = () => setCurrentIndex((prev) => ++prev);

  const hideMessage = () => setTutorialBubbleVisible(false);
  const showMessage = () => setTutorialBubbleVisible(true);

  const completeTutorial = useCallback(() => {
    setCurrentIndex(null);
    localStorage.setItem('HIGHSIGNAL.tutorial_completed', true);
    setTutorialCompleted(true);
    if (pathname !== '/main') navigate('/main');
    else document.getElementById('page').scroll(0, 0);
  }, [pathname]);

  const setTutorialElement = useCallback(
    (sequenceNumber, node, options) => {
      if (node !== null && !tutorialCompleted) {
        tutorialRefs.current[initialSequences[sequenceNumber]] = node;
        if (currentIndex === sequenceNumber) {
          tutorialRefs.current[initialSequences[currentIndex]].style.pointerEvents = 'none';
          tutorialRefs.current[initialSequences[currentIndex]].style.zIndex = 'var(--z-index-6)';
          setOffsetTop(node.offsetTop);
          setOffsetHeight(node.offsetHeight);
          setDirection(options?.direction || 'top');
          showMessage();
        }
      }
    },
    [currentIndex]
  );

  useEffect(() => {
    if (!tutorialCompleted) {
      // intro가 보여지지 않은 상태에서 다른 url에 접근시 main으로 돌아와 튜토리얼을 시작하게 함
      if (!noRedirectUrls.includes(pathname) && !introShown) {
        navigate('/main', { replace: true });
      }
      // 시퀀스에서 currentIndex보다 이전 index인 경우 ''로 방문 처리
      const next = [...sequences].map((o, i) => (i < currentIndex ? '' : initialSequences[i]));
      setSequences(next);
      // 페이지 이동간 시퀀스 처리 위한 상태 저장
      if (currentIndex >= 1) setPrevPage(initialSequences?.[currentIndex - 1]?.split('_')?.[0]);
      setCurrPage(initialSequences?.[currentIndex]?.split?.('_')?.[0]);
      setNextPage(initialSequences?.[currentIndex + 1]?.split?.('_')?.[0]);
      return () => {
        // 튜토리얼 완료시(다음 or 이전 버튼 클릭시) 튜토리얼 위한 ui 원복
        hideMessage();
        if (tutorialRefs.current[initialSequences[currentIndex]]) {
          tutorialRefs.current[initialSequences[currentIndex]].style.pointerEvents = 'auto';
          tutorialRefs.current[initialSequences[currentIndex]].style.zIndex = 'auto';
        }
      };
    }
  }, [currentIndex]);
  useEffect(() => {
    if (!tutorialCompleted && !isNaN(offsetTop) && offsetTop > 0) {
      // 튜토리얼을 진행할 다음 요소의 offsetTop을 가져왔을때
      // 튜토리얼 메시지를 보여주고 page 엘리먼트를 스크롤하여 해당 순서 영역에 위치시킴
      showMessage();
      document.getElementById('page').scroll({
        top: offsetTop < 150 ? 0 : offsetTop - 60,
        behavior: 'smooth'
      });
    }
  }, [offsetTop]);
  useEffect(() => {
    if (!tutorialCompleted) {
      // '~알려줄게요' 하는 인트로 화면 체크
      if (pathname === '/main' && !hash) {
        setTimeout(() => {
          showIntroduction();
        }, 2400);
      }
      // 페이지 이동시
      // 해당 페이지에 해당하는 튜토리얼 순서(index)를 체크하여 페이지 단위로 reset함
      if (pathname !== '/' + nextSequence.split('_')?.[0]) {
        const idx = initialSequences.findIndex((o) => o.includes(pathname.replace('/', '')));
        if (idx > -1) setCurrentIndex(idx);
      }
      return () => {
        hideMessage();
      };
    }
  }, [pathname, hash]);

  return (
    <TutorialContext.Provider
      value={{
        setTutorialElement,
        sequenceTexts,
        initialSequences,
        tutorialPaths,
        currentIndex,
        lowerCurrentIndex,
        raiseCurrentIndex,
        prevPage,
        currPage,
        nextPage,
        introShown,
        showIntroduction,
        tutorialCompleted,
        completeTutorial,
        offsetTop,
        offsetHeight,
        direction,
        leftSequences,
        tutorialBubbleVisible,
        hideMessage
      }}
    >
      {children}
    </TutorialContext.Provider>
  );
};
