import { useCallback, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { instance } from '@services/api';

export default function useFetch(param) {
  const { initialData = null } = param || {};
  const [data, setData] = useState(initialData);
  const [isFirstLoaded, setIsFirstLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [recentFetched, setRecentFetched] = useState('');
  const [error, setError] = useState(null);

  // 데이터 초기화
  const initializeData = useCallback(() => setData(initialData), []);
  // 데이터 GET
  const getData = useCallback(
    async (requestUrl, params, processor) => {
      setFetching(true);
      setIsLoaded(false);
      await instance
        .get(requestUrl, { params })
        .then((res) => {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log(dayjs().format('HH:mm:ss'), res.data);
          }
          if (res.data) {
            setData((prev) =>
              typeof processor === 'function'
                ? (() => {
                    let result;
                    try {
                      result = processor(prev, res.data);
                      if (!result) throw new Error('반환 값이 없습니다.');
                    } catch (error) {
                      console.error('데이터 처리에 실패했습니다.');
                      result = prev;
                    }
                    return result;
                  })()
                : res.data
            );
          }
          setRecentFetched(dayjs().format('HH:mm:ss.SSS'));
          setIsLoaded(true);
          if (!isFirstLoaded) setIsFirstLoaded(true);
        })
        .catch((err) => {
          setError(err);
        });
      setFetching(false);
    },
    [isFirstLoaded]
  );
  // isFirstLoaded -> false
  const resetFirstLoaded = useCallback(() => setIsFirstLoaded(false), []);
  // isLoaded -> false
  const resetLoaded = useCallback(() => setIsLoaded(false), []);

  useEffect(() => {
    return () => {
      initializeData();
      setIsFirstLoaded(false);
      setIsLoaded(false);
      setFetching(false);
      setRecentFetched('');
      setError(null);
    };
  }, []);

  return {
    data,
    isFirstLoaded,
    isLoaded,
    fetching,
    recentFetched,
    error,
    getData,
    initializeData,
    resetFirstLoaded,
    resetLoaded
  };
}
