export default [
  { code: '247540', kor_name: '에코프로비엠' },
  { code: '086520', kor_name: '에코프로' },
  { code: '028300', kor_name: 'HLB' },
  { code: '196170', kor_name: '알테오젠' },
  { code: '348370', kor_name: '엔켐' },
  { code: '058470', kor_name: '리노공업' },
  { code: '068760', kor_name: '셀트리온제약' },
  { code: '403870', kor_name: 'HPSP' },
  { code: '277810', kor_name: '레인보우로보틱스' },
  { code: '039030', kor_name: '이오테크닉스' },
  { code: '214150', kor_name: '클래시스' },
  { code: '145020', kor_name: '휴젤' },
  { code: '065350', kor_name: '신성델타테크' },
  { code: '000250', kor_name: '삼천당제약' },
  { code: '357780', kor_name: '솔브레인' },
  { code: '035900', kor_name: 'JYP Ent.' },
  { code: '005290', kor_name: '동진쎄미켐' },
  { code: '041510', kor_name: '에스엠' },
  { code: '263750', kor_name: '펄어비스' },
  { code: '240810', kor_name: '원익IPS' },
  { code: '095340', kor_name: 'ISC' },
  { code: '067630', kor_name: 'HLB생명과학' },
  { code: '141080', kor_name: '리가켐바이오' },
  { code: '293490', kor_name: '카카오게임즈' },
  { code: '417200', kor_name: 'LS머트리얼즈' },
  { code: '035760', kor_name: 'CJ ENM' },
  { code: '036930', kor_name: '주성엔지니어링' },
  { code: '112040', kor_name: '위메이드' },
  { code: '237690', kor_name: '에스티팜' },
  { code: '064760', kor_name: '티씨케이' },
  { code: '005930', kor_name: '삼성전자' },
  { code: '000660', kor_name: 'SK하이닉스' },
  { code: '373220', kor_name: 'LG에너지솔루션' },
  { code: '207940', kor_name: '삼성바이오로직스' },
  { code: '005380', kor_name: '현대차' },
  { code: '000270', kor_name: '기아' },
  { code: '068270', kor_name: '셀트리온' },
  { code: '005490', kor_name: 'POSCO홀딩스' },
  { code: '035420', kor_name: 'NAVER' },
  { code: '006400', kor_name: '삼성SDI' },
  { code: '051910', kor_name: 'LG화학' },
  { code: '105560', kor_name: 'KB금융' },
  { code: '028260', kor_name: '삼성물산' },
  { code: '012330', kor_name: '현대모비스' },
  { code: '035720', kor_name: '카카오' },
  { code: '055550', kor_name: '신한지주' },
  { code: '003670', kor_name: '포스코퓨처엠' },
  { code: '032830', kor_name: '삼성생명' },
  { code: '086790', kor_name: '하나금융지주' },
  { code: '066570', kor_name: 'LG전자' },
  { code: '138040', kor_name: '메리츠금융지주' },
  { code: '000810', kor_name: '삼성화재' },
  { code: '015760', kor_name: '한국전력' },
  { code: '259960', kor_name: '크래프톤' },
  { code: '003550', kor_name: 'LG' },
  { code: '012450', kor_name: '한화에어로스페이스' },
  { code: '033780', kor_name: 'KT&G' },
  { code: '018260', kor_name: '삼성에스디에스' },
  { code: '034730', kor_name: 'SK' },
  { code: '323410', kor_name: '카카오뱅크' }
];
