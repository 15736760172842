/** @jsxImportSource @emotion/react */

import { useFetcher, useRouteLoaderData } from 'react-router-dom';

import Box from '@components/Box';
import Button from '@components/Button';
import KakaoLogin from '@sections/KakaoLogin';
import Page from '@components/Page';

export default function LogoutPage() {
  let { authenticated, status, message } = useRouteLoaderData('root');
  return (
    <Page p={1}>
      {authenticated ? (
        <AuthStatus />
      ) : (
        <Box gap={1}>
          <p>로그인 상태가 아닙니다.</p>
          <KakaoLogin />
        </Box>
      )}
    </Page>
  );
}
function AuthStatus() {
  let fetcher = useFetcher();
  let isLoggingOut = fetcher.formData != null;
  return (
    <Box element={fetcher.Form} method='post' action='/sign-out'>
      <Button
        type='submit'
        loading={isLoggingOut}
        text={isLoggingOut ? '로그아웃중...' : '로그아웃'}
      />
    </Box>
  );
}
