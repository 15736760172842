import 'react-circular-progressbar/dist/styles.css';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useEffect, useState } from 'react';

import addTransparencyOnHexColor from '@utils/addTransparencyOnHexColor';
import { colors } from '@constants/color';
import styled from '@emotion/styled';

const CirclePercentageWrapper = styled('div')((props) => {
  return {
    position: 'relative',
    minHeight: '130px',
    minWidth: '130px',
    height: '130px',
    width: '130px',
    borderRadius: '50%',
    cursor: 'default'
  };
});
const PercentageWrapper = styled('figure')((props) => {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: '100%',
    width: '100%',
    background: 'transparent',
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)',
    transition: 'all 0.2s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    span: {
      fontSize: '0.85rem',
      color: colors.text.default.secondary
    },
    b: {
      transition: 'all 0.1s',
      fontSize: '1.4rem',
      fontWeight: '700',
      color: colors.stock.up.strong
    }
  };
});
export default function CirclePercentage({
  text,
  percentage = 0,
  color: _color = 'primary',
  strokeWidth = 9
}) {
  let pathColor = colors.stock.up.strong;
  let trailColor = addTransparencyOnHexColor(colors.stock.up.strong, 10);
  switch (_color) {
    case 'secondary':
      pathColor = '#F55B22';
      trailColor = '#FFF3EE';
      break;
    default:
      break;
  }

  const [circleValue, setCircleValue] = useState(0);

  useEffect(() => {
    // 0.1초(100ms) 후에 value를 75로 설정
    setTimeout(() => {
      setCircleValue(percentage);
    }, 100);
    // }, [percentage]);
  }, [percentage]);

  return (
    <CirclePercentageWrapper>
      <CircularProgressbar
        value={circleValue}
        strokeWidth={strokeWidth}
        styles={buildStyles({
          pathColor,
          trailColor,
          pathTransitionDuration: 1
        })}
      />
      <PercentageWrapper>
        {text && <span>{text}</span>}
        <b>{percentage}%</b>
      </PercentageWrapper>
    </CirclePercentageWrapper>
  );
}
