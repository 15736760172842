import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useRouteLoaderData } from 'react-router-dom';

import { enqueueSnackbar } from 'notistack';

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

// TODO 유저 정보 불러오기 api 없음, 생기면 user 정보 상태 처리 만들어야
export const AuthProvider = ({ children }) => {
  const redirectURLs = ['/', '/sign-in', '/sign-out'];

  // rootLoader 에서 로그인 처리 성공하면 authenticated 가 true로 전달됨
  const { authenticated, message, status } = useRouteLoaderData('root') || {};
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // state
  const [isAuth, setIsAuth] = useState(!!authenticated);

  useEffect(() => {
    setIsAuth(!!authenticated);
  }, [authenticated]);
  useEffect(() => {
    if (message) enqueueSnackbar(message, { variant: 'error' });
  }, []);

  useEffect(() => {
    if (isAuth && redirectURLs.includes(pathname)) {
      navigate('/main', { replace: true });
    }
  }, [isAuth]);

  return <AuthContext.Provider value={{ isAuth }}>{children}</AuthContext.Provider>;
};
