import { colors } from '@constants/color';
import styled from '@emotion/styled';

const shouldForwardProp = (prop) => !['color', 'squared'].includes(prop);
const ChipWrapper = styled('span', { shouldForwardProp })((props) => {
  let textColor = '#595959';
  let bgColor = '#f5f5f5';
  switch (props.color) {
    case 'red':
      textColor = '#fff';
      bgColor = colors.reds.light;
      break;
    case 'blue':
      textColor = '#2f54eb';
      bgColor = '#e2f4ff';
      break;
    case 'green':
      textColor = '#08979c';
      bgColor = '#e6fffb';
      break;
    default:
      // default
      break;
  }
  return {
    color: textColor,
    backgroundColor: bgColor,
    fontWeight: 500,

    userSelect: 'none',

    display: 'inline-flex',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.4rem 0.6rem',
    borderRadius: props.squared ? 'none' : '100px'
  };
});
export default function Chip({ text, color = 'default', squared, children }) {
  return (
    <ChipWrapper color={color} squared={squared}>
      {text || children}
    </ChipWrapper>
  );
}
