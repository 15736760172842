/** @jsxImportSource @emotion/react */

import Box from '@components/Box';
import Icon from '@components/Icon';
import { colors } from '@constants/color';
import { css } from '@emotion/react';
import { useState } from 'react';

export default function Tooltip({ text }) {
  const [show, setShow] = useState(false);
  return (
    <Box
      css={css`
        margin-left: auto;
        margin-right: 0;
        position: relative;
        width: fit-content;
      `}
    >
      <button
        aria-label='Open tooltip'
        onClick={() => setShow((prev) => !prev)}
        css={css`
          flex-shrink: 0;
          border-radius: 1000px;
          min-width: 1.1rem;
          width: 1.1rem;
          min-height: 1.1rem;
          height: 1.1rem;
          border: 1px solid ${colors.border.default.danger};
          display: inline-flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <Icon.ExclamationMark size='0.8rem' strokeWidth={4} color={colors.text.default.danger} />
      </button>
      <Box
        p={1}
        radius='10px'
        css={css`
          position: absolute;
          top: 1.4rem;
          right: 0;
          background-color: var(--color-bg);
          width: 260px;
          z-index: var(--z-index-6);
          box-shadow: var(--box-shadow-default);
          visibility: ${show ? 'visible' : 'hidden'};
          opacity: ${show ? '1' : '0'};
          transition: opacity 300ms var(--easing-function-1);
          p {
            white-space: pre-wrap;
            word-break: keep-all;
          }
        `}
      >
        <p>{text}</p>
      </Box>
    </Box>
  );
}
