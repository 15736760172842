import { colors } from '@constants/color';
import styled from '@emotion/styled';

const shouldForwardProp = (prop) => !['squared'].includes(prop);
const CheckboxWrapper = styled('label', { shouldForwardProp })((props) => {
  return {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    input: {
      display: 'none'
    },
    span: {
      ':first-of-type': {
        flexShrink: 0,
        border: `1.5px solid ${colors.grays.dark}`,
        borderRadius: props.squared ? '4px' : '50%',
        minWidth: '1.1rem',
        minHeight: '1.1rem',
        width: '1.1rem',
        height: '1.1rem',
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.775' height='7.14' viewBox='0 0 8.775 7.14'%3E%3Cpath id='패스_126' data-name='패스 126' d='M157.1,227.987l2.446,2.609,4.076-5.217' transform='translate(-155.97 -224.255)' fill='none' stroke='${colors.grays.dark.replace(
          '#',
          '%23'
        )}' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.6'/%3E%3C/svg%3E%0A")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '0.6rem',
        backgroundPosition: 'center',
        transition: 'all 50ms'
      },
      ':nth-of-type(2)': {
        marginLeft: '0.5rem',
        fontWeight: 500,
        whiteSpace: 'pre',
        fontSize: '0.9rem',
        color: colors.grays.darker
      }
    },
    'input:checked ~ span:first-of-type': {
      borderColor: '#2f54eb',
      backgroundColor: '#2f54eb',
      backgroundSize: '0.6rem',
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.775' height='7.14' viewBox='0 0 8.775 7.14'%3E%3Cpath id='패스_126' data-name='패스 126' d='M157.1,227.987l2.446,2.609,4.076-5.217' transform='translate(-155.97 -224.255)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.6'/%3E%3C/svg%3E%0A")`
    }
  };
});
export default function Checkbox({ required, label, squared, checked, onChange }) {
  return (
    <CheckboxWrapper squared={squared}>
      <input type='checkbox' onChange={onChange} required={required} checked={checked} />
      <span />
      {label && <span>{label}</span>}
    </CheckboxWrapper>
  );
}
