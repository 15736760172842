import { colors } from '@constants/color';
import styled from '@emotion/styled';

const shouldForwardProp = (prop) => !['color', 'size'].includes(prop);
const CircleButtonWrapper = styled('button', { shouldForwardProp })((props) => {
  let textColor = '#fff';
  let bgColor = colors.bg.default.brand;
  switch (props.color) {
    case 'secondary':
      textColor = colors.black;
      bgColor = '#fff';
      break;
    default:
      break;
  }

  let minHeight = '2.25rem';
  let height = '2.25rem';
  let maxHeight = '2.25rem';
  let fontSize = '1rem';
  switch (props.size) {
    case 'sm':
      minHeight = '1.75rem';
      height = '1.75rem';
      maxHeight = '1.75rem';
      fontSize = 'var(--font-size-sm)';
      break;
    case 'lg':
      minHeight = '3rem';
      height = '3rem';
      maxHeight = '3rem';
      fontSize = 'var(--font-size-lg)';
      break;
    case 'md':
    default:
      break;
  }
  return {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    span: {
      ':first-of-type': {
        width: height,
        minHeight,
        height,
        maxHeight,
        borderRadius: '50%',
        color: textColor,
        backgroundColor: bgColor,
        boxShadow: 'var(--box-shadow-default)',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      ':nth-of-type(2)': {
        marginTop: '0.5rem',
        color: colors.text.default.secondary,
        fontSize: '0.85rem'
      }
    }
  };
});
export default function CircleButton({
  type = 'button',
  helpText,
  onClick,
  color = 'primary',
  icon,
  size
}) {
  return (
    <CircleButtonWrapper type={type} onClick={onClick} color={color} size={size}>
      <span>{icon}</span>
      {helpText && <span>{helpText}</span>}
    </CircleButtonWrapper>
  );
}
