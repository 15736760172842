/** @jsxImportSource @emotion/react */

import Box from '@components/Box';
import Button from '@components/Button';
import Checkbox from '@components/Checkbox';
import Input from '@components/Input';
import KakaoLogin from '@sections/KakaoLogin';
import Page from '@components/Page';
import { colors } from '@constants/color';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

export default function SignUpPage() {
  const navigate = useNavigate();

  return (
    <Page p={1} gap={3} onHeaderArrowClick={() => navigate(-1)}>
      <Box gap={0.5}>
        <h3>
          HIGH SIGNAL
          <br />
          회원가입
        </h3>
        <p>회원 정보를 입력해주세요</p>
      </Box>

      <KakaoLogin />

      <div
        css={css`
          width: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          hr {
            width: 100%;
          }
          span {
            color: ${colors.text.default.tertiary};
            flex-shrink: 0;
            padding: 0 0.5rem;
          }
        `}
      >
        <hr />
        <span>또는</span>
        <hr />
      </div>

      <Box element='form' gap={0.5}>
        <Input required type='text' label='아이디' placeholder='아이디를 입력하세요' />
        <Input required type='password' label='비밀번호' placeholder='비밀번호를 입력하세요' />
        <Input
          required
          type='password'
          label='비밀번호 확인'
          placeholder='비밀번호를 재입력하세요'
        />
        <Input required type='text' label='이름' placeholder='이름을 입력하세요' />
        <Input
          required
          type='text'
          label='휴대폰번호'
          placeholder='숫자만 입력하세요'
          inputMode='numeric'
          pattern='[0-9]*'
        />
        <Input type='text' label='코드' placeholder='코드를 입력하세요' />
        <Box hAlign='center'>
          <Checkbox
            required
            id='agreement'
            label='본 솔루션을 통한 투자의 책임은 본인에게 있습니다.'
            squared
          />
        </Box>
        <Button
          type='submit'
          onClick={(event) => {
            event.preventDefault();
            navigate('/main');
          }}
          size='lg'
          text='가입하기'
        />
      </Box>
    </Page>
  );
}
