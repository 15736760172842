import { colors } from '@constants/color';
import styled from '@emotion/styled';

const SwitchWrapper = styled('div')((props) => {
  return {
    display: 'flex',
    alignItems: 'center'
    // justifyContent: 'space-between',
    // width: '100%'
  };
});
const shouldForwardProp = (prop) => !['size'].includes(prop);
const InputWrapper = styled('div', { shouldForwardProp })((props) => {
  let height = 1.8;
  switch (props.size) {
    case 'sm':
      height = 1.4;
      break;
    case 'lg':
      height = 2.2;
      break;
    default:
      break;
  }
  return {
    '--height': `${height || 2.4}rem`,
    width: 'calc(var(--height) * 1.6)',
    height: 'var(--height)',
    borderRadius: 'calc(var(--height) / 2)',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'background-color 200ms ease-in-out',
    backgroundColor: colors.gray,
    ':has(> input:checked)': {
      backgroundColor: colors.blue
    }
  };
});
const Input = styled('input')((props) => {
  return {
    appearance: 'none',
    zIndex: 1,
    position: 'absolute',
    cursor: 'pointer',
    backgroundColor: 'white',
    border: 'none',
    outline: 'none',
    width: 'calc(var(--height) / 1.2)',
    height: 'calc(var(--height) / 1.2)',
    borderRadius: '50%',
    boxShadow: `0px 1px 3px ${colors.grays.dark}`,
    transform: 'translateX(-36%)',
    transition: '200ms ease-in-out',
    '&:checked': {
      transform: 'translateX(36%)'
    }
  };
});
const Label = styled('label')((props) => {
  return {
    fontSize: '1em',
    marginLeft: '1rem',
    cursor: 'pointer'
  };
});
export default function Switch({
  id = 'default-switch',
  label,
  readOnly,
  defaultChecked,
  checked,
  onClick,
  size = 'md',
  ...attr
}) {
  return (
    <SwitchWrapper>
      <InputWrapper
        onClick={(event) => event.currentTarget.firstElementChild?.click?.()}
        size={size}
      >
        <Input
          id={id}
          type='checkbox'
          readOnly={readOnly}
          defaultChecked={defaultChecked}
          checked={checked}
          {...attr}
          onClick={(event) => {
            onClick?.();
            event.stopPropagation(); // parent의 onClick 이벤트를 막음
          }}
        />
      </InputWrapper>
      {label && <Label htmlFor={id}>{label}</Label>}
    </SwitchWrapper>
  );
}
