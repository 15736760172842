/** @jsxImportSource @emotion/react */

import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import { PageProvider, usePage } from '@contexts/PageContext';
import { Suspense, lazy, useCallback } from 'react';
import { tutorialSequenceKeys, tutorialSequenceMessages } from '@constants/etc';

import { AuthProvider } from '@contexts/AuthContext';
import Box from '@components/Box';
import { ColorSchemeProvider } from '@contexts/ColorSchemeContext';
import { ErrorProvider } from '@contexts/ErrorContext';
import { Outlet } from 'react-router-dom';
import { TutorialProvider } from '@contexts/TutorialContext';
import { colors } from '@constants/color';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';

const LazyChannelService = lazy(() => import('../components/ChannelIO'));

export default function MainLayout() {
  const noRedirectUrls = ['/', '/main', '/sign-in', '/sign-up'];
  return (
    <ColorSchemeProvider>
      <PageProvider>
        <SnackbarProviderWrapper />
        <AuthProvider>
          <TutorialProvider
            initialSequences={tutorialSequenceKeys}
            sequenceTexts={tutorialSequenceMessages}
            noRedirectUrls={noRedirectUrls}
          >
            <ResponsiveWrapper>
              <ErrorProvider>
                <Outlet />
                <Suspense fallback='로딩중'>
                  <LazyChannelService />
                </Suspense>
              </ErrorProvider>
            </ResponsiveWrapper>
          </TutorialProvider>
        </AuthProvider>
      </PageProvider>
    </ColorSchemeProvider>
  );
}

function SnackbarProviderWrapper() {
  const { pageHTMLElement } = usePage();
  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={2000}
      classes={{ containerRoot: 'z-index-overwrite' }}
      domRoot={pageHTMLElement}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      preventDuplicate
      hideIconVariant
      Components={{
        default: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        success: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent
      }}
    />
  );
}

function ResponsiveWrapper({ children }) {
  const isDesktop = useMediaQuery({ query: '(min-width: 900px)' });
  const renderDesktopView = useCallback(() => {
    return (
      <div
        css={css`
          width: 100%;
          max-width: 450px;
          margin-left: auto;
          display: flex;
          align-items: center;
          opacity: 0;
          animation: intro-main 1.2s 1 forwards;
          animation-delay: 1s;
        `}
      >
        <Box vAlign='center' hAlign='center' gap={2} maxWidth='300px'>
          <Box hAlign='center' gap={0.5} direction='row'>
            <picture>
              <source
                srcSet='/assets/images/logo/logo-symbol.webp'
                width='60'
                height='60'
                type='image/webp'
              />
              <source
                srcSet='/assets/images/logo/logo-symbol.png'
                width='60'
                height='60'
                type='image/png'
              />
              <img
                alt='High Signal logo symbol'
                src='/assets/images/logo/logo-symbol.png'
                width='60'
                height='60'
                loading='lazy'
                css={css`
                  pointer-events: none;
                  user-select: none;
                `}
              />
            </picture>
            <Box
              hAlign='start'
              gap={0.5}
              css={css`
                p:nth-of-type(1) {
                  font-size: 1.4rem;
                  font-weight: bold;
                }
                p:nth-of-type(2) {
                  font-weight: bold;
                  color: ${colors.blue};
                }
              `}
            >
              <p>HIGH SIGNAL</p>
              <p>AI 주식예측 멘토</p>
            </Box>
          </Box>
        </Box>
      </div>
    );
  }, []);
  return (
    <div
      css={css`
        ${isDesktop
          ? `
        display: flex;
          align-items: stretch;
          justify-content: center;
          min-height: 100%;
          & > :nth-of-type(2) {
            margin-left: 0;
            align-self: stretch;
          }`
          : ''}
      `}
    >
      {isDesktop && renderDesktopView()}
      {children}
    </div>
  );
}

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => {
  const common = {
    backgroundColor: colors.bg.default.inverse,
    borderRadius: '0.75rem',
    boxShadow: 'var(--box-shadow-default)'
  };
  return {
    '&.notistack-MuiContent-error': common,
    '&.notistack-MuiContent-success': common,
    '&.notistack-MuiContent-warning': common,
    '&.notistack-MuiContent-info': common
  };
});
