import Icon from '@components/Icon';
import { colors } from '@constants/color';
import styled from '@emotion/styled';

const shouldForwardProp = (prop) => !['color', 'dot', 'outlined'].includes(prop);
const ArrowButtonWrapper = styled('button', { shouldForwardProp })((props) => {
  let color = props.color === 'primary' ? colors.bg.default.brand : colors.blacks.lighter;
  let border = props.outlined
    ? `1px solid ${props.color === 'primary' ? colors.blues.lighter : colors.grays.medium}`
    : 'none';
  return {
    color,
    border,
    display: 'inline-flex',
    alignItems: 'center',
    margin: 0,
    padding: '0.5rem',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    '&:active': {
      backgroundColor: `color-mix(in srgb, ${color} 10%, var(--color-bg))`
    },
    transition: 'all 50ms ease-in-out',
    span: {
      fontSize: '0.8rem',
      position: 'relative',
      marginRight: '0.5rem',
      '::after': {
        display: props.dot ? 'block' : 'none',
        content: '" "',
        position: 'absolute',
        top: 0,
        right: '-5.5px',
        minWidth: '3px',
        minHeight: '3px',
        borderRadius: '50%',
        backgroundColor: color
      }
    }
  };
});

export default function ArrowButton({
  type = 'button',
  onClick = () => {},
  text = 'ArrowButton',
  color = 'primary', // primary, secondary
  outlined,
  dot
}) {
  return (
    <ArrowButtonWrapper type={type} onClick={onClick} color={color} outlined={outlined} dot={dot}>
      <span>{text}</span>
      <Icon.ChevronRight
        color={color === 'primary' ? colors.bg.default.brand : colors.grays.dark}
      />
    </ArrowButtonWrapper>
  );
}
