import React, { Component } from 'react';

import ErrorElement from './ErrorElement';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: { message: '', stack: '' },
      errorInfo: { componentStack: '' }
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by ErrorBoundary: ', error, errorInfo);
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      if (this.props.fallback) return this.props.fallback;
      return <ErrorElement message='오류가 발생했습니다.' />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
