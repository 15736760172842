/** @jsxImportSource @emotion/react */

import Box from '@components/Box';
import Button from '@components/Button';
import Input from '@components/Input';
import KakaoLogin from '@sections/KakaoLogin';
import Page from '@components/Page';
import { colors } from '@constants/color';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

export default function SignInPage() {
  const navigate = useNavigate();

  return (
    <Page p={1} gap={3} onHeaderArrowClick={() => navigate(-1)}>
      <Box gap={0.5}>
        <h3>HIGH SIGNAL</h3>
        <p>로그인해주세요</p>
      </Box>

      <KakaoLogin />

      <div
        css={css`
          width: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          hr {
            width: 100%;
          }
          span {
            color: ${colors.text.default.tertiary};
            flex-shrink: 0;
            padding: 0 0.5rem;
          }
        `}
      >
        <hr />
        <span>또는</span>
        <hr />
      </div>

      <Box element='form' gap={0.5}>
        <Input type='text' placeholder='아이디를 입력하세요' />
        <Input type='password' placeholder='비밀번호를 입력하세요' />
        <Button
          type='submit'
          onClick={(event) => {
            event.preventDefault();
            navigate('/main');
          }}
          size='lg'
          text='로그인'
        />
      </Box>
    </Page>
  );
}
