import { colors } from '@constants/color';
import styled from '@emotion/styled';

const CardWrapper = styled('div')((props) => {
  return {
    width: '100%',
    border: `1px solid ${colors.grays.primary}`,
    backgroundColor: 'var(--color-bg)',
    borderRadius: '6px',
    padding: '1rem',
    '& > h1:first-of-type': {
      fontSize: '1rem',
      textAlign: 'center',
      paddingBottom: '1rem',
      borderBottom: `1px solid ${colors.grays.primary}`
    },
    '& > div:first-of-type': {
      paddingTop: '1rem'
    }
  };
});
export default function Card({ title, children }) {
  return (
    <CardWrapper>
      {title && <h1>{title}</h1>}
      <div>{children}</div>
    </CardWrapper>
  );
}
const CardSimpleWrapper = styled('div')((props) => {
  return {
    width: '100%',
    border: '1px solid #f6f6f8',
    backgroundColor: '#f7fafd',
    borderRadius: '6px',
    padding: '1rem',
    fontWeight: 500,
    'div:first-of-type': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '1.2rem',
      '> h2': {
        fontSize: '1rem',
        fontWeight: 'bold',
        marginBottom: 0
      },
      '> time': {
        fontSize: '0.8rem',
        color: '#b2b9c6',
        letterSpacing: 0
      }
    },
    p: {
      lineHeight: '1.6rem',
      color: '#414141',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
      wordWrap: 'break-word',
      wordBreak: 'keep-all'
    }
  };
});
function Simple({ title, date, description }) {
  return (
    <CardSimpleWrapper>
      <div>
        <h2>{title}</h2>
        {date && <time dateTime={date}>{date}</time>}
      </div>
      <p>{description}</p>
    </CardSimpleWrapper>
  );
}
Card.Simple = Simple;
