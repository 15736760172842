import { colors } from '@constants/color';
import styled from '@emotion/styled';

const IconWrapper = styled.img((props) => {
  let width = '1.4rem';
  switch (props['size']) {
    case 'lg':
      width = '2rem';
      break;
    case 'sm':
      width = '1rem';
      break;
    default:
      break;
  }
  return {
    flexShrink: 0,
    userSelect: 'none',
    width
  };
});

function ArrowLeft() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'>
      <g id='그룹_312' data-name='그룹 312' transform='translate(-16 -13)'>
        <path
          id='패스_355'
          data-name='패스 355'
          d='M0,0H22V22H0Z'
          transform='translate(16 13)'
          fill='none'
        />
        <g id='그룹_311' data-name='그룹 311' transform='translate(-2.5 -1)'>
          <path
            id='패스_356'
            data-name='패스 356'
            d='M15,6,9,12l6,6'
            transform='translate(15.5 13)'
            fill='none'
            stroke='#bdc8d9'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <line
            id='선_68'
            data-name='선 68'
            x2='12'
            transform='translate(24.5 24.95)'
            fill='none'
            stroke='#bdc8d9'
            strokeLinecap='round'
            strokeWidth='2'
          />
        </g>
      </g>
    </svg>
  );
}

function ChevronLeft({ color = colors.bg.default.brand, size = '1.2rem', strokeWidth = 2 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15 6L9 12L15 18'
        stroke={typeof color === 'string' ? color : undefined}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

function ChevronDown({ color = colors.bg.default.brand, size = '1.2rem', strokeWidth = 2 }) {
  return (
    <svg
      fill='none'
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={strokeWidth}
      viewBox='0 0 24 24'
      width={size}
      height={size}
      xmlns='http://www.w3.org/2000/svg'
    >
      <polyline points='6 9 12 15 18 9' />
    </svg>
  );
}

function ChevronRightLg() {
  return (
    <IconWrapper
      alt='icon-chevron-right-lg'
      size='lg'
      src='/assets/images/icon/icon-arrow-right.svg'
      draggable={false}
    />
  );
}

function ChevronRightSm({ color, size = '1rem', strokeWidth = 5 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.83429 11.7657C5.52187 11.4533 5.52187 10.9467 5.83429 10.6343L8.4686 8L5.83429 5.36569C5.52187 5.05327 5.52187 4.54673 5.83429 4.23431C6.14671 3.9219 6.65324 3.9219 6.96566 4.23431L10.1657 7.43431C10.4781 7.74673 10.4781 8.25327 10.1657 8.56569L6.96566 11.7657C6.65324 12.0781 6.14671 12.0781 5.83429 11.7657Z'
      />
    </svg>
  );
}

function ChevronRight({ color = colors.bg.default.brand, size = '0.75rem', strokeWidth = 0 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 4.8 10'
      strokeWidth={strokeWidth}
      stroke={color}
      color={color}
    >
      <g transform='translate(-107.6 -7)'>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          fill='currentColor'
          fillRule='evenodd'
          d='M107.834,15.766a.8.8,0,0,1,0-1.131L110.469,12l-2.635-2.634a.8.8,0,0,1,1.132-1.131l3.2,3.2a.8.8,0,0,1,0,1.131l-3.2,3.2A.8.8,0,0,1,107.834,15.766Z'
        />
      </g>
    </svg>
  );
}

function ExclamationMark({ color = '#000000', size = '1.5rem', strokeWidth = 2 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      strokeWidth={strokeWidth}
    >
      <path d='M12 5V15M12 19H12.01' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
}

function MagnifyGlass({ color, size = '1.5rem', strokeWidth = 0.5 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 20'
      strokeWidth={strokeWidth}
      stroke={color}
      color={color}
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='currentColor'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.33333 3.125C5.45685 3.125 3.125 5.45685 3.125 8.33333C3.125 11.2098 5.45685 13.5417 8.33333 13.5417C11.2098 13.5417 13.5417 11.2098 13.5417 8.33333C13.5417 5.45685 11.2098 3.125 8.33333 3.125ZM1.875 8.33333C1.875 4.76649 4.76649 1.875 8.33333 1.875C11.9002 1.875 14.7917 4.76649 14.7917 8.33333C14.7917 9.89168 14.2397 11.3211 13.3207 12.4368L17.9419 17.0581C18.186 17.3021 18.186 17.6979 17.9419 17.9419C17.6979 18.186 17.3021 18.186 17.0581 17.9419L12.4368 13.3207C11.3211 14.2397 9.89168 14.7917 8.33333 14.7917C4.76649 14.7917 1.875 11.9002 1.875 8.33333Z'
      />
    </svg>
  );
}

function X({ color, size = '1rem', strokeWidth = 3 }) {
  return (
    <svg
      fill='none'
      width={size}
      height={size}
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={strokeWidth}
      color={color}
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <line x1='18' x2='6' y1='6' y2='18' />
      <line x1='6' x2='18' y1='6' y2='18' />
    </svg>
  );
}

const Icon = {
  ArrowLeft,
  ChevronLeft,
  ChevronDown,
  ChevronRight,
  ChevronRightLg,
  ChevronRightSm,
  ExclamationMark,
  MagnifyGlass,
  X
};
export default Icon;
