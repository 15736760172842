/** @jsxImportSource @emotion/react */

import { useCallback, useEffect } from 'react';

import { css } from '@emotion/react';

export default function KakaoLogin() {
  useEffect(() => {
    if (typeof window === 'object' && window?.Kakao) {
      if (!window.Kakao.isInitialized()) window.Kakao.init(process.env.REACT_APP_KAKAO_JS_KEY);
    }
  }, []);

  // ***************** 가져올 수 있는 개인 정보 properties
  // 1. 비즈니스 심사 없이 가져올 수 있지만, 동의 단계 + 동의 목적(필수) 정보가 필요함
  // profile_nickname
  // profile_image
  // account_email
  // friends
  // 2. 비즈니스 심사 후 가져올 수 있음
  // name
  // gender
  // age_range
  // birthday
  // birthyear
  // phone_number
  // account_ci
  // plusfriends
  // shipping_address
  // ***************** 가져올 수 있는 개인 정보 properties
  const handleClick = useCallback(() => {
    if (window.Kakao === undefined) return;
    window.Kakao.Auth.authorize({
      redirectUri: window.location.origin + '/oauth/callback',
      scope: 'account_email,name,phone_number',
      throughTalk: true
    });
  }, []);

  return (
    <button
      onClick={handleClick}
      css={css`
        margin-top: 0;
        width: 100%;
        background-color: #fee500;
        border-radius: ${(12 * 54) / 90}px;
        img {
          height: 54px;
          object-fit: contain;
        }
        @media (prefers-color-scheme: dark) {
          & {
            background-color: #fee500;
          }
        }
      `}
    >
      <img
        alt='Kakao login button'
        src='/assets/images/img/kakao_login_large_wide.png'
        width='360'
        height='54'
        draggable={false}
      />
    </button>
  );
}
