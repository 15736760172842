import React, { useCallback, useEffect } from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';

import Box from '@components/Box';
import Button from '@components/Button';
import { ColorSchemeProvider } from '@contexts/ColorSchemeContext';
import Footer from '@components/Footer';
import { colors } from '@constants/color';

export default function ErrorElement({
  status: statusProp,
  message: messageProp,
  errorType,
  showFooter = true,
  fullHeight = true
}) {
  const navigate = useNavigate();
  const { status, statusText, internal, error, data } = useRouteError() || {};
  errorType = internal ? 'InternalError' : errorType || '';
  useEffect(() => status && console.error({ status, statusText, internal, error, data }), []);
  const renderStatusText = useCallback(() => {
    const s = status || statusProp;
    switch (s) {
      case 404:
        return <p>페이지를 찾을 수 없습니다.</p>;
      default:
        return <p>{statusText || messageProp}</p>;
    }
  }, [status, statusProp, statusText, messageProp]);
  return (
    <ColorSchemeProvider>
      <div id='page' className='scroll'>
        <Box p={1} direction='column' vAlign='center' fullHeight={fullHeight}>
          <Box p={1} gap={1} bgColor={colors.bg.default.secondary} radius={1}>
            <h4>
              {errorType ? `${errorType} ` : ''}
              {status || statusProp}
            </h4>
            {renderStatusText()}
            <Button text='뒤로가기' color='secondary' onClick={() => navigate(-1)} />
          </Box>
        </Box>
        {showFooter && <Footer />}
      </div>
    </ColorSchemeProvider>
  );
}
