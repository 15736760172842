// Error 컴포넌트 컨텍스트

import React, { createContext, useContext, useEffect, useState } from 'react';

import ErrorElement from '@components/ErrorElement';
import { useLocation } from 'react-router-dom';

const ErrorContext = createContext();

export const useError = () => useContext(ErrorContext);

export const ErrorProvider = ({ children }) => {
  const { pathname } = useLocation();
  const [hasError, setHasError] = useState(false);
  const [errorType, setErrorType] = useState('');
  const [status, setStatus] = useState();
  const [message, setMessage] = useState('');

  const setError = ({ status, message, errorType }) => {
    if (!status) return;
    setHasError(true);
    setErrorType(errorType);
    setStatus(status);
    setMessage(message);
  };

  useEffect(() => {
    return () => {
      setHasError(false);
      setErrorType('');
      setStatus();
      setMessage('');
    };
  }, [pathname]);

  return (
    <ErrorContext.Provider value={{ setError }}>
      {hasError ? (
        <ErrorElement status={status} message={message} errorType={errorType} />
      ) : (
        children
      )}
    </ErrorContext.Provider>
  );
};
