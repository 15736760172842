import Icon from './Icon';
import { colors } from '@constants/color';
import styled from '@emotion/styled';

const shouldForwardProp = (prop) => !['color', 'squared'].includes(prop);
const ChipButtonWrapper = styled('div', { shouldForwardProp })((props) => {
  let textColor = '#595959';
  let bgColor = '#f5f5f5';
  switch (props.color) {
    case 'red':
      textColor = '#fff';
      bgColor = colors.reds.light;
      break;
    case 'blue':
      textColor = '#2f54eb';
      bgColor = '#e2f4ff';
      break;
    case 'green':
      textColor = '#08979c';
      bgColor = '#e6fffb';
      break;
    default:
      // default
      break;
  }
  return {
    color: textColor,
    fontWeight: 500,

    userSelect: 'none',

    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: bgColor,
    borderRadius: props.squared ? 'none' : '100px',

    'button:first-of-type': {
      flexShrink: 0,
      whiteSpace: 'pre',
      padding: '0.4rem 0.6rem'
    },
    'button:first-of-type:has(+ button)': {
      padding: '0.4rem 0.2rem 0.4rem 0.6rem'
    },
    'button:nth-of-type(2)': {
      display: 'inline-flex',
      alignItems: 'center',
      padding: '0.4rem'
    }
  };
});
export default function ChipButton({ text, color = 'default', squared, onClick, onXClick }) {
  return (
    <ChipButtonWrapper color={color} squared={squared}>
      <button aria-label={`Go to ${text}`} onClick={onClick}>
        {text}
      </button>
      {typeof onXClick === 'function' && (
        <button aria-label={`Remove ${text}`} onClick={onXClick}>
          <Icon.X color={colors.text.default.disabled} />
        </button>
      )}
    </ChipButtonWrapper>
  );
}
