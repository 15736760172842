import { colors } from '@constants/color';
import styled from '@emotion/styled';

const shouldForwardProp = (prop) => !['required'].includes(prop);
const InputLabelWrapper = styled('label', { shouldForwardProp })((props) => {
  return {
    width: '100%',
    span: {
      fontSize: 'var(--font-size-sm)',
      fontWeight: 600,
      display: 'inline-block',
      marginBottom: '0.25rem',
      '::after': {
        content: props.required ? '"*"' : '""',
        color: colors.text.default.danger,
        fontWeight: 'bold',
        marginLeft: '0.25rem'
      }
    },
    p: {
      fontSize: 'var(--font-size-sm)',
      fontWeight: 500,
      width: '100%',
      marginTop: '0.25rem',
      minHeight: 'var(--font-size-sm)',
      height: 'var(--font-size-sm)'
    }
  };
});
const InputWrapper = styled('input')((props) => {
  return {
    width: '100%',
    fontWeight: 500,
    minHeight: '3rem',
    height: '3rem',
    maxHeight: '3rem',
    borderRadius: '6px',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    border: `1px solid ${colors.border.default.primary}`,
    '::placeholder': {
      color: colors.text.default.disabled
    },
    ':focus': {
      color: colors.text.interactive.secondary,
      border: `1px solid ${colors.border.interactive.secondaryPress}`
    }
  };
});
export default function Input({
  id,
  type = 'text',
  placeholder = '',
  pattern,
  label,
  required,
  helperText
}) {
  return (
    <InputLabelWrapper htmlFor={id} required={required}>
      {label && <span>{label}</span>}
      <InputWrapper
        id={id}
        type={type}
        placeholder={placeholder}
        required={required}
        pattern={pattern}
      />
      <p>{helperText}</p>
    </InputLabelWrapper>
  );
}
