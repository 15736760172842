import { InfinitySpin } from 'react-loader-spinner';
import { colors } from '@constants/color';
import styled from '@emotion/styled';

const LoadingWrapper = styled('div')((props) => {
  return {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999
  };
});
export default function Loading() {
  return (
    <LoadingWrapper>
      <InfinitySpin
        visible={true}
        width='200'
        color={colors.border.default.brand}
        ariaLabel='infinity-spin-loading'
      />
    </LoadingWrapper>
  );
}
