import { colors } from '@constants/color';
import styled from '@emotion/styled';
import { useColorScheme } from '@contexts/ColorSchemeContext';

const shouldForwardProp = (prop) => !['type', 'height', 'isDarkMode', 'color'].includes(prop);
const DividerWrapper = styled('hr', { shouldForwardProp })((props) => {
  let heightWithUnit = !isNaN(props.height) ? props.height + 'rem' : props.height;
  switch (props.type) {
    case 'thin':
      heightWithUnit = '1px';
      break;
    case 'thick':
    default:
      heightWithUnit = '10px';
      break;
  }
  return {
    width: '100%',
    height: heightWithUnit,
    border: 'none',
    backgroundColor: props.isDarkMode ? 'black' : props.color || colors.bg.default.secondary,
    margin: 0
  };
});
export default function Divider({ type = 'thick', height, color }) {
  const { isDarkMode } = useColorScheme();
  return <DividerWrapper type={type} height={height} isDarkMode={isDarkMode} color={color} />;
}
