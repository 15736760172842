import styled from '@emotion/styled';

const TabPanelsWrapper = styled('ul')((props) => {
  return {
    width: '100%',
    marginBottom: '0 !important'
  };
});
export default function TabPanels({ children }) {
  return <TabPanelsWrapper>{children}</TabPanelsWrapper>;
}

const shouldForwardPropItem = (prop) => !['visible'].includes(prop);
const ItemWrapper = styled('li', { shouldForwardProp: shouldForwardPropItem })((props) => {
  if (props.visible)
    return {
      width: '100%'
    };
  else return { display: 'none' };
});
function Item({ visible, children }) {
  return <ItemWrapper visible={visible}>{children}</ItemWrapper>;
}

TabPanels.Item = Item;
