/** @jsxImportSource @emotion/react */

import 'react-circular-progressbar/dist/styles.css';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

import { FreeMode, Pagination } from 'swiper/modules';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { Suspense, lazy, useCallback, useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import ArrowButton from '@components/ArrowButton';
import AuthGuard from '@layouts/AuthGuard';
import Box from '@components/Box';
import Button from '@components/Button';
import Card from '@components/Card';
import Chip from '@components/Chip';
import Divider from '@components/Divider';
import Icon from '@components/Icon';
import { InfinitySpin } from 'react-loader-spinner';
import Page from '@components/Page';
import Searchbar from '@sections/SearchBar';
import TabPanels from '@components/TabPanels';
import Tabs from '@components/Tabs';
import { colors } from '@constants/color';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { marketPreviewData } from '@constants/dataForTutorial';
import rising_list from '@constants/rising_list';
import stocksSample from '@constants/ticker_list_min';
import useFetch from '@hooks/useFetch';
import { useTutorial } from '@contexts/TutorialContext';

const MarketChart = lazy(() => import('../components/MarketChart'));

export default function MainPage() {
  // custom hook
  const { setTutorialElement } = useTutorial();
  // react-router-dom
  const { hash } = useLocation();

  const title = hash === '#search' ? '종목 검색' : '홈';
  const set0TutorialElement = (ref) => setTutorialElement(0, ref);
  return (
    <Page title={title} canonicalURI='/main' gap={1}>
      <MainHeader />
      <div
        ref={set0TutorialElement}
        css={css`
          width: 100%;
        `}
      >
        <Searchbar />
      </div>
      <StockShortcuts />
      <Divider color={colors.blues.lightest} />
      <MarketPreview />
      <Divider color={colors.blues.lightest} />
      <StocksPredictedRising />
    </Page>
  );
}

function MainHeader() {
  const navigate = useNavigate();
  const reload = () => navigate(0);
  return (
    <Box
      element='header'
      p={1}
      gap={1}
      css={css`
        p:last-of-type {
          font-weight: 500;
          color: ${colors.grays.third};
        }
      `}
    >
      <Box
        p={0}
        direction='row'
        gap={0.5}
        hAlign='center'
        onClick={reload}
        css={css`
          h1 {
            font-size: 1.4rem;
            width: 100%;
          }
        `}
      >
        <figure
          css={css`
            position: relative;
            cursor: pointer;
            min-width: 30px;
            width: 30px;
            height: 30px;
            picture,
            img {
              width: 100%;
              height: 100%;
            }
            ::before {
              content: '';
              display: inline-block;
              width: 100%;
              height: 100%;
              background: linear-gradient(
                90deg,
                rgba(19, 194, 194, 0.28) 0%,
                rgba(9, 109, 217, 0.28) 100%
              );
              filter: blur(3px);
              border-radius: 50%;
              position: absolute;
              top: 5px;
              left: 0;
              z-index: 0;
            }
          `}
        >
          <picture>
            <source
              srcSet='/assets/images/logo/logo-symbol.webp'
              width='30'
              height='30'
              type='image/webp'
            />
            <source
              srcSet='/assets/images/logo/logo-symbol.png'
              width='30'
              height='30'
              type='image/png'
            />
            <img
              alt='High Signal logo symbol'
              src='/assets/images/logo/logo-symbol.png'
              width='30'
              height='30'
              loading='lazy'
            />
          </picture>
        </figure>
        <h1>HIGH SIGNAL</h1>
      </Box>
      <p>천문학적인 예측력으로 편안하게 투자하자</p>
    </Box>
  );
}

function StockShortcuts() {
  const swiperModules = [FreeMode, Pagination];
  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        max-width: 450px;
        .swiper-slide {
          width: fit-content;
        }
        .swiper-slide {
          margin-left: 0.5rem;
        }
        .swiper-slide:first-of-type {
          margin-left: 1rem;
        }
        .swiper-slide:last-of-type {
          margin-right: 1rem;
        }
      `}
    >
      <Swiper slidesPerView='auto' freeMode modules={swiperModules}>
        {stocksSample.map((item, idx) => {
          const imgRef = useRef();
          const [isLoaded, setIsLoaded] = useState(false);
          const [url, setUrl] = useState(`/assets/images/logo/${item.code}.png`);
          const handleError = () => {
            setUrl('/assets/images/logo/anonymous.png');
            setIsLoaded(true);
          };
          const handleLoad = () => setIsLoaded(true);
          return (
            <SwiperSlide key={idx}>
              <Link
                to={`/ai-explore/${item.code}`}
                state={item}
                css={css`
                  margin: 0;
                  width: 100%;
                  background-color: ${colors.bg.default.secondary};
                  border-radius: 12px;
                  padding: 1rem;
                  display: flex;
                  align-items: center;
                `}
              >
                <figure
                  css={css`
                    min-width: 30px;
                    min-height: 30px;
                    width: 30px;
                    height: 30px;
                    aspect-ratio: 1;
                    border-radius: 50%;
                    margin-right: 0.5rem;
                    flex-shrink: 0;
                    overflow: hidden;
                  `}
                >
                  <img
                    aria-label='Placeholder for error or loading image'
                    ref={imgRef}
                    alt={item.code}
                    width='30'
                    height='30'
                    src={url}
                    onError={handleError}
                    onLoad={handleLoad}
                    css={css`
                      display: none;
                    `}
                  />
                  {isLoaded && (
                    <img
                      aria-label={`Logo for ${item.code}`}
                      alt={item.code}
                      width='30'
                      height='30'
                      src={url}
                    />
                  )}
                </figure>
                <p>
                  <b>{item.kor_name}</b>
                </p>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}

function MarketPreview() {
  // custom hooks
  const { setTutorialElement, tutorialCompleted } = useTutorial();
  // fetch hook
  const { data, isLoaded, getData } = useFetch({ initialData: { charts: [] } });
  const serieses = new Map([
    ['코스피', data?.charts?.[0]?.dates || []],
    ['코스닥', data?.charts?.[1]?.dates || []]
  ]);
  const signals = new Map([
    ['코스피', data?.charts?.[0]?.signals || []],
    ['코스닥', data?.charts?.[1]?.signals || []]
  ]);
  // state for UI
  // 코스피, 코스닥 상태
  const indexes = ['코스피', '코스닥'];
  const [tabIndex, setTabIndex] = useState(0);
  // 차트 6개월, 1년 상태
  const chartDurations = ['6개월', '1년'];
  const [chartDuration, setChartDuration] = useState(0);

  const set1TutorialElement = (ref) => setTutorialElement(1, ref);
  const handleIndexClick = (idx) => () => setTabIndex(idx);
  const handleChartDurationChange = (event) => setChartDuration(Number(event.target.value));

  useEffect(() => {
    if (tutorialCompleted) {
      getData('/main_chart', undefined, (prev, next) => {
        const kospi = next?.charts[0];
        const kosdaq = next?.charts[1];
        kospi?.dates?.sort?.((a, b) => dayjs(a.date).unix() - dayjs(b.date).unix());
        kosdaq?.dates?.sort?.((a, b) => dayjs(a.date).unix() - dayjs(b.date).unix());
        kospi?.signals?.sort?.((a, b) => dayjs(a.date).unix() - dayjs(b.date).unix());
        kosdaq?.signals?.sort?.((a, b) => dayjs(a.date).unix() - dayjs(b.date).unix());
        return next;
      });
    }
  }, [tutorialCompleted]);

  // 튜토리얼시 차트, 데이터 로딩 중/후 차트 렌더링
  const renderMarketChart = useCallback(() => {
    if (!tutorialCompleted) {
      return (
        <Suspense>
          <MarketChart
            name='코스피'
            series={marketPreviewData[0].dates}
            signals={marketPreviewData[0].signals}
            duration='HALF_YEAR'
          />
        </Suspense>
      );
    }
    if (!isLoaded) {
      return (
        <Box
          hAlign='center'
          direction='column'
          css={css`
            [data-testid='infinity-spin'] {
              width: 150px;
            }
          `}
        >
          <InfinitySpin visible={true} color={colors.border.default.brand} />
        </Box>
      );
    } else {
      return (
        <Suspense>
          <MarketChart
            name={indexes[tabIndex]}
            series={serieses.get(indexes[tabIndex])}
            signals={signals.get(indexes[tabIndex])}
            duration={chartDuration === 0 ? 'HALF_YEAR' : 'FULL_YEAR'}
          />
        </Suspense>
      );
    }
  }, [tutorialCompleted, isLoaded, tabIndex, chartDuration]);

  return (
    <Box px={1}>
      <Box
        ref={set1TutorialElement}
        bgColor={colors.blues.lightest}
        px={1}
        py={1.2}
        gap={0.5}
        radius={0.5}
      >
        <Box
          gap={0.25}
          css={css`
            h2 {
              font-size: 1.4rem;
              color: ${colors.blues.dark};
            }
            p {
              color: ${colors.blues.dim};
              font-weight: 500;
              font-size: 0.9rem;
              line-height: 1.5;
            }
          `}
        >
          <h2>시장 종합 예측</h2>
          <p>시장 예측치를 확인하여 미리 시장을 대비하세요.</p>
        </Box>
        <Tabs gap={0.5}>
          {indexes.map((text, idx) => (
            <Tabs.Item key={text} sameWidth>
              <button
                type='button'
                onClick={handleIndexClick(idx)}
                css={css`
                  color: white;
                  background-color: ${idx === tabIndex ? colors.blues.light : colors.blues.lighter};
                  border-radius: 12px;
                  width: 100%;
                  padding: 0.8rem;
                  white-space: nowrap;
                  margin-top: 0;
                `}
              >
                {text}
              </button>
            </Tabs.Item>
          ))}
        </Tabs>
        <TabPanels>
          <Box element='li' gap={1}>
            <Box
              vAlign='center'
              css={css`
                position: relative;
                min-height: ${tutorialCompleted ? '240px' : '140px'};
              `}
            >
              {renderMarketChart()}
            </Box>
            <Box
              element='fieldset'
              direction='row'
              p={0.25}
              gap={0.25}
              radius='11px'
              onChange={handleChartDurationChange}
            >
              {chartDurations.map((item, idx) => (
                <Button
                  key={item}
                  inputType='radio'
                  name='chart duration'
                  color='text'
                  size='sm'
                  value={idx}
                  text={item}
                  checked={chartDuration === idx}
                  readOnly
                  css={css`
                    input:active label {
                      color: red;
                    }
                  `}
                />
              ))}
            </Box>
            <Box
              p={0.5}
              gap={1}
              direction='row'
              hAlign='center'
              bgColor='white'
              radius={0.5}
              css={css`
                img {
                  min-width: 40px;
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  overflow: hidden;
                }
                div > p {
                  font-size: 0.9rem;
                  line-height: 1.5;
                }
                div > p > span {
                  color: ${colors.stock.down.strong};
                  font-weight: bold;
                }
              `}
            >
              <img
                alt='Expert profile'
                src='/assets/images/img/hancheolkim-ai-profile.jpg'
                width='40'
                height='40'
              />
              <div>
                <p>
                  "증시가 <span style={{color: 'red'}}>상승</span> 추세입니다. <br />
                  AI와 함께 공격적인 투자 전략을 고려해보세요."
                </p>
              </div>
            </Box>
          </Box>
        </TabPanels>
      </Box>
    </Box>
  );
}

function StocksPredictedRising() {
  // custom hooks
  const { setTutorialElement, tutorialCompleted } = useTutorial();
  // react-router-dom
  const navigate = useNavigate();
  // state
  const [risings, setRisings] = useState([]);

  const set2TutorialElement = (ref) => setTutorialElement(2, ref);
  useEffect(() => {
    setRisings(rising_list);
  }, []);
  const renderItem = useCallback(
    ({ name, ticker = '123456', src, expected_return_20days, rank }) => {
      const handleClick = () => navigate(`/ai-explore/${ticker}`);
      return (
        <Box
          key={name}
          direction='row'
          hAlign='center'
          gap={0.5}
          onClick={handleClick}
          css={css`
            & > b {
              color: ${colors.text.default.brand};
            }
          `}
        >
          <b>{rank}</b>
          <Box direction='row' hAlign='center' p={0.5} gap={0.5}>
            <figure
              css={css`
                border-radius: 50%;
                overflow: hidden;
                flex-shrink: 0;
                width: 2.5rem;
                height: 2.5rem;
              `}
            >
              <img alt={`Logo for ${ticker}`} src={src} width='45' height='45' />
            </figure>
            <Box
              gap={0.25}
              css={css`
                h2 {
                  font-size: 1rem;
                }
                p {
                  font-size: 0.9rem;
                  b {
                    margin-left: 0.5rem;
                    color: ${expected_return_20days >= 0
                      ? colors.text.default.danger
                      : colors.text.default.brand};
                  }
                }
              `}
            >
              <h2>{name}</h2>
              <p>
                <span>예상수익률</span>
                <b>
                  {expected_return_20days >= 0
                    ? `+${expected_return_20days}`
                    : expected_return_20days}
                  %
                </b>
              </p>
            </Box>
          </Box>
        </Box>
      );
    },
    []
  );
  return (
    <Box element='section' px={1} mt={1} mb={3} gap={1} ref={set2TutorialElement}>
      <Box
        gap={0.5}
        css={css`
          h2 {
            font-size: 1.25rem;
          }
          p {
            font-size: 0.8rem;
            font-weight: 500;
            color: ${colors.text.default.tertiary};
          }
        `}
      >
        <h2>상승 예측 종목 현황</h2>
        <p>이번 주 상승 예상 종목 입니다.</p>
      </Box>

      {tutorialCompleted ? (
        <AuthGuard showGuidance>
          <Card title='상승 예측 종목 TOP 3'>
            {risings.length > 0 ? (
              risings.map((item, idx) => renderItem({ ...item, rank: idx + 1 }))
            ) : (
              <p
                css={css`
                  font-size: 0.8rem;
                  text-align: center;
                  color: ${colors.text.default.tertiary};
                `}
              >
                예측 대기중입니다
              </p>
            )}
          </Card>
        </AuthGuard>
      ) : (
        <FakeStocksPredictedRising />
      )}
    </Box>
  );
}
function FakeStocksPredictedRising() {
  const renderItem = useCallback(
    ({ name, ticker = '123456', src, expected_return_20days, rank }) => {
      return (
        <Box
          key={name}
          direction='row'
          hAlign='center'
          gap={0.5}
          css={css`
            & > b {
              color: ${colors.text.default.brand};
            }
          `}
        >
          <b>{rank}</b>
          <Box direction='row' hAlign='center' p={0.5} gap={0.5}>
            <figure
              css={css`
                border-radius: 50%;
                overflow: hidden;
                flex-shrink: 0;
                width: 2.5rem;
                height: 2.5rem;
              `}
            >
              <img alt={`Logo for ${ticker}`} src={src} width='45' height='45' />
            </figure>
            <Box
              gap={0.25}
              css={css`
                h2 {
                  font-size: 1rem;
                }
                p {
                  font-size: 0.9rem;
                  b {
                    margin-left: 0.5rem;
                    color: ${expected_return_20days >= 0
                      ? colors.text.default.danger
                      : colors.text.default.brand};
                  }
                }
              `}
            >
              <h2>{name}</h2>
              <p>
                <span>예상수익률</span>
                <b>
                  {expected_return_20days >= 0
                    ? `+${expected_return_20days}`
                    : expected_return_20days}
                  %
                </b>
              </p>
            </Box>
          </Box>
        </Box>
      );
    },
    []
  );
  return rising_list.map((item, idx) => renderItem({ ...item, rank: idx + 1 }));
}

function PopularList() {
  const navigate = useNavigate();
  const [trendings] = useState([
    { ticker: 0, name: '이수페타시스', hold: true, ror: -1.4 },
    { ticker: 0, name: '센서뷰', hold: false, ror: 0 },
    { ticker: 0, name: '어보브반도체', hold: true, ror: +12.5 }
  ]);
  const renderItem = (item) => {
    return (
      <li
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 0;
          border-bottom: 1px solid ${colors.grays.primary};
          h3 {
            font-size: 16px;
            font-weight: 600;
            // width: 100%;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
          }
          button {
            display: flex;
            align-items: center;
            margin: 0;
            margin-left: auto;
            font-size: 18px;
            font-weight: 600;
            color: ${item.ror < 0 ? colors.blue : colors.red};
            span {
              margin-right: 0.5rem;
            }
          }
        `}
        onClick={() => navigate(`/stock/${item.ticker}`)}
      >
        {item.hold ? <Chip color='red' text='보유중' /> : <Chip text='관망중' />}
        <h3>{item.name}</h3>
        <button type='button'>
          <span className='percent-txt'>{item.ror < 0 ? item.ror : `+${item.ror}`}%</span>
          <Icon.ChevronRightSm />
        </button>
      </li>
    );
  };
  return (
    <Box element='section' px={2} py={3} gap={2}>
      <Box direction='row' hAlign='center' gap={1}>
        <Box gap={1}>
          <h2>인기 종목 예측 현황</h2>
          <p
            css={css`
              font-size: 0.8rem;
              color: ${colors.grays.dark};
              font-weight: 500;
            `}
          >
            현재 문의가 많은 종목
          </p>
        </Box>
        <ArrowButton
          onClick={() => navigate('/ai-explore')}
          text='인기종목 AI 매매 신호받기'
          dot
          outlined
        />
      </Box>

      <Box>
        <ul
          css={css`
            margin-bottom: 3rem;
            width: 100%;
          `}
        >
          {trendings.map((item) => renderItem(item))}
        </ul>
        <Button
          text='인기종목 더보기'
          onClick={() => navigate('/ai/forecast/popular')}
          color='secondary'
          outlined
        />
      </Box>
    </Box>
  );
}

function TrendingList() {
  const navigate = useNavigate();
  const [trendings] = useState([
    {
      ticker: 0,
      name: 'SK하이닉스',
      hold: true,
      ror: -1.4,
      content: `삼성전자가 7년 동안 이어진 사법 리스크에 발이 묶인 동안, 글로벌 주요 반도체
    경쟁사들은 발 빠르게 움직이며 세계 시장에서 영향력을 확대하고 있다. 대만 TSMC는
    반도체 파운드리(위탁 생산) 분야 선두를 확고히 다졌고, 미국 인텔과 일본 라피더스 등
    후발 주자는 국가적 지원을 받아 삼성전자를 거세게 추격하고 있다. 2030년까지 메모리
    반도체와 파운드리를 포함한 시스템 반도체 모두에서 글로벌 1위를 목표로 하고 있는
    삼성전자가 직면한 경쟁이 한층 치열해졌다는 평가가 나온다.`
    },
    {
      ticker: 0,
      name: '센서뷰',
      hold: false,
      ror: 0,
      content: `삼성전자가 7년 동안 이어진 사법 리스크에 발이 묶인 동안, 글로벌 주요 반도체
    경쟁사들은 발 빠르게 움직이며 세계 시장에서 영향력을 확대하고 있다. 대만 TSMC는
    반도체 파운드리(위탁 생산) 분야 선두를 확고히 다졌고, 미국 인텔과 일본 라피더스 등
    후발 주자는 국가적 지원을 받아 삼성전자를 거세게 추격하고 있다. 2030년까지 메모리
    반도체와 파운드리를 포함한 시스템 반도체 모두에서 글로벌 1위를 목표로 하고 있는
    삼성전자가 직면한 경쟁이 한층 치열해졌다는 평가가 나온다.`
    },
    {
      ticker: 0,
      name: '어보브반도체',
      hold: true,
      ror: 12.5,
      content: `삼성전자가 7년 동안 이어진 사법 리스크에 발이 묶인 동안, 글로벌 주요 반도체
    경쟁사들은 발 빠르게 움직이며 세계 시장에서 영향력을 확대하고 있다. 대만 TSMC는
    반도체 파운드리(위탁 생산) 분야 선두를 확고히 다졌고, 미국 인텔과 일본 라피더스 등
    후발 주자는 국가적 지원을 받아 삼성전자를 거세게 추격하고 있다. 2030년까지 메모리
    반도체와 파운드리를 포함한 시스템 반도체 모두에서 글로벌 1위를 목표로 하고 있는
    삼성전자가 직면한 경쟁이 한층 치열해졌다는 평가가 나온다.`
    }
  ]);
  const renderItem = (item) => {
    return (
      <li
        css={css`
          padding: 8px 0;
          border-bottom: 1px solid ${colors.grays.primary};
          p {
            font-size: 0.8rem;
            white-space: wrap;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            word-wrap: break-word;
            line-height: 22px;
            font-weight: 500;
          }
        `}
        onClick={() => navigate(`/stock/${item.ticker}`)}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 0;
            h3 {
              font-size: 16px;
              font-weight: 600;
              // width: 100%;
              margin-left: 0.5rem;
              margin-right: 0.5rem;
            }
            button {
              display: flex;
              align-items: center;
              margin: 0;
              margin-left: auto;
              font-size: 18px;
              font-weight: 600;
              color: ${item.ror < 0 ? colors.blue : colors.red};
              span {
                margin-right: 0.5rem;
              }
            }
          `}
        >
          {item.hold ? <Chip color='red' text='보유중' /> : <Chip text='관망중' />}
          <h3>{item.name}</h3>
          <button type='button'>
            <span className='percent-txt'>{item.ror < 0 ? item.ror : `+${item.ror}`}%</span>
            <Icon.ChevronRightSm />
          </button>
        </div>
        <p>{item.content}</p>
      </li>
    );
  };
  return (
    <Box element='section' px={2} py={3} gap={2}>
      <Box gap={1}>
        <h2>실시간 TOP 10 뉴스 종목 예측현황</h2>
        <p
          css={css`
            font-size: 0.8rem;
            font-weight: 500;
            color: ${colors.grays.dark};
          `}
        >
          현재 이슈 종목
        </p>
      </Box>

      <Box>
        <ul
          css={css`
            margin-bottom: 3rem;
            width: 100%;
          `}
        >
          {trendings.map((item) => renderItem(item))}
        </ul>
        <Button
          text='뉴스종목 더보기'
          onClick={() => navigate('/ai/forecast/trending')}
          color='secondary'
          outlined
        />
      </Box>
    </Box>
  );
}
