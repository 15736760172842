/** @jsxImportSource @emotion/react */

import { colors } from '@constants/color';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useMemo } from 'react';

const shouldForwardProp = (prop) => !['size', 'color', 'active', 'wFit', 'rounded'].includes(prop);
const ButtonWrapper = styled('button', { shouldForwardProp })((props) => {
  let textColor;
  let textColorPress = textColor;
  let bgColor = colors.bg.default.brand;
  let bgColorPress = colors.bg.interactive.primaryPress;
  let borderColor = 'transparent';
  let borderColorPress = 'transparent';
  switch (props.color) {
    // outline 된 버전
    case 'secondary':
      if (props.active) textColor = colors.text.interactive.secondaryActive;
      else textColor = 'inherit';
      textColorPress = colors.text.interactive.secondaryPress;
      if (props.active) borderColor = colors.border.interactive.secondaryActive;
      else borderColor = colors.border.default.primary;
      if (props.active) bgColor = colors.bg.interactive.secondaryActive;
      else bgColor = 'transparent';
      bgColorPress = colors.bg.interactive.secondaryPress;
      borderColorPress = colors.border.interactive.secondaryPress;
      break;
    // outline, background 없는 버전
    case 'text':
      if (props.active) textColor = colors.text.interactive.secondaryActive;
      else textColor = 'inherit';
      textColorPress = colors.text.interactive.secondaryPress;
      if (props.active) bgColor = colors.bg.interactive.secondaryActive;
      else bgColor = 'transparent';
      bgColorPress = colors.bg.interactive.secondaryPress;
      borderColorPress = 'transparent';
      break;
    case 'primary':
    default:
      if (props.active) bgColor = colors.bg.interactive.primaryActive;
      textColor = colors.text.default.inverse;
      break;
  }
  if (props.disabled) {
    switch (props.color) {
      case 'text':
        textColor = colors.text.default.disabled;
        bgColor = 'transparent';
        break;
      case 'primary':
      case 'secondary':
      default:
        textColor = colors.text.default.disabled;
        bgColor = colors.bg.default.disabled;
        break;
    }
  }
  let minHeight = '2.25rem';
  let height = '2.25rem';
  let maxHeight = '2.25rem';
  let fontSize = '1rem';
  switch (props.size) {
    case 'sm':
      minHeight = '1.75rem';
      height = '1.75rem';
      maxHeight = '1.75rem';
      fontSize = 'var(--font-size-sm)';
      break;
    case 'lg':
      minHeight = '3rem';
      height = '3rem';
      maxHeight = '3rem';
      fontSize = 'var(--font-size-lg)';
      break;
    case 'md':
    default:
      break;
  }
  return {
    width: props.wFit ? 'fit-content' : '100%',
    minHeight,
    height,
    maxHeight,
    margin: 0,
    padding: props.wFit ? '0 1rem' : 'initial',

    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',

    borderRadius: props.rounded ? '100px' : '6px',

    fontSize,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    userSelect: 'none',
    overflow: 'hidden',

    color: textColor,
    backgroundColor: bgColor,
    border: `1px solid ${borderColor}`,
    '&:active': {
      color: textColorPress,
      backgroundColor: bgColorPress,
      border: `1px solid ${borderColorPress}`
    },
    transition: '80ms ease-in-out',
    transitionProperty: 'color, background-color, border',
    '&:disabled': {
      cursor: 'default',
      pointerEvents: 'none'
    },
    '&[disabled]': {
      cursor: 'default',
      pointerEvents: 'none'
    },
    '&[data-loading="true"]': {
      backgroundColor: colors.blues.lighter
    }
  };
});
export default function Button({
  id,
  type = 'button',
  text = 'Button',
  size = 'md',
  onClick,
  color = 'primary',
  active,
  defaultChecked,
  checked,
  readOnly,
  wFit,
  rounded,
  disabled,
  loading,
  css: cssProp,
  inputType,
  name,
  ariaLabel,
  value
}) {
  if (inputType === 'radio') {
    const Element = useMemo(() => ButtonWrapper.withComponent('div'), []);
    return (
      <Element
        size={size}
        color={color}
        wFit={wFit}
        active={defaultChecked || checked}
        rounded={rounded}
        disabled={disabled}
        data-loading={loading}
        css={cssProp}
      >
        <input
          type={inputType}
          name={name}
          id={id || value}
          value={value}
          defaultChecked={defaultChecked}
          checked={checked}
          disabled={disabled}
          readOnly={readOnly}
          data-loading={loading}
          css={css`
            appearance: none;
            display: none;
          `}
        />
        <label
          htmlFor={id || value}
          css={css`
            cursor: ${disabled ? 'default' : 'pointer'};
            width: 100%;
            height: 100%;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: inherit;
          `}
        >
          {text}
        </label>
      </Element>
    );
  }
  return (
    <ButtonWrapper
      type={type}
      aria-label={ariaLabel}
      name={name}
      value={value}
      onClick={onClick}
      size={size}
      color={color}
      wFit={wFit}
      active={active}
      rounded={rounded}
      disabled={disabled}
      data-loading={loading}
      css={cssProp}
    >
      {text}
    </ButtonWrapper>
  );
}
