export const marketPreviewData = [
  {
    name: '코스피',
    dates: [
      {
        date: '2023-06-13',
        price: 2637.95,
        signal: 'ascend'
      },
      {
        date: '2023-06-14',
        price: 2619.08,
        signal: 'ascend'
      },
      {
        date: '2023-06-15',
        price: 2608.54,
        signal: 'ascend'
      },
      {
        date: '2023-06-16',
        price: 2625.79,
        signal: 'descend'
      },
      {
        date: '2023-06-19',
        price: 2609.5,
        signal: 'descend'
      },
      {
        date: '2023-06-20',
        price: 2604.91,
        signal: 'descend'
      },
      {
        date: '2023-06-21',
        price: 2582.63,
        signal: 'descend'
      },
      {
        date: '2023-06-22',
        price: 2593.7,
        signal: 'descend'
      },
      {
        date: '2023-06-23',
        price: 2570.1,
        signal: 'descend'
      },
      {
        date: '2023-06-26',
        price: 2582.2,
        signal: 'descend'
      },
      {
        date: '2023-06-27',
        price: 2581.39,
        signal: 'descend'
      },
      {
        date: '2023-06-28',
        price: 2564.19,
        signal: 'descend'
      },
      {
        date: '2023-06-29',
        price: 2550.02,
        signal: 'descend'
      },
      {
        date: '2023-06-30',
        price: 2564.28,
        signal: 'descend'
      },
      {
        date: '2023-07-03',
        price: 2602.47,
        signal: 'descend'
      },
      {
        date: '2023-07-04',
        price: 2593.31,
        signal: 'descend'
      },
      {
        date: '2023-07-05',
        price: 2579,
        signal: 'descend'
      },
      {
        date: '2023-07-06',
        price: 2556.29,
        signal: 'descend'
      },
      {
        date: '2023-07-07',
        price: 2526.71,
        signal: 'descend'
      },
      {
        date: '2023-07-10',
        price: 2520.7,
        signal: 'descend'
      },
      {
        date: '2023-07-11',
        price: 2562.49,
        signal: 'descend'
      },
      {
        date: '2023-07-12',
        price: 2574.72,
        signal: 'descend'
      },
      {
        date: '2023-07-13',
        price: 2591.23,
        signal: 'descend'
      },
      {
        date: '2023-07-14',
        price: 2628.3,
        signal: 'descend'
      },
      {
        date: '2023-07-17',
        price: 2619,
        signal: 'descend'
      },
      {
        date: '2023-07-18',
        price: 2607.62,
        signal: 'descend'
      },
      {
        date: '2023-07-19',
        price: 2608.24,
        signal: 'descend'
      },
      {
        date: '2023-07-20',
        price: 2600.23,
        signal: 'descend'
      },
      {
        date: '2023-07-21',
        price: 2609.76,
        signal: 'descend'
      },
      {
        date: '2023-07-24',
        price: 2628.53,
        signal: 'descend'
      },
      {
        date: '2023-07-25',
        price: 2636.46,
        signal: 'descend'
      },
      {
        date: '2023-07-26',
        price: 2592.36,
        signal: 'descend'
      },
      {
        date: '2023-07-27',
        price: 2603.81,
        signal: 'descend'
      },
      {
        date: '2023-07-28',
        price: 2608.32,
        signal: 'descend'
      },
      {
        date: '2023-07-31',
        price: 2632.58,
        signal: 'descend'
      },
      {
        date: '2023-08-01',
        price: 2667.07,
        signal: 'descend'
      },
      {
        date: '2023-08-02',
        price: 2616.47,
        signal: 'descend'
      },
      {
        date: '2023-08-03',
        price: 2605.39,
        signal: 'descend'
      },
      {
        date: '2023-08-04',
        price: 2602.8,
        signal: 'descend'
      },
      {
        date: '2023-08-07',
        price: 2580.71,
        signal: 'descend'
      },
      {
        date: '2023-08-08',
        price: 2573.98,
        signal: 'descend'
      },
      {
        date: '2023-08-09',
        price: 2605.12,
        signal: 'descend'
      },
      {
        date: '2023-08-10',
        price: 2601.56,
        signal: 'descend'
      },
      {
        date: '2023-08-11',
        price: 2591.26,
        signal: 'descend'
      },
      {
        date: '2023-08-14',
        price: 2570.87,
        signal: 'descend'
      },
      {
        date: '2023-08-16',
        price: 2525.64,
        signal: 'descend'
      },
      {
        date: '2023-08-17',
        price: 2519.85,
        signal: 'descend'
      },
      {
        date: '2023-08-18',
        price: 2504.5,
        signal: 'descend'
      },
      {
        date: '2023-08-21',
        price: 2508.8,
        signal: 'descend'
      },
      {
        date: '2023-08-22',
        price: 2515.74,
        signal: 'descend'
      },
      {
        date: '2023-08-23',
        price: 2505.5,
        signal: 'descend'
      },
      {
        date: '2023-08-24',
        price: 2537.68,
        signal: 'descend'
      },
      {
        date: '2023-08-25',
        price: 2519.14,
        signal: 'descend'
      },
      {
        date: '2023-08-28',
        price: 2543.41,
        signal: 'descend'
      },
      {
        date: '2023-08-29',
        price: 2552.16,
        signal: 'descend'
      },
      {
        date: '2023-08-30',
        price: 2561.22,
        signal: 'descend'
      },
      {
        date: '2023-08-31',
        price: 2556.27,
        signal: 'descend'
      },
      {
        date: '2023-09-01',
        price: 2563.71,
        signal: 'descend'
      },
      {
        date: '2023-09-04',
        price: 2584.55,
        signal: 'descend'
      },
      {
        date: '2023-09-05',
        price: 2582.18,
        signal: 'descend'
      },
      {
        date: '2023-09-06',
        price: 2563.34,
        signal: 'descend'
      },
      {
        date: '2023-09-07',
        price: 2548.26,
        signal: 'descend'
      },
      {
        date: '2023-09-08',
        price: 2547.68,
        signal: 'descend'
      },
      {
        date: '2023-09-11',
        price: 2556.88,
        signal: 'descend'
      },
      {
        date: '2023-09-12',
        price: 2536.58,
        signal: 'descend'
      },
      {
        date: '2023-09-13',
        price: 2534.7,
        signal: 'descend'
      },
      {
        date: '2023-09-14',
        price: 2572.89,
        signal: 'descend'
      },
      {
        date: '2023-09-15',
        price: 2601.28,
        signal: 'descend'
      },
      {
        date: '2023-09-18',
        price: 2574.72,
        signal: 'descend'
      },
      {
        date: '2023-09-19',
        price: 2559.21,
        signal: 'descend'
      },
      {
        date: '2023-09-20',
        price: 2559.74,
        signal: 'descend'
      },
      {
        date: '2023-09-21',
        price: 2514.97,
        signal: 'descend'
      },
      {
        date: '2023-09-22',
        price: 2508.13,
        signal: 'descend'
      },
      {
        date: '2023-09-25',
        price: 2495.76,
        signal: 'descend'
      },
      {
        date: '2023-09-26',
        price: 2462.97,
        signal: 'descend'
      },
      {
        date: '2023-09-27',
        price: 2465.07,
        signal: 'descend'
      },
      {
        date: '2023-10-04',
        price: 2405.69,
        signal: 'descend'
      },
      {
        date: '2023-10-05',
        price: 2403.6,
        signal: 'descend'
      },
      {
        date: '2023-10-06',
        price: 2408.73,
        signal: 'descend'
      },
      {
        date: '2023-10-10',
        price: 2402.58,
        signal: 'descend'
      },
      {
        date: '2023-10-11',
        price: 2450.08,
        signal: 'descend'
      },
      {
        date: '2023-10-12',
        price: 2479.82,
        signal: 'descend'
      },
      {
        date: '2023-10-13',
        price: 2456.15,
        signal: 'descend'
      },
      {
        date: '2023-10-16',
        price: 2436.24,
        signal: 'descend'
      },
      {
        date: '2023-10-17',
        price: 2460.17,
        signal: 'descend'
      },
      {
        date: '2023-10-18',
        price: 2462.6,
        signal: 'descend'
      },
      {
        date: '2023-10-19',
        price: 2415.8,
        signal: 'descend'
      },
      {
        date: '2023-10-20',
        price: 2375,
        signal: 'descend'
      },
      {
        date: '2023-10-23',
        price: 2357.02,
        signal: 'descend'
      },
      {
        date: '2023-10-24',
        price: 2383.51,
        signal: 'descend'
      },
      {
        date: '2023-10-25',
        price: 2363.17,
        signal: 'descend'
      },
      {
        date: '2023-10-26',
        price: 2299.08,
        signal: 'descend'
      },
      {
        date: '2023-10-27',
        price: 2302.81,
        signal: 'descend'
      },
      {
        date: '2023-10-30',
        price: 2310.55,
        signal: 'descend'
      },
      {
        date: '2023-10-31',
        price: 2277.99,
        signal: 'descend'
      },
      {
        date: '2023-11-01',
        price: 2301.56,
        signal: 'descend'
      },
      {
        date: '2023-11-02',
        price: 2343.12,
        signal: 'ascend'
      },
      {
        date: '2023-11-03',
        price: 2368.34,
        signal: 'ascend'
      },
      {
        date: '2023-11-06',
        price: 2502.37,
        signal: 'ascend'
      },
      {
        date: '2023-11-07',
        price: 2443.96,
        signal: 'ascend'
      },
      {
        date: '2023-11-08',
        price: 2421.62,
        signal: 'ascend'
      },
      {
        date: '2023-11-09',
        price: 2427.08,
        signal: 'ascend'
      },
      {
        date: '2023-11-10',
        price: 2409.66,
        signal: 'ascend'
      },
      {
        date: '2023-11-13',
        price: 2403.76,
        signal: 'ascend'
      },
      {
        date: '2023-11-14',
        price: 2433.25,
        signal: 'ascend'
      },
      {
        date: '2023-11-15',
        price: 2486.67,
        signal: 'ascend'
      },
      {
        date: '2023-11-16',
        price: 2488.18,
        signal: 'ascend'
      },
      {
        date: '2023-11-17',
        price: 2469.85,
        signal: 'ascend'
      },
      {
        date: '2023-11-20',
        price: 2491.2,
        signal: 'ascend'
      },
      {
        date: '2023-11-21',
        price: 2510.42,
        signal: 'ascend'
      },
      {
        date: '2023-11-22',
        price: 2511.7,
        signal: 'ascend'
      },
      {
        date: '2023-11-23',
        price: 2514.96,
        signal: 'ascend'
      },
      {
        date: '2023-11-24',
        price: 2496.63,
        signal: 'ascend'
      },
      {
        date: '2023-11-27',
        price: 2495.66,
        signal: 'ascend'
      },
      {
        date: '2023-11-28',
        price: 2521.76,
        signal: 'ascend'
      },
      {
        date: '2023-11-29',
        price: 2519.81,
        signal: 'ascend'
      },
      {
        date: '2023-11-30',
        price: 2535.29,
        signal: 'ascend'
      },
      {
        date: '2023-12-01',
        price: 2505.01,
        signal: 'ascend'
      },
      {
        date: '2023-12-04',
        price: 2514.95,
        signal: 'ascend'
      },
      {
        date: '2023-12-05',
        price: 2494.28,
        signal: 'ascend'
      },
      {
        date: '2023-12-06',
        price: 2495.38,
        signal: 'ascend'
      },
      {
        date: '2023-12-07',
        price: 2492.07,
        signal: 'ascend'
      },
      {
        date: '2023-12-08',
        price: 2517.85,
        signal: 'ascend'
      },
      {
        date: '2023-12-11',
        price: 2525.36,
        signal: 'ascend'
      },
      {
        date: '2023-12-12',
        price: 2535.27,
        signal: 'ascend'
      },
      {
        date: '2023-12-13',
        price: 2510.66,
        signal: 'ascend'
      },
      {
        date: '2023-12-14',
        price: 2544.18,
        signal: 'ascend'
      },
      {
        date: '2023-12-15',
        price: 2563.56,
        signal: 'ascend'
      },
      {
        date: '2023-12-18',
        price: 2566.86,
        signal: 'ascend'
      },
      {
        date: '2023-12-19',
        price: 2568.55,
        signal: 'ascend'
      },
      {
        date: '2023-12-20',
        price: 2614.3,
        signal: 'ascend'
      },
      {
        date: '2023-12-21',
        price: 2600.02,
        signal: 'ascend'
      },
      {
        date: '2023-12-22',
        price: 2599.51,
        signal: 'ascend'
      },
      {
        date: '2023-12-26',
        price: 2602.59,
        signal: 'descend'
      },
      {
        date: '2023-12-27',
        price: 2613.5,
        signal: 'descend'
      },
      {
        date: '2023-12-28',
        price: 2655.28,
        signal: 'descend'
      },
      {
        date: '2024-01-02',
        price: 2669.81,
        signal: 'descend'
      },
      {
        date: '2024-01-03',
        price: 2607.31,
        signal: 'descend'
      },
      {
        date: '2024-01-04',
        price: 2587.02,
        signal: 'descend'
      },
      {
        date: '2024-01-05',
        price: 2578.08,
        signal: 'descend'
      },
      {
        date: '2024-01-08',
        price: 2567.82,
        signal: 'descend'
      },
      {
        date: '2024-01-09',
        price: 2561.24,
        signal: 'descend'
      },
      {
        date: '2024-01-10',
        price: 2541.98,
        signal: 'descend'
      },
      {
        date: '2024-01-11',
        price: 2540.27,
        signal: 'descend'
      },
      {
        date: '2024-01-12',
        price: 2525.05,
        signal: 'descend'
      },
      {
        date: '2024-01-15',
        price: 2525.99,
        signal: 'descend'
      },
      {
        date: '2024-01-16',
        price: 2497.59,
        signal: 'descend'
      },
      {
        date: '2024-01-17',
        price: 2435.9,
        signal: 'descend'
      },
      {
        date: '2024-01-18',
        price: 2440.04,
        signal: 'descend'
      },
      {
        date: '2024-01-19',
        price: 2472.74,
        signal: 'descend'
      },
      {
        date: '2024-01-22',
        price: 2464.35,
        signal: 'descend'
      },
      {
        date: '2024-01-23',
        price: 2478.61,
        signal: 'descend'
      },
      {
        date: '2024-01-24',
        price: 2469.69,
        signal: 'descend'
      },
      {
        date: '2024-01-25',
        price: 2470.34,
        signal: 'descend'
      },
      {
        date: '2024-01-26',
        price: 2478.56,
        signal: 'descend'
      },
      {
        date: '2024-01-29',
        price: 2500.65,
        signal: 'ascend'
      },
      {
        date: '2024-01-30',
        price: 2498.81,
        signal: 'ascend'
      },
      {
        date: '2024-01-31',
        price: 2497.09,
        signal: 'ascend'
      },
      {
        date: '2024-02-01',
        price: 2542.46,
        signal: 'ascend'
      },
      {
        date: '2024-02-02',
        price: 2615.31,
        signal: 'ascend'
      },
      {
        date: '2024-02-05',
        price: 2591.31,
        signal: 'ascend'
      },
      {
        date: '2024-02-06',
        price: 2576.2,
        signal: 'ascend'
      },
      {
        date: '2024-02-07',
        price: 2609.58,
        signal: 'ascend'
      },
      {
        date: '2024-02-08',
        price: 2620.32,
        signal: 'ascend'
      },
      {
        date: '2024-02-13',
        price: 2649.64,
        signal: 'ascend'
      },
      {
        date: '2024-02-14',
        price: 2620.42,
        signal: 'ascend'
      },
      {
        date: '2024-02-15',
        price: 2613.8,
        signal: 'ascend'
      },
      {
        date: '2024-02-16',
        price: 2648.76,
        signal: 'ascend'
      },
      {
        date: '2024-02-19',
        price: 2680.26,
        signal: 'ascend'
      },
      {
        date: '2024-02-20',
        price: 2657.79,
        signal: 'ascend'
      },
      {
        date: '2024-02-21',
        price: 2653.31,
        signal: 'ascend'
      },
      {
        date: '2024-02-22',
        price: 2664.27,
        signal: 'ascend'
      },
      {
        date: '2024-02-23',
        price: 2667.7,
        signal: 'ascend'
      },
      {
        date: '2024-02-26',
        price: 2647.08,
        signal: 'ascend'
      },
      {
        date: '2024-02-27',
        price: 2625.05,
        signal: 'ascend'
      },
      {
        date: '2024-02-28',
        price: 2652.29,
        signal: 'ascend'
      },
      {
        date: '2024-02-29',
        price: 2642.36,
        signal: 'ascend'
      },
      {
        date: '2024-03-04',
        price: 2674.27,
        signal: 'ascend'
      },
      {
        date: '2024-03-05',
        price: 2649.4,
        signal: 'ascend'
      },
      {
        date: '2024-03-06',
        price: 2641.49,
        signal: 'ascend'
      },
      {
        date: '2024-03-07',
        price: 2647.62,
        signal: 'ascend'
      },
      {
        date: '2024-03-08',
        price: 2680.35,
        signal: 'ascend'
      },
      {
        date: '2024-03-11',
        price: 2659.84,
        signal: 'ascend'
      },
      {
        date: '2024-03-12',
        price: 2681.81,
        signal: 'ascend'
      },
      {
        date: '2024-03-13',
        price: 2693.57,
        signal: 'ascend'
      },
      {
        date: '2024-03-14',
        price: 2718.76,
        signal: 'ascend'
      },
      {
        date: '2024-03-15',
        price: 2666.84,
        signal: 'ascend'
      },
      {
        date: '2024-03-18',
        price: 2685.84,
        signal: 'ascend'
      },
      {
        date: '2024-03-19',
        price: 2656.17,
        signal: 'ascend'
      },
      {
        date: '2024-03-20',
        price: 2690.14,
        signal: 'ascend'
      },
      {
        date: '2024-03-21',
        price: 2754.86,
        signal: 'ascend'
      },
      {
        date: '2024-03-22',
        price: 2748.56,
        signal: 'ascend'
      },
      {
        date: '2024-03-25',
        price: 2737.57,
        signal: 'ascend'
      },
      {
        date: '2024-03-26',
        price: 2757.09,
        signal: 'ascend'
      },
      {
        date: '2024-03-27',
        price: 2755.11,
        signal: 'ascend'
      },
      {
        date: '2024-03-28',
        price: 2745.82,
        signal: 'ascend'
      },
      {
        date: '2024-03-29',
        price: 2746.63,
        signal: 'ascend'
      },
      {
        date: '2024-04-01',
        price: 2747.86,
        signal: 'ascend'
      },
      {
        date: '2024-04-02',
        price: 2753.16,
        signal: 'ascend'
      },
      {
        date: '2024-04-03',
        price: 2706.97,
        signal: 'ascend'
      },
      {
        date: '2024-04-04',
        price: 2742,
        signal: 'ascend'
      },
      {
        date: '2024-04-05',
        price: 2714.21,
        signal: 'descend'
      },
      {
        date: '2024-04-08',
        price: 2717.65,
        signal: 'descend'
      },
      {
        date: '2024-04-09',
        price: 2705.16,
        signal: 'descend'
      },
      {
        date: '2024-04-11',
        price: 2706.96,
        signal: 'descend'
      },
      {
        date: '2024-04-12',
        price: 2681.82,
        signal: 'descend'
      },
      {
        date: '2024-04-15',
        price: 2670.43,
        signal: 'descend'
      },
      {
        date: '2024-04-16',
        price: 2609.63,
        signal: 'descend'
      },
      {
        date: '2024-04-17',
        price: 2584.18,
        signal: 'descend'
      },
      {
        date: '2024-04-18',
        price: 2634.7,
        signal: 'descend'
      },
      {
        date: '2024-04-19',
        price: 2591.86,
        signal: 'ascend'
      },
      {
        date: '2024-04-22',
        price: 2629.44,
        signal: 'ascend'
      },
      {
        date: '2024-04-23',
        price: 2623.02,
        signal: 'ascend'
      },
      {
        date: '2024-04-24',
        price: 2675.75,
        signal: 'ascend'
      },
      {
        date: '2024-04-25',
        price: 2628.62,
        signal: 'ascend'
      },
      {
        date: '2024-04-26',
        price: 2656.33,
        signal: 'ascend'
      },
      {
        date: '2024-04-29',
        price: 2687.44,
        signal: 'ascend'
      },
      {
        date: '2024-04-30',
        price: 2692.06,
        signal: 'ascend'
      },
      {
        date: '2024-05-02',
        price: 2683.65,
        signal: 'ascend'
      },
      {
        date: '2024-05-03',
        price: 2676.63,
        signal: 'ascend'
      },
      {
        date: '2024-05-07',
        price: 2734.36,
        signal: 'ascend'
      },
      {
        date: '2024-05-08',
        price: 2745.05,
        signal: 'ascend'
      },
      {
        date: '2024-05-09',
        price: 2712.14,
        signal: 'ascend'
      },
      {
        date: '2024-05-10',
        price: 2727.63,
        signal: 'ascend'
      },
      {
        date: '2024-05-13',
        price: 2727.21,
        signal: 'ascend'
      },
      {
        date: '2024-05-14',
        price: 2730.34,
        signal: 'ascend'
      },
      {
        date: '2024-05-16',
        price: 2753,
        signal: 'ascend'
      },
      {
        date: '2024-05-17',
        price: 2724.62,
        signal: 'descend'
      },
      {
        date: '2024-05-20',
        price: 2742.14,
        signal: 'descend'
      },
      {
        date: '2024-05-21',
        price: 2724.18,
        signal: 'descend'
      },
      {
        date: '2024-05-22',
        price: 2723.46,
        signal: 'descend'
      },
      {
        date: '2024-05-23',
        price: 2721.81,
        signal: 'descend'
      },
      {
        date: '2024-05-24',
        price: 2687.6,
        signal: 'descend'
      },
      {
        date: '2024-05-28',
        price: 2722.85,
        signal: 'descend'
      },
      {
        date: '2024-05-29',
        price: 2722.85,
        signal: 'descend'
      },
      {
        date: '2024-05-30',
        price: 2658.68,
        signal: 'descend'
      },
      {
        date: '2024-05-31',
        price: 2650.27,
        signal: 'descend'
      },
      {
        date: '2024-06-03',
        price: 2682.52,
        signal: 'descend'
      },
      {
        date: '2024-06-04',
        price: 2662.1,
        signal: 'descend'
      },
      {
        date: '2024-06-05',
        price: 2689.5,
        signal: 'descend'
      },
      {
        date: '2024-06-07',
        price: 2722.67,
        signal: 'descend'
      }
    ],
    signals: [
      {
        kind: 'ascend',
        date: '1996-05-02'
      },
      {
        kind: 'descend',
        date: '1996-05-04'
      },
      {
        kind: 'ascend',
        date: '1996-05-06'
      },
      {
        kind: 'ascend',
        date: '2023-03-03'
      },
      {
        kind: 'descend',
        date: '2023-06-16'
      },
      {
        kind: 'ascend',
        date: '2023-11-02'
      },
      {
        kind: 'descend',
        date: '2023-12-26'
      },
      {
        kind: 'ascend',
        date: '2024-01-29'
      },
      {
        kind: 'descend',
        date: '2024-04-05'
      },
      {
        kind: 'ascend',
        date: '2024-04-19'
      },
      {
        kind: 'descend',
        date: '2024-05-17'
      }
    ]
  },
  {
    name: '코스닥',
    dates: [
      {
        date: '2023-06-13',
        price: 896.81,
        signal: 'ascend'
      },
      {
        date: '2023-06-14',
        price: 871.83,
        signal: 'ascend'
      },
      {
        date: '2023-06-15',
        price: 878.04,
        signal: 'ascend'
      },
      {
        date: '2023-06-16',
        price: 887.95,
        signal: 'ascend'
      },
      {
        date: '2023-06-19',
        price: 888.61,
        signal: 'ascend'
      },
      {
        date: '2023-06-20',
        price: 886.41,
        signal: 'ascend'
      },
      {
        date: '2023-06-21',
        price: 875.7,
        signal: 'ascend'
      },
      {
        date: '2023-06-22',
        price: 876.38,
        signal: 'ascend'
      },
      {
        date: '2023-06-23',
        price: 874.84,
        signal: 'ascend'
      },
      {
        date: '2023-06-26',
        price: 879.5,
        signal: 'ascend'
      },
      {
        date: '2023-06-27',
        price: 874.14,
        signal: 'ascend'
      },
      {
        date: '2023-06-28',
        price: 866.97,
        signal: 'ascend'
      },
      {
        date: '2023-06-29',
        price: 861.79,
        signal: 'ascend'
      },
      {
        date: '2023-06-30',
        price: 868.24,
        signal: 'ascend'
      },
      {
        date: '2023-07-03',
        price: 889.29,
        signal: 'ascend'
      },
      {
        date: '2023-07-04',
        price: 890,
        signal: 'ascend'
      },
      {
        date: '2023-07-05',
        price: 891.18,
        signal: 'ascend'
      },
      {
        date: '2023-07-06',
        price: 870.53,
        signal: 'ascend'
      },
      {
        date: '2023-07-07',
        price: 867.27,
        signal: 'ascend'
      },
      {
        date: '2023-07-10',
        price: 860.35,
        signal: 'ascend'
      },
      {
        date: '2023-07-11',
        price: 878.73,
        signal: 'ascend'
      },
      {
        date: '2023-07-12',
        price: 879.88,
        signal: 'ascend'
      },
      {
        date: '2023-07-13',
        price: 893.07,
        signal: 'ascend'
      },
      {
        date: '2023-07-14',
        price: 896.28,
        signal: 'ascend'
      },
      {
        date: '2023-07-17',
        price: 898.29,
        signal: 'ascend'
      },
      {
        date: '2023-07-18',
        price: 914.14,
        signal: 'ascend'
      },
      {
        date: '2023-07-19',
        price: 923.72,
        signal: 'ascend'
      },
      {
        date: '2023-07-20',
        price: 931.6,
        signal: 'ascend'
      },
      {
        date: '2023-07-21',
        price: 934.58,
        signal: 'ascend'
      },
      {
        date: '2023-07-24',
        price: 929.9,
        signal: 'ascend'
      },
      {
        date: '2023-07-25',
        price: 939.96,
        signal: 'ascend'
      },
      {
        date: '2023-07-26',
        price: 900.63,
        signal: 'ascend'
      },
      {
        date: '2023-07-27',
        price: 883.79,
        signal: 'ascend'
      },
      {
        date: '2023-07-28',
        price: 913.74,
        signal: 'ascend'
      },
      {
        date: '2023-07-31',
        price: 935.97,
        signal: 'ascend'
      },
      {
        date: '2023-08-01',
        price: 939.67,
        signal: 'ascend'
      },
      {
        date: '2023-08-02',
        price: 909.76,
        signal: 'descend'
      },
      {
        date: '2023-08-03',
        price: 920.32,
        signal: 'descend'
      },
      {
        date: '2023-08-04',
        price: 918.43,
        signal: 'descend'
      },
      {
        date: '2023-08-07',
        price: 898.22,
        signal: 'descend'
      },
      {
        date: '2023-08-08',
        price: 892.34,
        signal: 'descend'
      },
      {
        date: '2023-08-09',
        price: 908.98,
        signal: 'descend'
      },
      {
        date: '2023-08-10',
        price: 911.29,
        signal: 'descend'
      },
      {
        date: '2023-08-11',
        price: 912.2,
        signal: 'descend'
      },
      {
        date: '2023-08-14',
        price: 901.68,
        signal: 'descend'
      },
      {
        date: '2023-08-16',
        price: 878.29,
        signal: 'descend'
      },
      {
        date: '2023-08-17',
        price: 886.04,
        signal: 'descend'
      },
      {
        date: '2023-08-18',
        price: 877.32,
        signal: 'descend'
      },
      {
        date: '2023-08-21',
        price: 888.71,
        signal: 'descend'
      },
      {
        date: '2023-08-22',
        price: 893.33,
        signal: 'descend'
      },
      {
        date: '2023-08-23',
        price: 882.87,
        signal: 'descend'
      },
      {
        date: '2023-08-24',
        price: 901.74,
        signal: 'descend'
      },
      {
        date: '2023-08-25',
        price: 899.38,
        signal: 'descend'
      },
      {
        date: '2023-08-28',
        price: 909.38,
        signal: 'descend'
      },
      {
        date: '2023-08-29',
        price: 916.24,
        signal: 'descend'
      },
      {
        date: '2023-08-30',
        price: 923.81,
        signal: 'descend'
      },
      {
        date: '2023-08-31',
        price: 928.4,
        signal: 'descend'
      },
      {
        date: '2023-09-01',
        price: 919.74,
        signal: 'descend'
      },
      {
        date: '2023-09-04',
        price: 919.16,
        signal: 'descend'
      },
      {
        date: '2023-09-05',
        price: 921.48,
        signal: 'descend'
      },
      {
        date: '2023-09-06',
        price: 917.95,
        signal: 'descend'
      },
      {
        date: '2023-09-07',
        price: 906.36,
        signal: 'descend'
      },
      {
        date: '2023-09-08',
        price: 914.18,
        signal: 'descend'
      },
      {
        date: '2023-09-11',
        price: 912.55,
        signal: 'descend'
      },
      {
        date: '2023-09-12',
        price: 898.04,
        signal: 'descend'
      },
      {
        date: '2023-09-13',
        price: 882.72,
        signal: 'descend'
      },
      {
        date: '2023-09-14',
        price: 899.47,
        signal: 'descend'
      },
      {
        date: '2023-09-15',
        price: 899.03,
        signal: 'descend'
      },
      {
        date: '2023-09-18',
        price: 891.29,
        signal: 'descend'
      },
      {
        date: '2023-09-19',
        price: 883.89,
        signal: 'descend'
      },
      {
        date: '2023-09-20',
        price: 882.72,
        signal: 'descend'
      },
      {
        date: '2023-09-21',
        price: 860.68,
        signal: 'descend'
      },
      {
        date: '2023-09-22',
        price: 857.35,
        signal: 'descend'
      },
      {
        date: '2023-09-25',
        price: 839.17,
        signal: 'descend'
      },
      {
        date: '2023-09-26',
        price: 827.82,
        signal: 'descend'
      },
      {
        date: '2023-09-27',
        price: 841.02,
        signal: 'descend'
      },
      {
        date: '2023-10-04',
        price: 807.4,
        signal: 'descend'
      },
      {
        date: '2023-10-05',
        price: 801.02,
        signal: 'descend'
      },
      {
        date: '2023-10-06',
        price: 816.39,
        signal: 'descend'
      },
      {
        date: '2023-10-10',
        price: 795,
        signal: 'descend'
      },
      {
        date: '2023-10-11',
        price: 817.12,
        signal: 'descend'
      },
      {
        date: '2023-10-12',
        price: 835.49,
        signal: 'descend'
      },
      {
        date: '2023-10-13',
        price: 822.78,
        signal: 'descend'
      },
      {
        date: '2023-10-16',
        price: 810.54,
        signal: 'descend'
      },
      {
        date: '2023-10-17',
        price: 820.38,
        signal: 'descend'
      },
      {
        date: '2023-10-18',
        price: 808.89,
        signal: 'descend'
      },
      {
        date: '2023-10-19',
        price: 784.04,
        signal: 'descend'
      },
      {
        date: '2023-10-20',
        price: 769.25,
        signal: 'descend'
      },
      {
        date: '2023-10-23',
        price: 763.69,
        signal: 'descend'
      },
      {
        date: '2023-10-24',
        price: 784.86,
        signal: 'descend'
      },
      {
        date: '2023-10-25',
        price: 770.84,
        signal: 'descend'
      },
      {
        date: '2023-10-26',
        price: 743.85,
        signal: 'descend'
      },
      {
        date: '2023-10-27',
        price: 748.49,
        signal: 'descend'
      },
      {
        date: '2023-10-30',
        price: 757.12,
        signal: 'descend'
      },
      {
        date: '2023-10-31',
        price: 736.1,
        signal: 'descend'
      },
      {
        date: '2023-11-01',
        price: 739.23,
        signal: 'descend'
      },
      {
        date: '2023-11-02',
        price: 772.84,
        signal: 'descend'
      },
      {
        date: '2023-11-03',
        price: 782.05,
        signal: 'ascend'
      },
      {
        date: '2023-11-06',
        price: 839.45,
        signal: 'ascend'
      },
      {
        date: '2023-11-07',
        price: 824.37,
        signal: 'ascend'
      },
      {
        date: '2023-11-08',
        price: 811.02,
        signal: 'ascend'
      },
      {
        date: '2023-11-09',
        price: 802.87,
        signal: 'ascend'
      },
      {
        date: '2023-11-10',
        price: 789.31,
        signal: 'ascend'
      },
      {
        date: '2023-11-13',
        price: 774.42,
        signal: 'ascend'
      },
      {
        date: '2023-11-14',
        price: 794.19,
        signal: 'ascend'
      },
      {
        date: '2023-11-15',
        price: 809.36,
        signal: 'ascend'
      },
      {
        date: '2023-11-16',
        price: 811.11,
        signal: 'ascend'
      },
      {
        date: '2023-11-17',
        price: 799.06,
        signal: 'ascend'
      },
      {
        date: '2023-11-20',
        price: 813.08,
        signal: 'ascend'
      },
      {
        date: '2023-11-21',
        price: 817.01,
        signal: 'ascend'
      },
      {
        date: '2023-11-22',
        price: 814.61,
        signal: 'ascend'
      },
      {
        date: '2023-11-23',
        price: 815.98,
        signal: 'ascend'
      },
      {
        date: '2023-11-24',
        price: 815,
        signal: 'ascend'
      },
      {
        date: '2023-11-27',
        price: 810.25,
        signal: 'ascend'
      },
      {
        date: '2023-11-28',
        price: 816.44,
        signal: 'ascend'
      },
      {
        date: '2023-11-29',
        price: 822.44,
        signal: 'ascend'
      },
      {
        date: '2023-11-30',
        price: 831.68,
        signal: 'ascend'
      },
      {
        date: '2023-12-01',
        price: 827.24,
        signal: 'ascend'
      },
      {
        date: '2023-12-04',
        price: 828.52,
        signal: 'ascend'
      },
      {
        date: '2023-12-05',
        price: 813.38,
        signal: 'ascend'
      },
      {
        date: '2023-12-06',
        price: 819.54,
        signal: 'ascend'
      },
      {
        date: '2023-12-07',
        price: 813.2,
        signal: 'ascend'
      },
      {
        date: '2023-12-08',
        price: 830.37,
        signal: 'ascend'
      },
      {
        date: '2023-12-11',
        price: 835.25,
        signal: 'ascend'
      },
      {
        date: '2023-12-12',
        price: 839.53,
        signal: 'ascend'
      },
      {
        date: '2023-12-13',
        price: 829.31,
        signal: 'ascend'
      },
      {
        date: '2023-12-14',
        price: 840.59,
        signal: 'ascend'
      },
      {
        date: '2023-12-15',
        price: 838.31,
        signal: 'ascend'
      },
      {
        date: '2023-12-18',
        price: 850.96,
        signal: 'ascend'
      },
      {
        date: '2023-12-19',
        price: 858.3,
        signal: 'ascend'
      },
      {
        date: '2023-12-20',
        price: 862.98,
        signal: 'ascend'
      },
      {
        date: '2023-12-21',
        price: 859.44,
        signal: 'ascend'
      },
      {
        date: '2023-12-22',
        price: 854.62,
        signal: 'ascend'
      },
      {
        date: '2023-12-26',
        price: 848.34,
        signal: 'ascend'
      },
      {
        date: '2023-12-27',
        price: 859.79,
        signal: 'ascend'
      },
      {
        date: '2023-12-28',
        price: 866.57,
        signal: 'ascend'
      },
      {
        date: '2024-01-02',
        price: 878.93,
        signal: 'ascend'
      },
      {
        date: '2024-01-03',
        price: 871.57,
        signal: 'ascend'
      },
      {
        date: '2024-01-04',
        price: 866.25,
        signal: 'ascend'
      },
      {
        date: '2024-01-05',
        price: 878.33,
        signal: 'ascend'
      },
      {
        date: '2024-01-08',
        price: 879.34,
        signal: 'ascend'
      },
      {
        date: '2024-01-09',
        price: 884.64,
        signal: 'ascend'
      },
      {
        date: '2024-01-10',
        price: 875.46,
        signal: 'ascend'
      },
      {
        date: '2024-01-11',
        price: 882.53,
        signal: 'ascend'
      },
      {
        date: '2024-01-12',
        price: 868.08,
        signal: 'ascend'
      },
      {
        date: '2024-01-15',
        price: 859.71,
        signal: 'descend'
      },
      {
        date: '2024-01-16',
        price: 854.83,
        signal: 'descend'
      },
      {
        date: '2024-01-17',
        price: 833.05,
        signal: 'descend'
      },
      {
        date: '2024-01-18',
        price: 840.33,
        signal: 'descend'
      },
      {
        date: '2024-01-19',
        price: 842.67,
        signal: 'descend'
      },
      {
        date: '2024-01-22',
        price: 839.69,
        signal: 'descend'
      },
      {
        date: '2024-01-23',
        price: 840.11,
        signal: 'descend'
      },
      {
        date: '2024-01-24',
        price: 836.21,
        signal: 'descend'
      },
      {
        date: '2024-01-25',
        price: 823.74,
        signal: 'descend'
      },
      {
        date: '2024-01-26',
        price: 837.24,
        signal: 'descend'
      },
      {
        date: '2024-01-29',
        price: 819.14,
        signal: 'descend'
      },
      {
        date: '2024-01-30',
        price: 818.86,
        signal: 'descend'
      },
      {
        date: '2024-01-31',
        price: 799.24,
        signal: 'descend'
      },
      {
        date: '2024-02-01',
        price: 798.73,
        signal: 'descend'
      },
      {
        date: '2024-02-02',
        price: 814.77,
        signal: 'descend'
      },
      {
        date: '2024-02-05',
        price: 807.99,
        signal: 'descend'
      },
      {
        date: '2024-02-06',
        price: 807.03,
        signal: 'descend'
      },
      {
        date: '2024-02-07',
        price: 811.92,
        signal: 'descend'
      },
      {
        date: '2024-02-08',
        price: 826.58,
        signal: 'ascend'
      },
      {
        date: '2024-02-13',
        price: 845.15,
        signal: 'ascend'
      },
      {
        date: '2024-02-14',
        price: 853.3,
        signal: 'ascend'
      },
      {
        date: '2024-02-15',
        price: 859.21,
        signal: 'ascend'
      },
      {
        date: '2024-02-16',
        price: 857.6,
        signal: 'ascend'
      },
      {
        date: '2024-02-19',
        price: 858.47,
        signal: 'ascend'
      },
      {
        date: '2024-02-20',
        price: 866.17,
        signal: 'ascend'
      },
      {
        date: '2024-02-21',
        price: 864.07,
        signal: 'ascend'
      },
      {
        date: '2024-02-22',
        price: 870.11,
        signal: 'ascend'
      },
      {
        date: '2024-02-23',
        price: 868.57,
        signal: 'ascend'
      },
      {
        date: '2024-02-26',
        price: 867.4,
        signal: 'ascend'
      },
      {
        date: '2024-02-27',
        price: 853.75,
        signal: 'ascend'
      },
      {
        date: '2024-02-28',
        price: 863.39,
        signal: 'ascend'
      },
      {
        date: '2024-02-29',
        price: 862.96,
        signal: 'ascend'
      },
      {
        date: '2024-03-04',
        price: 872.97,
        signal: 'ascend'
      },
      {
        date: '2024-03-05',
        price: 866.37,
        signal: 'ascend'
      },
      {
        date: '2024-03-06',
        price: 870.67,
        signal: 'ascend'
      },
      {
        date: '2024-03-07',
        price: 863.37,
        signal: 'ascend'
      },
      {
        date: '2024-03-08',
        price: 873.18,
        signal: 'ascend'
      },
      {
        date: '2024-03-11',
        price: 875.93,
        signal: 'ascend'
      },
      {
        date: '2024-03-12',
        price: 889.71,
        signal: 'ascend'
      },
      {
        date: '2024-03-13',
        price: 889.93,
        signal: 'ascend'
      },
      {
        date: '2024-03-14',
        price: 887.52,
        signal: 'ascend'
      },
      {
        date: '2024-03-15',
        price: 880.46,
        signal: 'ascend'
      },
      {
        date: '2024-03-18',
        price: 894.48,
        signal: 'ascend'
      },
      {
        date: '2024-03-19',
        price: 891.91,
        signal: 'ascend'
      },
      {
        date: '2024-03-20',
        price: 891.45,
        signal: 'ascend'
      },
      {
        date: '2024-03-21',
        price: 904.29,
        signal: 'ascend'
      },
      {
        date: '2024-03-22',
        price: 903.98,
        signal: 'ascend'
      },
      {
        date: '2024-03-25',
        price: 913.69,
        signal: 'ascend'
      },
      {
        date: '2024-03-26',
        price: 916.09,
        signal: 'ascend'
      },
      {
        date: '2024-03-27',
        price: 911.25,
        signal: 'ascend'
      },
      {
        date: '2024-03-28',
        price: 910.05,
        signal: 'ascend'
      },
      {
        date: '2024-03-29',
        price: 905.5,
        signal: 'ascend'
      },
      {
        date: '2024-04-01',
        price: 912.45,
        signal: 'ascend'
      },
      {
        date: '2024-04-02',
        price: 891.59,
        signal: 'descend'
      },
      {
        date: '2024-04-03',
        price: 879.96,
        signal: 'descend'
      },
      {
        date: '2024-04-04',
        price: 882.9,
        signal: 'descend'
      },
      {
        date: '2024-04-05',
        price: 872.29,
        signal: 'descend'
      },
      {
        date: '2024-04-08',
        price: 860.57,
        signal: 'descend'
      },
      {
        date: '2024-04-09',
        price: 859.33,
        signal: 'descend'
      },
      {
        date: '2024-04-11',
        price: 858.1,
        signal: 'descend'
      },
      {
        date: '2024-04-12',
        price: 860.47,
        signal: 'descend'
      },
      {
        date: '2024-04-15',
        price: 852.42,
        signal: 'descend'
      },
      {
        date: '2024-04-16',
        price: 832.81,
        signal: 'descend'
      },
      {
        date: '2024-04-17',
        price: 833.03,
        signal: 'descend'
      },
      {
        date: '2024-04-18',
        price: 855.65,
        signal: 'descend'
      },
      {
        date: '2024-04-19',
        price: 841.91,
        signal: 'descend'
      },
      {
        date: '2024-04-22',
        price: 845.82,
        signal: 'descend'
      },
      {
        date: '2024-04-23',
        price: 845.44,
        signal: 'descend'
      },
      {
        date: '2024-04-24',
        price: 862.23,
        signal: 'descend'
      },
      {
        date: '2024-04-25',
        price: 853.26,
        signal: 'descend'
      },
      {
        date: '2024-04-26',
        price: 856.82,
        signal: 'descend'
      },
      {
        date: '2024-04-29',
        price: 869.72,
        signal: 'descend'
      },
      {
        date: '2024-04-30',
        price: 868.93,
        signal: 'descend'
      },
      {
        date: '2024-05-02',
        price: 867.48,
        signal: 'descend'
      },
      {
        date: '2024-05-03',
        price: 865.59,
        signal: 'descend'
      },
      {
        date: '2024-05-07',
        price: 871.26,
        signal: 'descend'
      },
      {
        date: '2024-05-08',
        price: 872.42,
        signal: 'descend'
      },
      {
        date: '2024-05-09',
        price: 870.15,
        signal: 'descend'
      },
      {
        date: '2024-05-10',
        price: 864.16,
        signal: 'descend'
      },
      {
        date: '2024-05-13',
        price: 854.43,
        signal: 'descend'
      },
      {
        date: '2024-05-14',
        price: 862.15,
        signal: 'descend'
      },
      {
        date: '2024-05-16',
        price: 870.37,
        signal: 'descend'
      },
      {
        date: '2024-05-17',
        price: 855.06,
        signal: 'descend'
      },
      {
        date: '2024-05-20',
        price: 847.08,
        signal: 'descend'
      },
      {
        date: '2024-05-21',
        price: 846.51,
        signal: 'descend'
      },
      {
        date: '2024-05-22',
        price: 845.72,
        signal: 'descend'
      },
      {
        date: '2024-05-23',
        price: 846.58,
        signal: 'descend'
      },
      {
        date: '2024-05-24',
        price: 839.41,
        signal: 'descend'
      },
      {
        date: '2024-05-27',
        price: 847.99,
        signal: 'descend'
      },
      {
        date: '2024-05-28',
        price: 851.01,
        signal: 'descend'
      },
      {
        date: '2024-05-29',
        price: 838.45,
        signal: 'descend'
      },
      {
        date: '2024-05-30',
        price: 831.99,
        signal: 'descend'
      },
      {
        date: '2024-05-31',
        price: 839.98,
        signal: 'descend'
      },
      {
        date: '2024-06-03',
        price: 844.72,
        signal: 'descend'
      },
      {
        date: '2024-06-04',
        price: 845.84,
        signal: 'descend'
      },
      {
        date: '2024-06-05',
        price: 850.75,
        signal: 'descend'
      },
      {
        date: '2024-06-07',
        price: 866.18,
        signal: 'descend'
      }
    ],
    signals: [
      {
        kind: 'ascend',
        date: '2023-01-13'
      },
      {
        kind: 'descend',
        date: '2023-04-21'
      },
      {
        kind: 'ascend',
        date: '2023-05-30'
      },
      {
        kind: 'descend',
        date: '2023-08-02'
      },
      {
        kind: 'ascend',
        date: '2023-11-03'
      },
      {
        kind: 'descend',
        date: '2024-01-15'
      },
      {
        kind: 'ascend',
        date: '2024-02-08'
      },
      {
        kind: 'descend',
        date: '2024-04-02'
      }
    ]
  }
];

export const stockData = {
  name: '삼성전자',
  ticker: '005930',
  market: 'KOSPI',
  target_price: 10000,
  expected_return_20days: -83.36106489184692,
  stop_loss_price: 100,
  daily_fluctuation_rate: 1.0084033613445378,
  current_price: 60100,
  buy_signals: ['2023-03-16', '2023-05-12', '2023-10-06', '2024-03-19', '2024-05-18'],
  sell_signals: ['2023-04-16', '2023-07-06', '2024-01-04', '2024-04-10'],
  candles: [
    {
      date: '2023-06-13',
      interval: 'day',
      opening_price: 71700,
      high_price: 72200,
      low_price: 71600,
      closing_price: 72000,
      volume: 13227285
    },
    {
      date: '2023-06-14',
      interval: 'day',
      opening_price: 72100,
      high_price: 72200,
      low_price: 71100,
      closing_price: 71900,
      volume: 12541046
    },
    {
      date: '2023-06-15',
      interval: 'day',
      opening_price: 72100,
      high_price: 72300,
      low_price: 71300,
      closing_price: 71500,
      volume: 13614994
    },
    {
      date: '2023-06-16',
      interval: 'day',
      opening_price: 71800,
      high_price: 71900,
      low_price: 70900,
      closing_price: 71800,
      volume: 15373696
    },
    {
      date: '2023-06-19',
      interval: 'day',
      opening_price: 71300,
      high_price: 71700,
      low_price: 70900,
      closing_price: 71200,
      volume: 11100887
    },
    {
      date: '2023-06-20',
      interval: 'day',
      opening_price: 70700,
      high_price: 71400,
      low_price: 70400,
      closing_price: 71400,
      volume: 11557883
    },
    {
      date: '2023-06-21',
      interval: 'day',
      opening_price: 70700,
      high_price: 71200,
      low_price: 70400,
      closing_price: 70500,
      volume: 10626603
    },
    {
      date: '2023-06-22',
      interval: 'day',
      opening_price: 70200,
      high_price: 71500,
      low_price: 70100,
      closing_price: 71300,
      volume: 11411007
    },
    {
      date: '2023-06-23',
      interval: 'day',
      opening_price: 72000,
      high_price: 72200,
      low_price: 71600,
      closing_price: 71600,
      volume: 12329484
    },
    {
      date: '2023-06-26',
      interval: 'day',
      opening_price: 71700,
      high_price: 72500,
      low_price: 71500,
      closing_price: 72400,
      volume: 10541901
    },
    {
      date: '2023-06-27',
      interval: 'day',
      opening_price: 72500,
      high_price: 72600,
      low_price: 72000,
      closing_price: 72600,
      volume: 9442997
    },
    {
      date: '2023-06-28',
      interval: 'day',
      opening_price: 72600,
      high_price: 72700,
      low_price: 72000,
      closing_price: 72700,
      volume: 8783093
    },
    {
      date: '2023-06-29',
      interval: 'day',
      opening_price: 73100,
      high_price: 73400,
      low_price: 72400,
      closing_price: 72400,
      volume: 12229967
    },
    {
      date: '2023-06-30',
      interval: 'day',
      opening_price: 72500,
      high_price: 72700,
      low_price: 71700,
      closing_price: 72200,
      volume: 11694765
    },
    {
      date: '2023-07-03',
      interval: 'day',
      opening_price: 72700,
      high_price: 73200,
      low_price: 72600,
      closing_price: 73000,
      volume: 10722181
    },
    {
      date: '2023-07-04',
      interval: 'day',
      opening_price: 73400,
      high_price: 73600,
      low_price: 72900,
      closing_price: 73000,
      volume: 10214350
    },
    {
      date: '2023-07-05',
      interval: 'day',
      opening_price: 73000,
      high_price: 73300,
      low_price: 71900,
      closing_price: 72000,
      volume: 12310610
    },
    {
      date: '2023-07-06',
      interval: 'day',
      opening_price: 71900,
      high_price: 72400,
      low_price: 71500,
      closing_price: 71600,
      volume: 14777667
    },
    {
      date: '2023-07-07',
      interval: 'day',
      opening_price: 71100,
      high_price: 71400,
      low_price: 69800,
      closing_price: 69900,
      volume: 17308876
    },
    {
      date: '2023-07-10',
      interval: 'day',
      opening_price: 70000,
      high_price: 70400,
      low_price: 69200,
      closing_price: 69500,
      volume: 11713926
    },
    {
      date: '2023-07-11',
      interval: 'day',
      opening_price: 70200,
      high_price: 71500,
      low_price: 70100,
      closing_price: 71500,
      volume: 12177392
    },
    {
      date: '2023-07-12',
      interval: 'day',
      opening_price: 71200,
      high_price: 72000,
      low_price: 71100,
      closing_price: 71900,
      volume: 10375581
    },
    {
      date: '2023-07-13',
      interval: 'day',
      opening_price: 72400,
      high_price: 72600,
      low_price: 71900,
      closing_price: 71900,
      volume: 14417279
    },
    {
      date: '2023-07-14',
      interval: 'day',
      opening_price: 72500,
      high_price: 73400,
      low_price: 72400,
      closing_price: 73400,
      volume: 15882519
    },
    {
      date: '2023-07-17',
      interval: 'day',
      opening_price: 73200,
      high_price: 73500,
      low_price: 72800,
      closing_price: 73300,
      volume: 10060049
    },
    {
      date: '2023-07-18',
      interval: 'day',
      opening_price: 73200,
      high_price: 73500,
      low_price: 72000,
      closing_price: 72000,
      volume: 11697900
    },
    {
      date: '2023-07-19',
      interval: 'day',
      opening_price: 72700,
      high_price: 72800,
      low_price: 71300,
      closing_price: 71700,
      volume: 10896412
    },
    {
      date: '2023-07-20',
      interval: 'day',
      opening_price: 71100,
      high_price: 71500,
      low_price: 70800,
      closing_price: 71000,
      volume: 9732730
    },
    {
      date: '2023-07-21',
      interval: 'day',
      opening_price: 70400,
      high_price: 70400,
      low_price: 69400,
      closing_price: 70300,
      volume: 16528926
    },
    {
      date: '2023-07-24',
      interval: 'day',
      opening_price: 70100,
      high_price: 70900,
      low_price: 69900,
      closing_price: 70400,
      volume: 13418597
    },
    {
      date: '2023-07-25',
      interval: 'day',
      opening_price: 70000,
      high_price: 70500,
      low_price: 69800,
      closing_price: 70000,
      volume: 14314945
    },
    {
      date: '2023-07-26',
      interval: 'day',
      opening_price: 69800,
      high_price: 70600,
      low_price: 68100,
      closing_price: 69800,
      volume: 30016220
    },
    {
      date: '2023-07-27',
      interval: 'day',
      opening_price: 69900,
      high_price: 71700,
      low_price: 69300,
      closing_price: 71700,
      volume: 24261180
    },
    {
      date: '2023-07-28',
      interval: 'day',
      opening_price: 71800,
      high_price: 72400,
      low_price: 70100,
      closing_price: 70600,
      volume: 19420684
    },
    {
      date: '2023-07-31',
      interval: 'day',
      opening_price: 70900,
      high_price: 71000,
      low_price: 69800,
      closing_price: 69800,
      volume: 13035420
    },
    {
      date: '2023-08-01',
      interval: 'day',
      opening_price: 70100,
      high_price: 71200,
      low_price: 70000,
      closing_price: 71100,
      volume: 12299254
    },
    {
      date: '2023-08-02',
      interval: 'day',
      opening_price: 70700,
      high_price: 71000,
      low_price: 69800,
      closing_price: 69900,
      volume: 13835020
    },
    {
      date: '2023-08-03',
      interval: 'day',
      opening_price: 69900,
      high_price: 70200,
      low_price: 68500,
      closing_price: 68800,
      volume: 20087090
    },
    {
      date: '2023-08-04',
      interval: 'day',
      opening_price: 68800,
      high_price: 69100,
      low_price: 68200,
      closing_price: 68300,
      volume: 12360193
    },
    {
      date: '2023-08-07',
      interval: 'day',
      opening_price: 67700,
      high_price: 69200,
      low_price: 67600,
      closing_price: 68500,
      volume: 10968505
    },
    {
      date: '2023-08-08',
      interval: 'day',
      opening_price: 69000,
      high_price: 69100,
      low_price: 67400,
      closing_price: 67600,
      volume: 14664709
    },
    {
      date: '2023-08-09',
      interval: 'day',
      opening_price: 68000,
      high_price: 69600,
      low_price: 67900,
      closing_price: 68900,
      volume: 17259672
    },
    {
      date: '2023-08-10',
      interval: 'day',
      opening_price: 68300,
      high_price: 68500,
      low_price: 67800,
      closing_price: 68000,
      volume: 10227311
    },
    {
      date: '2023-08-11',
      interval: 'day',
      opening_price: 68400,
      high_price: 68800,
      low_price: 67500,
      closing_price: 67500,
      volume: 9781038
    },
    {
      date: '2023-08-14',
      interval: 'day',
      opening_price: 67500,
      high_price: 67900,
      low_price: 66900,
      closing_price: 67300,
      volume: 9352343
    },
    {
      date: '2023-08-16',
      interval: 'day',
      opening_price: 66700,
      high_price: 67100,
      low_price: 66300,
      closing_price: 67000,
      volume: 13174578
    },
    {
      date: '2023-08-17',
      interval: 'day',
      opening_price: 66300,
      high_price: 66800,
      low_price: 66000,
      closing_price: 66700,
      volume: 10778652
    },
    {
      date: '2023-08-18',
      interval: 'day',
      opening_price: 66000,
      high_price: 66700,
      low_price: 65800,
      closing_price: 66300,
      volume: 11745006
    },
    {
      date: '2023-08-21',
      interval: 'day',
      opening_price: 66600,
      high_price: 67100,
      low_price: 66300,
      closing_price: 66600,
      volume: 9720067
    },
    {
      date: '2023-08-22',
      interval: 'day',
      opening_price: 67200,
      high_price: 67700,
      low_price: 66300,
      closing_price: 66600,
      volume: 10500242
    },
    {
      date: '2023-08-23',
      interval: 'day',
      opening_price: 66700,
      high_price: 67100,
      low_price: 66400,
      closing_price: 67100,
      volume: 9549352
    },
    {
      date: '2023-08-24',
      interval: 'day',
      opening_price: 68300,
      high_price: 68700,
      low_price: 67900,
      closing_price: 68200,
      volume: 15044463
    },
    {
      date: '2023-08-25',
      interval: 'day',
      opening_price: 67100,
      high_price: 67400,
      low_price: 66900,
      closing_price: 67100,
      volume: 7032462
    },
    {
      date: '2023-08-28',
      interval: 'day',
      opening_price: 66800,
      high_price: 67000,
      low_price: 66500,
      closing_price: 66800,
      volume: 5824628
    },
    {
      date: '2023-08-29',
      interval: 'day',
      opening_price: 66900,
      high_price: 67200,
      low_price: 66600,
      closing_price: 66800,
      volume: 9114352
    },
    {
      date: '2023-08-30',
      interval: 'day',
      opening_price: 67300,
      high_price: 67700,
      low_price: 67100,
      closing_price: 67100,
      volume: 9181223
    },
    {
      date: '2023-08-31',
      interval: 'day',
      opening_price: 67100,
      high_price: 67200,
      low_price: 66400,
      closing_price: 66900,
      volume: 15964630
    },
    {
      date: '2023-09-01',
      interval: 'day',
      opening_price: 66800,
      high_price: 71000,
      low_price: 66700,
      closing_price: 71000,
      volume: 29738236
    },
    {
      date: '2023-09-04',
      interval: 'day',
      opening_price: 72900,
      high_price: 72900,
      low_price: 70700,
      closing_price: 71200,
      volume: 26286496
    },
    {
      date: '2023-09-05',
      interval: 'day',
      opening_price: 70900,
      high_price: 71500,
      low_price: 70200,
      closing_price: 70700,
      volume: 12330239
    },
    {
      date: '2023-09-06',
      interval: 'day',
      opening_price: 70700,
      high_price: 70800,
      low_price: 69700,
      closing_price: 70000,
      volume: 11414620
    },
    {
      date: '2023-09-07',
      interval: 'day',
      opening_price: 70000,
      high_price: 70600,
      low_price: 69600,
      closing_price: 70400,
      volume: 13741241
    },
    {
      date: '2023-09-08',
      interval: 'day',
      opening_price: 70200,
      high_price: 70300,
      low_price: 69600,
      closing_price: 70300,
      volume: 10688118
    },
    {
      date: '2023-09-11',
      interval: 'day',
      opening_price: 70400,
      high_price: 70800,
      low_price: 70000,
      closing_price: 70800,
      volume: 11785462
    },
    {
      date: '2023-09-12',
      interval: 'day',
      opening_price: 70800,
      high_price: 71000,
      low_price: 70400,
      closing_price: 70500,
      volume: 11688599
    },
    {
      date: '2023-09-13',
      interval: 'day',
      opening_price: 71100,
      high_price: 71600,
      low_price: 70300,
      closing_price: 70900,
      volume: 15955797
    },
    {
      date: '2023-09-14',
      interval: 'day',
      opening_price: 71200,
      high_price: 71800,
      low_price: 70800,
      closing_price: 71700,
      volume: 21041408
    },
    {
      date: '2023-09-15',
      interval: 'day',
      opening_price: 71700,
      high_price: 72300,
      low_price: 71400,
      closing_price: 72000,
      volume: 17823512
    },
    {
      date: '2023-09-18',
      interval: 'day',
      opening_price: 71300,
      high_price: 71700,
      low_price: 70200,
      closing_price: 70200,
      volume: 16040727
    },
    {
      date: '2023-09-19',
      interval: 'day',
      opening_price: 70400,
      high_price: 70800,
      low_price: 69600,
      closing_price: 69800,
      volume: 11820188
    },
    {
      date: '2023-09-20',
      interval: 'day',
      opening_price: 70000,
      high_price: 70300,
      low_price: 69500,
      closing_price: 69600,
      volume: 10873015
    },
    {
      date: '2023-09-21',
      interval: 'day',
      opening_price: 69200,
      high_price: 69800,
      low_price: 68800,
      closing_price: 68900,
      volume: 10796336
    },
    {
      date: '2023-09-22',
      interval: 'day',
      opening_price: 68300,
      high_price: 68900,
      low_price: 68300,
      closing_price: 68800,
      volume: 9897840
    },
    {
      date: '2023-09-25',
      interval: 'day',
      opening_price: 68500,
      high_price: 69700,
      low_price: 68500,
      closing_price: 69400,
      volume: 13582516
    },
    {
      date: '2023-09-26',
      interval: 'day',
      opening_price: 70000,
      high_price: 70000,
      low_price: 68400,
      closing_price: 68600,
      volume: 13143470
    },
    {
      date: '2023-09-27',
      interval: 'day',
      opening_price: 68600,
      high_price: 69100,
      low_price: 68200,
      closing_price: 68400,
      volume: 14886491
    },
    {
      date: '2023-10-04',
      interval: 'day',
      opening_price: 67400,
      high_price: 67700,
      low_price: 66700,
      closing_price: 67500,
      volume: 23361148
    },
    {
      date: '2023-10-05',
      interval: 'day',
      opening_price: 67300,
      high_price: 67400,
      low_price: 66700,
      closing_price: 66700,
      volume: 16108313
    },
    {
      date: '2023-10-06',
      interval: 'day',
      opening_price: 67100,
      high_price: 67300,
      low_price: 66000,
      closing_price: 66000,
      volume: 14386527
    },
    {
      date: '2023-10-10',
      interval: 'day',
      opening_price: 66200,
      high_price: 67600,
      low_price: 66200,
      closing_price: 66400,
      volume: 19889202
    },
    {
      date: '2023-10-11',
      interval: 'day',
      opening_price: 68600,
      high_price: 69400,
      low_price: 67900,
      closing_price: 68200,
      volume: 25209348
    },
    {
      date: '2023-10-12',
      interval: 'day',
      opening_price: 68600,
      high_price: 69700,
      low_price: 68200,
      closing_price: 68900,
      volume: 19311380
    },
    {
      date: '2023-10-13',
      interval: 'day',
      opening_price: 68000,
      high_price: 68500,
      low_price: 67700,
      closing_price: 68000,
      volume: 9724086
    },
    {
      date: '2023-10-16',
      interval: 'day',
      opening_price: 67900,
      high_price: 68500,
      low_price: 66800,
      closing_price: 67300,
      volume: 12599299
    },
    {
      date: '2023-10-17',
      interval: 'day',
      opening_price: 67700,
      high_price: 69900,
      low_price: 67400,
      closing_price: 69400,
      volume: 17299252
    },
    {
      date: '2023-10-18',
      interval: 'day',
      opening_price: 68900,
      high_price: 70500,
      low_price: 68800,
      closing_price: 70500,
      volume: 16493184
    },
    {
      date: '2023-10-19',
      interval: 'day',
      opening_price: 69700,
      high_price: 70000,
      low_price: 69400,
      closing_price: 69500,
      volume: 13985012
    },
    {
      date: '2023-10-20',
      interval: 'day',
      opening_price: 68900,
      high_price: 69200,
      low_price: 68100,
      closing_price: 68800,
      volume: 15204495
    },
    {
      date: '2023-10-23',
      interval: 'day',
      opening_price: 68700,
      high_price: 69100,
      low_price: 68200,
      closing_price: 68400,
      volume: 11625959
    },
    {
      date: '2023-10-24',
      interval: 'day',
      opening_price: 68700,
      high_price: 68800,
      low_price: 67700,
      closing_price: 68500,
      volume: 12791710
    },
    {
      date: '2023-10-25',
      interval: 'day',
      opening_price: 68800,
      high_price: 68800,
      low_price: 67900,
      closing_price: 68000,
      volume: 10610703
    },
    {
      date: '2023-10-26',
      interval: 'day',
      opening_price: 67000,
      high_price: 67900,
      low_price: 66700,
      closing_price: 66700,
      volume: 15517624
    },
    {
      date: '2023-10-27',
      interval: 'day',
      opening_price: 67100,
      high_price: 67300,
      low_price: 66700,
      closing_price: 67300,
      volume: 11334726
    },
    {
      date: '2023-10-30',
      interval: 'day',
      opening_price: 66800,
      high_price: 67800,
      low_price: 66700,
      closing_price: 67300,
      volume: 10139270
    },
    {
      date: '2023-10-31',
      interval: 'day',
      opening_price: 67600,
      high_price: 68300,
      low_price: 66900,
      closing_price: 66900,
      volume: 14488892
    },
    {
      date: '2023-11-01',
      interval: 'day',
      opening_price: 67500,
      high_price: 68900,
      low_price: 67300,
      closing_price: 68600,
      volume: 13775256
    },
    {
      date: '2023-11-02',
      interval: 'day',
      opening_price: 70000,
      high_price: 70000,
      low_price: 69400,
      closing_price: 69700,
      volume: 16350031
    },
    {
      date: '2023-11-03',
      interval: 'day',
      opening_price: 69700,
      high_price: 70200,
      low_price: 69500,
      closing_price: 69600,
      volume: 10322234
    },
    {
      date: '2023-11-06',
      interval: 'day',
      opening_price: 69800,
      high_price: 70900,
      low_price: 69300,
      closing_price: 70900,
      volume: 22228488
    },
    {
      date: '2023-11-07',
      interval: 'day',
      opening_price: 70600,
      high_price: 70900,
      low_price: 70000,
      closing_price: 70900,
      volume: 17228732
    },
    {
      date: '2023-11-08',
      interval: 'day',
      opening_price: 71300,
      high_price: 71400,
      low_price: 69700,
      closing_price: 69900,
      volume: 12901310
    },
    {
      date: '2023-11-09',
      interval: 'day',
      opening_price: 69900,
      high_price: 70800,
      low_price: 69600,
      closing_price: 70300,
      volume: 12301373
    },
    {
      date: '2023-11-10',
      interval: 'day',
      opening_price: 70000,
      high_price: 70500,
      low_price: 69500,
      closing_price: 70500,
      volume: 9684347
    },
    {
      date: '2023-11-13',
      interval: 'day',
      opening_price: 71300,
      high_price: 71300,
      low_price: 70300,
      closing_price: 70400,
      volume: 9246919
    },
    {
      date: '2023-11-14',
      interval: 'day',
      opening_price: 71000,
      high_price: 71100,
      low_price: 70600,
      closing_price: 70800,
      volume: 9567984
    },
    {
      date: '2023-11-15',
      interval: 'day',
      opening_price: 71600,
      high_price: 72200,
      low_price: 71500,
      closing_price: 72200,
      volume: 20148676
    },
    {
      date: '2023-11-16',
      interval: 'day',
      opening_price: 72500,
      high_price: 73000,
      low_price: 72300,
      closing_price: 72800,
      volume: 15860451
    },
    {
      date: '2023-11-17',
      interval: 'day',
      opening_price: 72300,
      high_price: 73000,
      low_price: 72300,
      closing_price: 72500,
      volume: 11494644
    },
    {
      date: '2023-11-20',
      interval: 'day',
      opening_price: 72100,
      high_price: 73000,
      low_price: 72100,
      closing_price: 72700,
      volume: 10610157
    },
    {
      date: '2023-11-21',
      interval: 'day',
      opening_price: 73100,
      high_price: 73400,
      low_price: 72700,
      closing_price: 72800,
      volume: 9712881
    },
    {
      date: '2023-11-22',
      interval: 'day',
      opening_price: 72200,
      high_price: 73000,
      low_price: 71900,
      closing_price: 72800,
      volume: 11105143
    },
    {
      date: '2023-11-23',
      interval: 'day',
      opening_price: 73000,
      high_price: 73200,
      low_price: 72200,
      closing_price: 72400,
      volume: 6775614
    },
    {
      date: '2023-11-24',
      interval: 'day',
      opening_price: 72400,
      high_price: 72600,
      low_price: 71700,
      closing_price: 71700,
      volume: 6676685
    },
    {
      date: '2023-11-27',
      interval: 'day',
      opening_price: 71500,
      high_price: 72100,
      low_price: 71100,
      closing_price: 71300,
      volume: 9113857
    },
    {
      date: '2023-11-28',
      interval: 'day',
      opening_price: 71400,
      high_price: 72700,
      low_price: 71300,
      closing_price: 72700,
      volume: 13283081
    },
    {
      date: '2023-11-29',
      interval: 'day',
      opening_price: 72400,
      high_price: 72800,
      low_price: 72200,
      closing_price: 72700,
      volume: 9283933
    },
    {
      date: '2023-11-30',
      interval: 'day',
      opening_price: 72700,
      high_price: 72800,
      low_price: 72200,
      closing_price: 72800,
      volume: 15783714
    },
    {
      date: '2023-12-01',
      interval: 'day',
      opening_price: 72400,
      high_price: 72500,
      low_price: 71700,
      closing_price: 72000,
      volume: 9871284
    },
    {
      date: '2023-12-04',
      interval: 'day',
      opening_price: 72800,
      high_price: 72900,
      low_price: 72400,
      closing_price: 72600,
      volume: 10229267
    },
    {
      date: '2023-12-05',
      interval: 'day',
      opening_price: 72300,
      high_price: 72400,
      low_price: 71200,
      closing_price: 71200,
      volume: 12129682
    },
    {
      date: '2023-12-06',
      interval: 'day',
      opening_price: 71800,
      high_price: 72100,
      low_price: 71600,
      closing_price: 71700,
      volume: 8123087
    },
    {
      date: '2023-12-07',
      interval: 'day',
      opening_price: 71800,
      high_price: 71900,
      low_price: 71100,
      closing_price: 71500,
      volume: 8862017
    },
    {
      date: '2023-12-08',
      interval: 'day',
      opening_price: 72100,
      high_price: 72800,
      low_price: 71900,
      closing_price: 72600,
      volume: 10859463
    },
    {
      date: '2023-12-11',
      interval: 'day',
      opening_price: 72800,
      high_price: 73000,
      low_price: 72200,
      closing_price: 73000,
      volume: 9861960
    },
    {
      date: '2023-12-12',
      interval: 'day',
      opening_price: 73300,
      high_price: 73500,
      low_price: 73100,
      closing_price: 73500,
      volume: 13758646
    },
    {
      date: '2023-12-13',
      interval: 'day',
      opening_price: 73300,
      high_price: 73500,
      low_price: 72800,
      closing_price: 72800,
      volume: 13116766
    },
    {
      date: '2023-12-14',
      interval: 'day',
      opening_price: 74100,
      high_price: 74300,
      low_price: 72500,
      closing_price: 73100,
      volume: 27567592
    },
    {
      date: '2023-12-15',
      interval: 'day',
      opening_price: 73800,
      high_price: 74000,
      low_price: 73200,
      closing_price: 73300,
      volume: 15419815
    },
    {
      date: '2023-12-18',
      interval: 'day',
      opening_price: 73300,
      high_price: 73400,
      low_price: 72800,
      closing_price: 72900,
      volume: 9690551
    },
    {
      date: '2023-12-19',
      interval: 'day',
      opening_price: 73000,
      high_price: 73400,
      low_price: 72800,
      closing_price: 73400,
      volume: 8907632
    },
    {
      date: '2023-12-20',
      interval: 'day',
      opening_price: 74200,
      high_price: 74900,
      low_price: 73800,
      closing_price: 74800,
      volume: 16870156
    },
    {
      date: '2023-12-21',
      interval: 'day',
      opening_price: 74600,
      high_price: 75000,
      low_price: 74300,
      closing_price: 75000,
      volume: 13478766
    },
    {
      date: '2023-12-22',
      interval: 'day',
      opening_price: 75800,
      high_price: 76300,
      low_price: 75400,
      closing_price: 75900,
      volume: 14515608
    },
    {
      date: '2023-12-26',
      interval: 'day',
      opening_price: 76100,
      high_price: 76700,
      low_price: 75700,
      closing_price: 76600,
      volume: 13164909
    },
    {
      date: '2023-12-27',
      interval: 'day',
      opening_price: 76700,
      high_price: 78000,
      low_price: 76500,
      closing_price: 78000,
      volume: 20651042
    },
    {
      date: '2023-12-28',
      interval: 'day',
      opening_price: 77700,
      high_price: 78500,
      low_price: 77500,
      closing_price: 78500,
      volume: 17797536
    },
    {
      date: '2024-01-02',
      interval: 'day',
      opening_price: 78200,
      high_price: 79800,
      low_price: 78200,
      closing_price: 79600,
      volume: 17142848
    },
    {
      date: '2024-01-03',
      interval: 'day',
      opening_price: 78500,
      high_price: 78800,
      low_price: 77000,
      closing_price: 77000,
      volume: 21753644
    },
    {
      date: '2024-01-04',
      interval: 'day',
      opening_price: 76100,
      high_price: 77300,
      low_price: 76100,
      closing_price: 76600,
      volume: 15324439
    },
    {
      date: '2024-01-05',
      interval: 'day',
      opening_price: 76700,
      high_price: 77100,
      low_price: 76400,
      closing_price: 76600,
      volume: 11304316
    },
    {
      date: '2024-01-08',
      interval: 'day',
      opening_price: 77000,
      high_price: 77500,
      low_price: 76400,
      closing_price: 76500,
      volume: 11088724
    },
    {
      date: '2024-01-09',
      interval: 'day',
      opening_price: 77400,
      high_price: 77700,
      low_price: 74300,
      closing_price: 74700,
      volume: 26019248
    },
    {
      date: '2024-01-10',
      interval: 'day',
      opening_price: 75000,
      high_price: 75200,
      low_price: 73200,
      closing_price: 73600,
      volume: 20259528
    },
    {
      date: '2024-01-11',
      interval: 'day',
      opening_price: 72900,
      high_price: 73600,
      low_price: 72700,
      closing_price: 73200,
      volume: 57691264
    },
    {
      date: '2024-01-12',
      interval: 'day',
      opening_price: 73000,
      high_price: 74100,
      low_price: 72800,
      closing_price: 73100,
      volume: 13038939
    },
    {
      date: '2024-01-15',
      interval: 'day',
      opening_price: 73200,
      high_price: 74000,
      low_price: 73200,
      closing_price: 73900,
      volume: 13212339
    },
    {
      date: '2024-01-16',
      interval: 'day',
      opening_price: 73500,
      high_price: 73700,
      low_price: 72500,
      closing_price: 72600,
      volume: 14760415
    },
    {
      date: '2024-01-17',
      interval: 'day',
      opening_price: 73100,
      high_price: 73300,
      low_price: 71000,
      closing_price: 71000,
      volume: 22683660
    },
    {
      date: '2024-01-18',
      interval: 'day',
      opening_price: 71600,
      high_price: 72000,
      low_price: 70700,
      closing_price: 71700,
      volume: 17853396
    },
    {
      date: '2024-01-19',
      interval: 'day',
      opening_price: 73500,
      high_price: 74700,
      low_price: 73000,
      closing_price: 74700,
      volume: 23363428
    },
    {
      date: '2024-01-22',
      interval: 'day',
      opening_price: 75900,
      high_price: 76000,
      low_price: 75000,
      closing_price: 75100,
      volume: 19673376
    },
    {
      date: '2024-01-23',
      interval: 'day',
      opening_price: 75700,
      high_price: 75800,
      low_price: 74300,
      closing_price: 75200,
      volume: 14786224
    },
    {
      date: '2024-01-24',
      interval: 'day',
      opening_price: 75200,
      high_price: 75200,
      low_price: 73500,
      closing_price: 74000,
      volume: 12860661
    },
    {
      date: '2024-01-25',
      interval: 'day',
      opening_price: 74200,
      high_price: 74800,
      low_price: 73700,
      closing_price: 74100,
      volume: 11737747
    },
    {
      date: '2024-01-26',
      interval: 'day',
      opening_price: 73700,
      high_price: 74500,
      low_price: 73300,
      closing_price: 73400,
      volume: 11160062
    },
    {
      date: '2024-01-29',
      interval: 'day',
      opening_price: 73800,
      high_price: 75200,
      low_price: 73500,
      closing_price: 74400,
      volume: 13976521
    },
    {
      date: '2024-01-30',
      interval: 'day',
      opening_price: 75000,
      high_price: 75300,
      low_price: 73700,
      closing_price: 74300,
      volume: 12244418
    },
    {
      date: '2024-01-31',
      interval: 'day',
      opening_price: 73400,
      high_price: 74000,
      low_price: 72500,
      closing_price: 72700,
      volume: 15703560
    },
    {
      date: '2024-02-01',
      interval: 'day',
      opening_price: 73000,
      high_price: 74200,
      low_price: 72900,
      closing_price: 73600,
      volume: 19881032
    },
    {
      date: '2024-02-02',
      interval: 'day',
      opening_price: 74000,
      high_price: 75200,
      low_price: 73700,
      closing_price: 75200,
      volume: 14955881
    },
    {
      date: '2024-02-05',
      interval: 'day',
      opening_price: 74200,
      high_price: 74800,
      low_price: 73500,
      closing_price: 74300,
      volume: 19026020
    },
    {
      date: '2024-02-06',
      interval: 'day',
      opening_price: 74300,
      high_price: 74700,
      low_price: 73300,
      closing_price: 74400,
      volume: 14559254
    },
    {
      date: '2024-02-07',
      interval: 'day',
      opening_price: 74600,
      high_price: 75500,
      low_price: 74300,
      closing_price: 75000,
      volume: 16566445
    },
    {
      date: '2024-02-08',
      interval: 'day',
      opening_price: 75000,
      high_price: 75200,
      low_price: 73600,
      closing_price: 74100,
      volume: 20810708
    },
    {
      date: '2024-02-13',
      interval: 'day',
      opening_price: 74800,
      high_price: 75200,
      low_price: 74400,
      closing_price: 75200,
      volume: 21966744
    },
    {
      date: '2024-02-14',
      interval: 'day',
      opening_price: 73700,
      high_price: 74300,
      low_price: 73700,
      closing_price: 74000,
      volume: 12434945
    },
    {
      date: '2024-02-15',
      interval: 'day',
      opening_price: 74200,
      high_price: 74400,
      low_price: 73000,
      closing_price: 73000,
      volume: 14120600
    },
    {
      date: '2024-02-16',
      interval: 'day',
      opening_price: 73300,
      high_price: 73400,
      low_price: 72500,
      closing_price: 72800,
      volume: 13444781
    },
    {
      date: '2024-02-19',
      interval: 'day',
      opening_price: 72800,
      high_price: 73900,
      low_price: 72800,
      closing_price: 73800,
      volume: 12726404
    },
    {
      date: '2024-02-20',
      interval: 'day',
      opening_price: 73700,
      high_price: 73700,
      low_price: 72800,
      closing_price: 73300,
      volume: 14681477
    },
    {
      date: '2024-02-21',
      interval: 'day',
      opening_price: 73400,
      high_price: 73700,
      low_price: 72900,
      closing_price: 73000,
      volume: 11503495
    },
    {
      date: '2024-02-22',
      interval: 'day',
      opening_price: 73800,
      high_price: 73900,
      low_price: 72700,
      closing_price: 73100,
      volume: 15208934
    },
    {
      date: '2024-02-23',
      interval: 'day',
      opening_price: 73600,
      high_price: 74200,
      low_price: 72900,
      closing_price: 72900,
      volume: 16225166
    },
    {
      date: '2024-02-26',
      interval: 'day',
      opening_price: 72300,
      high_price: 73200,
      low_price: 72200,
      closing_price: 72800,
      volume: 14669352
    },
    {
      date: '2024-02-27',
      interval: 'day',
      opening_price: 73100,
      high_price: 73400,
      low_price: 72700,
      closing_price: 72900,
      volume: 13201981
    },
    {
      date: '2024-02-28',
      interval: 'day',
      opening_price: 72900,
      high_price: 73900,
      low_price: 72800,
      closing_price: 73200,
      volume: 11795859
    },
    {
      date: '2024-02-29',
      interval: 'day',
      opening_price: 72600,
      high_price: 73400,
      low_price: 72000,
      closing_price: 73400,
      volume: 21176404
    },
    {
      date: '2024-03-04',
      interval: 'day',
      opening_price: 74300,
      high_price: 75000,
      low_price: 74000,
      closing_price: 74900,
      volume: 23210474
    },
    {
      date: '2024-03-05',
      interval: 'day',
      opening_price: 74600,
      high_price: 74800,
      low_price: 73700,
      closing_price: 73700,
      volume: 19505124
    },
    {
      date: '2024-03-06',
      interval: 'day',
      opening_price: 73200,
      high_price: 73500,
      low_price: 72700,
      closing_price: 72900,
      volume: 21547904
    },
    {
      date: '2024-03-07',
      interval: 'day',
      opening_price: 73100,
      high_price: 73300,
      low_price: 72200,
      closing_price: 72200,
      volume: 14516963
    },
    {
      date: '2024-03-08',
      interval: 'day',
      opening_price: 72800,
      high_price: 73400,
      low_price: 72600,
      closing_price: 73300,
      volume: 19271348
    },
    {
      date: '2024-03-11',
      interval: 'day',
      opening_price: 72900,
      high_price: 73100,
      low_price: 72300,
      closing_price: 72400,
      volume: 9740504
    },
    {
      date: '2024-03-12',
      interval: 'day',
      opening_price: 72600,
      high_price: 73500,
      low_price: 72100,
      closing_price: 73300,
      volume: 13011654
    },
    {
      date: '2024-03-13',
      interval: 'day',
      opening_price: 73700,
      high_price: 74100,
      low_price: 73500,
      closing_price: 74100,
      volume: 15243134
    },
    {
      date: '2024-03-14',
      interval: 'day',
      opening_price: 74400,
      high_price: 74500,
      low_price: 73600,
      closing_price: 74300,
      volume: 22545540
    },
    {
      date: '2024-03-15',
      interval: 'day',
      opening_price: 73400,
      high_price: 73700,
      low_price: 72300,
      closing_price: 72300,
      volume: 22580556
    },
    {
      date: '2024-03-18',
      interval: 'day',
      opening_price: 72600,
      high_price: 73000,
      low_price: 72500,
      closing_price: 72800,
      volume: 11520348
    },
    {
      date: '2024-03-19',
      interval: 'day',
      opening_price: 72300,
      high_price: 73000,
      low_price: 71700,
      closing_price: 72800,
      volume: 15376066
    },
    {
      date: '2024-03-20',
      interval: 'day',
      opening_price: 73700,
      high_price: 77200,
      low_price: 73400,
      closing_price: 76900,
      volume: 50106296
    },
    {
      date: '2024-03-21',
      interval: 'day',
      opening_price: 79200,
      high_price: 79300,
      low_price: 77700,
      closing_price: 79300,
      volume: 44569800
    },
    {
      date: '2024-03-22',
      interval: 'day',
      opening_price: 79600,
      high_price: 79900,
      low_price: 77800,
      closing_price: 78900,
      volume: 26724760
    },
    {
      date: '2024-03-25',
      interval: 'day',
      opening_price: 79600,
      high_price: 79800,
      low_price: 77800,
      closing_price: 78200,
      volume: 18703996
    },
    {
      date: '2024-03-26',
      interval: 'day',
      opening_price: 79700,
      high_price: 80100,
      low_price: 79200,
      closing_price: 79900,
      volume: 30551494
    },
    {
      date: '2024-03-27',
      interval: 'day',
      opening_price: 79200,
      high_price: 80000,
      low_price: 79200,
      closing_price: 79800,
      volume: 17424596
    },
    {
      date: '2024-03-28',
      interval: 'day',
      opening_price: 79400,
      high_price: 81000,
      low_price: 79200,
      closing_price: 80800,
      volume: 25084812
    },
    {
      date: '2024-03-29',
      interval: 'day',
      opening_price: 81200,
      high_price: 82500,
      low_price: 80900,
      closing_price: 82400,
      volume: 27126366
    },
    {
      date: '2024-04-01',
      interval: 'day',
      opening_price: 83200,
      high_price: 83300,
      low_price: 82000,
      closing_price: 82000,
      volume: 20116512
    },
    {
      date: '2024-04-02',
      interval: 'day',
      opening_price: 82900,
      high_price: 85000,
      low_price: 82900,
      closing_price: 85000,
      volume: 37077944
    },
    {
      date: '2024-04-03',
      interval: 'day',
      opening_price: 84300,
      high_price: 85000,
      low_price: 83500,
      closing_price: 84100,
      volume: 30493348
    },
    {
      date: '2024-04-04',
      interval: 'day',
      opening_price: 85200,
      high_price: 85500,
      low_price: 84300,
      closing_price: 85300,
      volume: 25248934
    },
    {
      date: '2024-04-05',
      interval: 'day',
      opening_price: 84500,
      high_price: 85000,
      low_price: 83800,
      closing_price: 84500,
      volume: 18883752
    },
    {
      date: '2024-04-08',
      interval: 'day',
      opening_price: 85200,
      high_price: 86000,
      low_price: 84500,
      closing_price: 84500,
      volume: 18953232
    },
    {
      date: '2024-04-09',
      interval: 'day',
      opening_price: 84500,
      high_price: 84900,
      low_price: 83100,
      closing_price: 83600,
      volume: 23725956
    },
    {
      date: '2024-04-11',
      interval: 'day',
      opening_price: 83200,
      high_price: 84700,
      low_price: 82500,
      closing_price: 84100,
      volume: 25538008
    },
    {
      date: '2024-04-12',
      interval: 'day',
      opening_price: 84700,
      high_price: 84900,
      low_price: 83200,
      closing_price: 83700,
      volume: 17061770
    },
    {
      date: '2024-04-15',
      interval: 'day',
      opening_price: 82900,
      high_price: 83200,
      low_price: 81200,
      closing_price: 82200,
      volume: 26663772
    },
    {
      date: '2024-04-16',
      interval: 'day',
      opening_price: 81200,
      high_price: 81300,
      low_price: 79400,
      closing_price: 80000,
      volume: 31949844
    },
    {
      date: '2024-04-17',
      interval: 'day',
      opening_price: 80700,
      high_price: 80800,
      low_price: 78900,
      closing_price: 78900,
      volume: 22611632
    },
    {
      date: '2024-04-18',
      interval: 'day',
      opening_price: 78800,
      high_price: 80100,
      low_price: 78300,
      closing_price: 79600,
      volume: 21370190
    },
    {
      date: '2024-04-19',
      interval: 'day',
      opening_price: 78300,
      high_price: 78700,
      low_price: 76300,
      closing_price: 77600,
      volume: 31317564
    },
    {
      date: '2024-04-22',
      interval: 'day',
      opening_price: 77400,
      high_price: 77500,
      low_price: 75100,
      closing_price: 76100,
      volume: 30469476
    },
    {
      date: '2024-04-23',
      interval: 'day',
      opening_price: 76400,
      high_price: 76800,
      low_price: 75500,
      closing_price: 75500,
      volume: 18717700
    },
    {
      date: '2024-04-24',
      interval: 'day',
      opening_price: 77500,
      high_price: 78800,
      low_price: 77200,
      closing_price: 78600,
      volume: 22166150
    },
    {
      date: '2024-04-25',
      interval: 'day',
      opening_price: 77300,
      high_price: 77500,
      low_price: 76300,
      closing_price: 76300,
      volume: 15549134
    },
    {
      date: '2024-04-26',
      interval: 'day',
      opening_price: 77800,
      high_price: 77900,
      low_price: 76500,
      closing_price: 76700,
      volume: 12755629
    },
    {
      date: '2024-04-29',
      interval: 'day',
      opening_price: 77400,
      high_price: 77600,
      low_price: 76200,
      closing_price: 76700,
      volume: 14664474
    },
    {
      date: '2024-04-30',
      interval: 'day',
      opening_price: 77000,
      high_price: 78500,
      low_price: 76600,
      closing_price: 77500,
      volume: 19007008
    },
    {
      date: '2024-05-02',
      interval: 'day',
      opening_price: 77600,
      high_price: 78600,
      low_price: 77300,
      closing_price: 78000,
      volume: 18900640
    },
    {
      date: '2024-05-03',
      interval: 'day',
      opening_price: 79000,
      high_price: 79000,
      low_price: 77500,
      closing_price: 77600,
      volume: 13151889
    },
    {
      date: '2024-05-07',
      interval: 'day',
      opening_price: 79600,
      high_price: 81300,
      low_price: 79400,
      closing_price: 81300,
      volume: 26238868
    },
    {
      date: '2024-05-08',
      interval: 'day',
      opening_price: 80800,
      high_price: 81400,
      low_price: 80500,
      closing_price: 81300,
      volume: 13089576
    },
    {
      date: '2024-05-09',
      interval: 'day',
      opening_price: 81100,
      high_price: 81500,
      low_price: 79700,
      closing_price: 79700,
      volume: 18759936
    },
    {
      date: '2024-05-10',
      interval: 'day',
      opening_price: 80400,
      high_price: 81100,
      low_price: 78900,
      closing_price: 79200,
      volume: 16976124
    },
    {
      date: '2024-05-13',
      interval: 'day',
      opening_price: 79400,
      high_price: 79900,
      low_price: 77600,
      closing_price: 78400,
      volume: 18652344
    },
    {
      date: '2024-05-14',
      interval: 'day',
      opening_price: 78600,
      high_price: 78800,
      low_price: 77900,
      closing_price: 78300,
      volume: 11763992
    },
    {
      date: '2024-05-16',
      interval: 'day',
      opening_price: 80200,
      high_price: 80300,
      low_price: 78100,
      closing_price: 78200,
      volume: 20989778
    },
    {
      date: '2024-05-17',
      interval: 'day',
      opening_price: 78600,
      high_price: 78800,
      low_price: 77200,
      closing_price: 77400,
      volume: 15918201
    },
    {
      date: '2024-05-20',
      interval: 'day',
      opening_price: 78100,
      high_price: 79100,
      low_price: 77900,
      closing_price: 78900,
      volume: 19456784
    },
    {
      date: '2024-05-21',
      interval: 'day',
      opening_price: 78500,
      high_price: 79000,
      low_price: 78200,
      closing_price: 78400,
      volume: 13690583
    },
    {
      date: '2024-05-22',
      interval: 'day',
      opening_price: 78100,
      high_price: 78700,
      low_price: 77300,
      closing_price: 77700,
      volume: 19521506
    },
    {
      date: '2024-05-23',
      interval: 'day',
      opening_price: 77700,
      high_price: 79100,
      low_price: 77100,
      closing_price: 78300,
      volume: 18728088
    },
    {
      date: '2024-05-24',
      interval: 'day',
      opening_price: 76800,
      high_price: 77000,
      low_price: 75700,
      closing_price: 75900,
      volume: 27891278
    },
    {
      date: '2024-05-27',
      interval: 'day',
      opening_price: 75300,
      high_price: 78200,
      low_price: 74000,
      closing_price: 77200,
      volume: 43825200
    },
    {
      date: '2024-05-28',
      interval: 'day',
      opening_price: 76500,
      high_price: 78000,
      low_price: 76200,
      closing_price: 77600,
      volume: 18539072
    },
    {
      date: '2024-05-29',
      interval: 'day',
      opening_price: 77700,
      high_price: 78200,
      low_price: 75200,
      closing_price: 75200,
      volume: 30244876
    },
    {
      date: '2024-05-30',
      interval: 'day',
      opening_price: 74800,
      high_price: 75200,
      low_price: 73500,
      closing_price: 73500,
      volume: 28551272
    },
    {
      date: '2024-05-31',
      interval: 'day',
      opening_price: 74500,
      high_price: 74700,
      low_price: 73500,
      closing_price: 73500,
      volume: 26198776
    },
    {
      date: '2024-06-03',
      interval: 'day',
      opening_price: 74400,
      high_price: 76400,
      low_price: 74200,
      closing_price: 75700,
      volume: 15706268
    },
    {
      date: '2024-06-04',
      interval: 'day',
      opening_price: 74900,
      high_price: 76100,
      low_price: 74900,
      closing_price: 75300,
      volume: 14098053
    },
    {
      date: '2024-06-05',
      interval: 'day',
      opening_price: 78000,
      high_price: 78000,
      low_price: 76800,
      closing_price: 77400,
      volume: 23177968
    },
    {
      date: '2024-06-07',
      interval: 'day',
      opening_price: 78400,
      high_price: 78600,
      low_price: 77100,
      closing_price: 77300,
      volume: 21189348
    },
    {
      date: '2024-12-01',
      interval: 'day',
      opening_price: 1000,
      high_price: 1200,
      low_price: 900,
      closing_price: 1100,
      volume: 1000
    },
    {
      date: '2024-12-02',
      interval: 'day',
      opening_price: 900,
      high_price: 1200,
      low_price: 900,
      closing_price: 1100,
      volume: 1000
    }
  ]
};
