/** @jsxImportSource @emotion/react */

import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import Box from '@components/Box';
import ChipButton from '@components/ChipButton';
import Icon from '@components/Icon';
import { InfinitySpin } from 'react-loader-spinner';
import { colors } from '@constants/color';
import { css } from '@emotion/react';
import debounce from 'lodash/debounce';
import useFetch from '@hooks/useFetch';

export default function Searchbar() {
  // constant
  const PLACEHOLDER = '주가 예측으로 미리 전략을 구축하세요';
  // reacto-router-dom
  const navigate = useNavigate();
  const { pathname, hash } = useLocation();
  // state
  const [isSearchPanelOpen, setIsSearchPanelOpen] = useState(hash === '#search');

  const toggleSearchPanel = () => setIsSearchPanelOpen((prev) => !prev);
  const handleClickOpenSearchPanel = () => {
    toggleSearchPanel();
    navigate(pathname + '#search');
  };
  const handleClickCloseSearchPanel = () => {
    toggleSearchPanel();
    navigate(pathname);
  };
  useEffect(() => {
    setIsSearchPanelOpen(hash === '#search');
  }, [hash]);
  return (
    <>
      <Box px={1}>
        <SearchButton placeholder={PLACEHOLDER} onClick={handleClickOpenSearchPanel} />
      </Box>

      <SearchPanel
        placeholder={PLACEHOLDER}
        open={isSearchPanelOpen}
        onClose={handleClickCloseSearchPanel}
      />
    </>
  );
}
const SearchButton = ({ placeholder, onClick }) => {
  return (
    <button
      aria-label='Search panel opener'
      type='button'
      onClick={onClick}
      css={css`
        position: relative;
        width: 100%;
        min-height: 3rem;
        height: 3rem;
        max-height: 3rem;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 1rem;
        border-radius: 100px;
        border: 1px solid ${colors.border.default.primary};
        transition: 80ms ease-in-out;
        transition-property: color, background-color, border;
        background-color: var(--color-bg);
        & > p {
          width: fit-content;
          font-weight: bold;
          position: relative;
          text-align: left;
          letter-spacing: -1.2px;
          word-spacing: -0.5px;
        }
        & > p::after {
          content: '';
          min-width: 5px;
          min-height: 5px;
          width: 5px;
          height: 5px;
          border-radius: 100px;
          background-color: ${colors.text.default.brand};
          position: absolute;
          right: -10px;
          top: 0;
        }
      `}
    >
      <p>{placeholder}</p>
      <div
        css={css`
          box-sizing: content-box;
          flex-shrink: 0;
          width: calc(3.6rem - 6px);
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 100px;
          margin: 2px;
          span {
            display: none;
          }
        `}
      >
        <Icon.MagnifyGlass strokeWidth={0.2} color={colors.text.default.brand} size={22} />
      </div>
    </button>
  );
};
const SearchPanel = ({ placeholder, open, onClose }) => {
  // fetch hook
  const { data, fetching, initializeData, getData } = useFetch({ initialData: [] });
  // reacto-router-dom
  const { hash } = useLocation();
  // ref
  const inputRef = useRef(null);
  // state
  const [keyword, setKeyword] = useState('');
  const [recentSearchKeywords, setRecentSearchKeywords] = useState([]);

  const getStocks = useCallback(
    async (value) => {
      if (keyword === value) return;
      setKeyword(value);
      if (!value) {
        initializeData();
        return;
      }
      await getData('/search_stocks', { search_keyword: value });
    },
    [keyword]
  );
  const saveRecentSearchKeywords = useCallback((keyword) => {
    let recentSearchKeywords = localStorage.getItem('HIGHSIGNAL.recent_search_keywords');
    if (Array.isArray(JSON.parse(recentSearchKeywords))) {
      recentSearchKeywords = JSON.parse(recentSearchKeywords);
      recentSearchKeywords = recentSearchKeywords.filter((o) => o !== keyword);
      recentSearchKeywords.unshift(keyword);
      const updatedArray = recentSearchKeywords.slice(0, 5);
      localStorage.setItem('HIGHSIGNAL.recent_search_keywords', JSON.stringify(updatedArray));
      setRecentSearchKeywords(updatedArray);
    } else {
      const newArray = [keyword];
      localStorage.setItem('HIGHSIGNAL.recent_search_keywords', JSON.stringify(newArray));
      setRecentSearchKeywords(newArray);
    }
  }, []);
  const handleClickKeywordXButton = useCallback((index) => {
    recentSearchKeywords.splice(index, 1);
    localStorage.setItem('HIGHSIGNAL.recent_search_keywords', JSON.stringify(recentSearchKeywords));
    setRecentSearchKeywords((prev) => prev.filter((k, i) => i !== index));
  }, []);
  const handleClickRecentSearchKeyword = useCallback(async (recentKeyword) => {
    inputRef.current.value = recentKeyword;
    await getStocks(recentKeyword);
    saveRecentSearchKeywords(recentKeyword);
  }, []);
  const onSubmit = async (event) => {
    event.preventDefault();
    if (onChange?.name === 'debounced') onChange.cancel();
    const formData = new FormData(event.target);
    const keyword = formData.get('keyword');
    await getStocks(keyword);
    saveRecentSearchKeywords(keyword);
  };
  const onChange = debounce(async (event) => {
    await getStocks(event.target.value);
  }, 450);

  useEffect(() => {
    return () => {
      if (inputRef.current) inputRef.current.value = '';
      initializeData();
      setKeyword('');
    };
  }, [hash]);
  useEffect(() => {
    if (open && inputRef.current) inputRef.current.focus();
    const storageItem = JSON.parse(localStorage.getItem('HIGHSIGNAL.recent_search_keywords'));
    if (Array.isArray(storageItem)) setRecentSearchKeywords(storageItem);
  }, [open]);
  return (
    <div
      css={css`
        position: fixed;
        top: 0;
        z-index: var(--z-index-2);
        display: ${open ? 'flex' : 'none'};
        flex-direction: column;
        align-items: stretch;
        width: 100%;
        max-width: 450px;
        min-height: 100%;
        height: 100%;
        background-color: var(--color-bg);
        flex: 1;
      `}
    >
      <form
        data-searchbar
        onSubmit={onSubmit}
        css={css`
          flex-shrink: 0;
          height: 4rem;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          margin-bottom: 0;
          display: flex;
          align-items: stretch;
          input {
            font-weight: 500;
            width: 100%;
            border: none;
            outline: none;
            background-color: transparent;
            &::placeholder {
              color: ${colors.text.default.disabled};
            }
          }
          button {
            flex-shrink: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            padding-left: 1rem;
            padding-right: 1rem;
            span {
              display: none;
            }
            img {
              width: 2rem;
              color: black;
            }
            * {
              pointer-events: none;
            }
          }
        `}
      >
        <button type='button' aria-label='Go back' onClick={onClose}>
          <Icon.ArrowLeft />
        </button>
        <input
          data-searchbar
          ref={inputRef}
          name='keyword'
          onChange={onChange}
          type='text'
          placeholder={placeholder}
          autoComplete='off'
        />
        <button type='submit' aria-label='Search for stocks'>
          <Icon.MagnifyGlass strokeWidth={0.2} color={colors.text.default.brand} size={22} />
        </button>
      </form>

      <RecentSearchedKeywords
        onClick={handleClickRecentSearchKeyword}
        onXClick={handleClickKeywordXButton}
        data={recentSearchKeywords}
      />

      <ul
        css={css`
          flex-grow: 1;
          overflow: auto;
          display: flex;
          flex-direction: column;

          a {
            display: block;
            padding: 1rem 1.5rem;
            font-weight: 500;
          }
        `}
      >
        {fetching ? (
          <Box
            hAlign='center'
            direction='column'
            css={css`
              [data-testid='infinity-spin'] {
                width: 150px;
              }
            `}
          >
            <InfinitySpin visible={true} color={colors.border.default.brand} />
          </Box>
        ) : (
          keyword &&
          (data.length > 0 ? (
            data.map((item, idx) => (
              <SearchedListItem
                key={idx}
                keyword={keyword}
                onClick={() => saveRecentSearchKeywords(item.name)}
                ticker={item.ticker}
                name={item.name}
              />
            ))
          ) : (
            <Box p={1}>
              <p
                css={css`
                  color: ${colors.text.default.disabled};
                `}
              >
                검색결과가 없습니다.
              </p>
            </Box>
          ))
        )}
      </ul>
    </div>
  );
};
const SearchedListItem = ({ keyword, onClick, ticker, name }) => {
  const splitTexts = useCallback(({ name = '', keyword = '' }) => {
    // keyword 복사
    let k = keyword;
    // 1. index 찾기 1 : 검색어와 name이 동일할때
    let index = name.indexOf(k);
    // 2. index 찾기 2 : 검색어와 name이 동일하지 않을 때
    if (index < 0) {
      k = k.toLowerCase();
      index = name.toLowerCase().indexOf(k);
    }

    // 하이라이트 인덱스 = 검색어와 주식 이름이 일치하는 자리의 index
    let highlightIndex;
    // 하이라이트 처리를 위해 주식 이름을 분리하여 배열로 줄것임
    const texts = [];

    // 주식 이름에서 검색어와 일치하지 않는 주식이 결과값으로 나오기도 함
    // t&d -> 서부T&D, HL D&I
    if (index < 0) {
      texts.push(name);
      return { highlightIndex, texts };
    }

    // 주식 이름을 쪼개서 분리하는 작업
    name.split('').forEach((str, idx) => {
      // 하이라이트 처리되는 부분
      // 일치하는 자리와 일치하는 길이를 체크하여 texts 배열에 삽입
      if (idx === index) {
        texts.push(name.substring(idx, idx + k.length));
        highlightIndex = texts.length - 1;
      } else if (idx < index || idx >= index + k.length) {
        if (
          texts.length === 0 ||
          (typeof highlightIndex === 'number' && texts.length - 1 === highlightIndex)
        ) {
          texts.push(str);
        } else texts[texts.length - 1] = texts[texts.length - 1] + str;
      }
    });

    return { highlightIndex, texts };
  }, []);
  const { highlightIndex, texts } = splitTexts({ name, keyword });
  const stateForLink = { name };
  return (
    <li>
      <Link to={`/ai-explore/${ticker}`} state={stateForLink} onClick={onClick}>
        <span>
          {texts.map((text, idx) =>
            idx === highlightIndex ? (
              <span
                key={`${keyword}-${idx}`}
                css={css`
                  color: ${colors.text.default.brand};
                `}
              >
                {text}
              </span>
            ) : (
              text
            )
          )}
        </span>
      </Link>
    </li>
  );
};
const RecentSearchedKeywords = memo(function RecentSearchedKeywords({ onClick, onXClick, data }) {
  return (
    <Box
      element='ul'
      direction='row'
      px={1}
      py={0.5}
      gap={0.5}
      className='scroll'
      css={css`
        overflow-x: scroll;
        @media (min-width: 768px) {
          /* Webkit Browsers (Chrome, Safari) */
          ::-webkit-scrollbar {
            width: 10px;
            height: 10px;
          }

          ::-webkit-scrollbar-track {
            background: #00000011;
          }
          ::-webkit-scrollbar-track:disabled {
            background: transparent;
          }

          ::-webkit-scrollbar-thumb {
            background: #00000022;
          }

          ::-webkit-scrollbar-thumb:hover {
            background: #00000055;
          }

          /* Firefox */
          /* scrollbar-color thumb track */
          * {
            scrollbar-width: thin;
            scrollbar-color: #00000022 #00000011;
          }

          /* IE10+ */
          .scrollbar-ie {
            -ms-overflow-style: -ms-autohiding-scrollbar;
          }

          /* Edge */
          .scrollbar-edge {
            -ms-overflow-style: -ms-autohiding-scrollbar;
          }
        }
      `}
    >
      {data.map((item, idx) => (
        <li key={item}>
          <ChipButton
            text={item}
            onClick={async () => await onClick(item)}
            onXClick={() => onXClick(idx)}
          />
        </li>
      ))}
    </Box>
  );
});
