// Page 컴포넌트 컨텍스트
// document 사이즈

import React, { createContext, useCallback, useContext, useLayoutEffect, useState } from 'react';

import getFontSize from '@utils/getFontSize';

const PageContext = createContext();

export const usePage = () => useContext(PageContext);

export const PageProvider = ({ children }) => {
  const [pageHTMLElement, setPageHTMLElement] = useState(null);
  const [clientWidth, setClientWidth] = useState(0);
  const [offsetWidth, setOffsetWidth] = useState(0);
  const contentWidth = clientWidth > 0 ? clientWidth - getFontSize() * 2 : 0;

  const handleResize = useCallback(() => {
    setClientWidth(document.getElementById('page')?.clientWidth);
    setOffsetWidth(document.getElementById('page')?.offsetWidth);
  }, []);

  useLayoutEffect(() => {
    setClientWidth(document.getElementById('page')?.clientWidth);
    setOffsetWidth(document.getElementById('page')?.offsetWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <PageContext.Provider
      value={{
        pageHTMLElement,
        setPageHTMLElement,
        clientWidth,
        contentWidth,
        offsetWidth
      }}
    >
      {children}
    </PageContext.Provider>
  );
};
