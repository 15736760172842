import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from '../components/Modal';

export default function RegisterModal({ open, close }) {
  const navigate = useNavigate();
  useEffect(() => {
    const modalElement = document.getElementById('user-modal');
    if (open) {
      // 첫 번째 requestAnimationFrame은 브라우저에게 CSS 변경을 준비하도록 합니다.
      requestAnimationFrame(() => {
        // 두 번째 requestAnimationFrame은 실제 CSS 변경이 적용되도록 합니다.
        requestAnimationFrame(() => {
          if (modalElement) {
            modalElement.classList.add('active');
          }
        });
      });
    } else {
      if (modalElement) {
        modalElement.classList.remove('active');
      }
    }
  }, [open]);
  return (
    <Modal open={open} close={close} icon>
      <Modal.Content
        title="회원 전용 서비스입니다"
        suggestion="프렉탈 솔루션에 가입하시겠습니까?"
        description={`프렉탈 솔루션에 가입하셔서\n누구보다 편안한 투자를 경험해보세요!`}
      />
      <Modal.Actions
        onNegativeBtnClick={close}
        onPositiveBtnClick={() => navigate('/sign-up')}
      />
    </Modal>
  );
}
