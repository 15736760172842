import styled from '@emotion/styled';

const shouldForwardProp = (prop) => !['active'].includes(prop);
const HashtagClickableWrapper = styled('button', { shouldForwardProp })((props) => {
  return {
    margin: 0,
    userSelect: 'none',
    fontWeight: props.active ? 600 : 500,
    color: props.active ? 'white' : '#3965dd',
    backgroundColor: props.active ? '#3965dd' : '#f0f6ff',
    padding: '0.5rem',
    borderRadius: '4px'
  };
});
const HashtagNonClickableWrapper = styled('span', { shouldForwardProp })((props) => {
  return {
    margin: 0,
    userSelect: 'none',
    fontWeight: props.active ? 600 : 500,
    color: props.active ? 'white' : '#3965dd',
    backgroundColor: props.active ? '#3965dd' : '#f0f6ff',
    padding: '0.5rem',
    borderRadius: '4px'
  };
});
export default function Hashtag({ text = 'Hashtag', onClick, children, active }) {
  if (onClick)
    return (
      <HashtagClickableWrapper onClick={onClick} active={active}>
        {text ? '#' + text : children}
      </HashtagClickableWrapper>
    );
  return (
    <HashtagNonClickableWrapper active={active}>
      {text ? '#' + text : children}
    </HashtagNonClickableWrapper>
  );
}
