import styled from '@emotion/styled';

const shouldForwardPropTabs = (prop) => !['gap'].includes(prop);
const TabsWrapper = styled('ul', { shouldForwardProp: shouldForwardPropTabs })((props) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    '> *': {
      marginRight: !isNaN(props.gap) ? `${props.gap}rem` : 0
    },
    '> :last-child': {
      marginRight: 0
    }
  };
});
export default function Tabs({ tabs, children, css, gap }) {
  return (
    <TabsWrapper css={css} gap={gap}>
      {children}
    </TabsWrapper>
  );
}

const shouldForwardPropItem = (prop) => !['sameWidth'].includes(prop);
const ItemWrapper = styled('li', { shouldForwardProp: shouldForwardPropItem })((props) => {
  return {
    flex: props.sameWidth ? 1 : 'auto'
  };
});
function Item({ children, css, sameWidth }) {
  return (
    <ItemWrapper css={css} sameWidth={sameWidth}>
      {children}
    </ItemWrapper>
  );
}

Tabs.Item = Item;
