import Button from './Button';
import { useEffect } from 'react';

export default function Modal({ open, close, children, icon }) {
  useEffect(() => {
    const modalElement = document.getElementById('user-modal');
    if (open) {
      // 첫 번째 requestAnimationFrame은 브라우저에게 CSS 변경을 준비하도록 합니다.
      requestAnimationFrame(() => {
        // 두 번째 requestAnimationFrame은 실제 CSS 변경이 적용되도록 합니다.
        requestAnimationFrame(() => {
          if (modalElement) {
            modalElement.classList.add('active');
          }
        });
      });
    } else {
      if (modalElement) {
        modalElement.classList.remove('active');
      }
    }
  }, [open]);

  if (!open) return <></>;
  return (
    <div id='user-modal' className='modal-wrapper'>
      <div className='modal-overlay' onClick={close} />
      <div className='custom-modal system-modal'>
        {icon && (
          <div className='modal-icon'>
            <img
              src='/assets/images/icon/icon-modal-checked.svg'
              alt='모달 아이콘'
              className='icon icon-32'
            />
          </div>
        )}
        {children}
      </div>
    </div>
  );
}

function Content({ title, suggestion, description, children }) {
  if (children) return <div className='modal-content-wrap'>{children}</div>;
  return (
    <div className='modal-content-wrap'>
      {title && <h2 className='modal-ttl'>{title}</h2>}
      <div className='modal-content'>
        <p className='modal-ttl type-md'>{suggestion}</p>
        <p className='modal-desc'>{description}</p>
      </div>
    </div>
  );
}

function Actions({
  textNegative = '아니오',
  onNegativeBtnClick,
  textPositive = '예',
  onPositiveBtnClick
}) {
  return (
    <div className='modal-btn-wrap'>
      <Button text={textNegative} onClick={onNegativeBtnClick} color='secondary' />
      <Button text={textPositive} onClick={onPositiveBtnClick} />
    </div>
  );
}

Modal.Content = Content;
Modal.Actions = Actions;
