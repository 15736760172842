/** @jsxImportSource @emotion/react */

import 'react-circular-progressbar/dist/styles.css';

import React, { useState } from 'react';

import Box from '@components/Box';
import Button from '@components/Button';
import Chip from '@components/Chip';
import Icon from '@components/Icon';
import Page from '@components/Page';
import { colors } from '@constants/color';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

export default function AITestAdminPage() {
  const navigate = useNavigate();

  const [targetPrice, setGoalPrice] = useState(400);
  const [entryPrice, setEntryPrice] = useState(200);
  const [expectedRor, setExpectedRor] = useState(200);
  const [stopLossPrice, setStopLossPrice] = useState(160);

  const [dateToBuy, setDateToBuy] = useState('');
  const [dateToSell, setDateToSell] = useState('');
  const [dates, setDates] = useState([]);

  const controlSignal = () => {
    localStorage.setItem(
      'HIGHSIGNAL.data',
      JSON.stringify({ targetPrice, entryPrice, expectedRor, stopLossPrice, dates })
    );
  };

  return (
    <Page p={1}>
      <form
        css={css`
          width: 100%;
          & > div {
            display: grid;
            grid-template-columns: 1fr 2fr;
            align-items: center;
            gap: 1rem;
            div {
              display: inline-flex;
              align-items: center;
            }
          }
          input {
            width: 100%;
            padding: 1rem 0;
            border-bottom: 1px solid ${colors.gray};
          }
          input[type='number'] {
            text-align: right;
          }
        `}
      >
        <div>
          <label>목표가</label>
          <input
            name='goal-price'
            type='number'
            step='1'
            min='0'
            placeholder='목표가'
            value={targetPrice}
            onChange={(event) => setGoalPrice(event.target.value)}
          />
        </div>
        <div>
          <label>진입가</label>
          <input
            name='entry-price'
            type='number'
            step='1'
            min='0'
            placeholder='진입가'
            value={entryPrice}
            onChange={(event) => setEntryPrice(event.target.value)}
          />
        </div>
        <div>
          <label>기대 수익률 (%)</label>
          <input
            name='expected-ror'
            type='number'
            step='0.01'
            placeholder='기대 수익률 (%)'
            value={expectedRor}
            onChange={(event) => setExpectedRor(event.target.value)}
          />
        </div>
        <div>
          <label>손절가</label>
          <input
            name='stop-loss-price'
            type='number'
            step='1'
            min='0'
            placeholder='손절가'
            value={stopLossPrice}
            onChange={(event) => setStopLossPrice(event.target.value)}
          />
        </div>
        <div>
          <label>Buy</label>
          <div>
            <input
              name='date-to-buy'
              type='date'
              placeholder='YYYY-MM-DD'
              value={dateToBuy}
              onChange={(event) => setDateToBuy(event.target.value)}
            />
            <Button
              type='button'
              form='add-buy'
              text='추가'
              wFit
              size='sm'
              disabled={!dateToBuy}
              onClick={() => {
                const notIncluded = dates.findIndex((o) => o.date === dateToBuy) === -1;
                if (notIncluded) setDates((prev) => [...prev, { type: 'buy', date: dateToBuy }]);
              }}
            />
          </div>
        </div>
        <div>
          <label>Sell</label>
          <div>
            <input
              name='date-to-sell'
              type='date'
              placeholder='YYYY-MM-DD'
              value={dateToSell}
              onChange={(event) => setDateToSell(event.target.value)}
            />
            <Button
              type='button'
              form='add-sell'
              text='추가'
              wFit
              size='sm'
              disabled={!dateToSell}
              onClick={() => {
                const notIncluded = dates.findIndex((o) => o.date === dateToSell) === -1;
                if (notIncluded) setDates((prev) => [...prev, { type: 'sell', date: dateToSell }]);
              }}
            />
          </div>
        </div>
      </form>
      <Box gap={1}>
        <h6>buy & sell 날짜 목록</h6>
        <Box>
          {dates.map((item, idx) => {
            return (
              <div
                key={idx}
                css={css`
                  width: 100%;
                  display: flex;
                  align-items: center;
                  margin-bottom: 0.5rem;
                  > span:first-of-type {
                    width: 30px;
                  }
                  > p {
                    width: 100%;
                    padding: 0.5rem;
                  }
                  > button {
                    margin: 0;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    background-color: ${colors.gray};
                    padding: 0.5em;
                    cursor: pointer;
                  }
                `}
              >
                <span>{idx + 1}</span>
                <Chip text={item.type} />
                <p className='w-full'>{item.date}</p>
                <button
                  onClick={() => setDates((prev) => prev.filter((o) => o.date !== item.date))}
                >
                  <Icon.X />
                </button>
              </div>
            );
          })}
        </Box>
      </Box>
      <div
        css={css`
          width: 100%;
          position: relative;
          z-index: 1;
          padding: 1rem;
          display: inline-flex;
        `}
      >
        <Button text='테스트 페이지로' color='secondary' onClick={() => navigate('/ai-explore')} />
        <Button text='보내기' onClick={controlSignal} />
      </div>
    </Page>
  );
}
