// types -> 원하는 series data 타입들
// area, bar, baseline, candlestick(default), histogram, line, whitespace
// https://tradingview.github.io/lightweight-charts/docs/api/interfaces/IChartApiBase#removeseries
// area https://tradingview.github.io/lightweight-charts/docs/api/interfaces/AreaData
// bar https://tradingview.github.io/lightweight-charts/docs/api/interfaces/BarData
// baseline https://tradingview.github.io/lightweight-charts/docs/api/interfaces/BaselineData
// ✅candlestick https://tradingview.github.io/lightweight-charts/docs/api/interfaces/CandlestickData
// ✅histogram https://tradingview.github.io/lightweight-charts/docs/api/interfaces/HistogramData
// line https://tradingview.github.io/lightweight-charts/docs/api/interfaces/LineData

import addTransparencyOnHexColor from './addTransparencyOnHexColor';
import { colors } from '@constants/color';

// whitespace https://tradingview.github.io/lightweight-charts/docs/api/interfaces/WhitespaceData
export const standardizeRawDataToSeriesData = (
  { data = [], buySignals = [], sellSignals = [], targetPrice, stopLossPrice },
  types = ['candlestick'],
  options
) => {
  if (!Array.isArray(data)) {
    console.error('data is not an array.');
    return [];
  }

  // date 오름 차순 정렬
  data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  // date 중복 처리
  const removeDuplicateDates = (arr) => {
    const seenDates = new Set();
    return arr.filter((item) => {
      if (seenDates.has(item.date)) {
        return false;
      } else {
        seenDates.add(item.date);
        return true;
      }
    });
  };
  const uniqueData = removeDuplicateDates(data);

  // time 과거 -> 현재순
  const area = [];
  const bar = [];
  const baseline = [];
  const candlestick = [];
  const histogram = [];
  const line = [];
  const whitespace = [];
  const histogramBuySignals = [];
  const histogramSellSignals = [];

  for (let i = 0; i < uniqueData.length; i++) {
    const {
      date,
      opening_price,
      high_price,
      low_price,
      closing_price,
      volume,
      value,
      price,
      signal
    } = uniqueData[i];
    const time = new Date(date).getTime() / 1000;
    if (types.includes('area')) {
      area.push({
        time
        // value, lineColor, topColor, bottomColor, customValues
      });
    }
    if (types.includes('bar')) {
      bar.push({
        time,
        open: opening_price,
        high: high_price,
        low: low_price,
        close: closing_price
        // color, customValues
      });
    }
    if (types.includes('baseline')) {
      baseline.push({
        time
        // value, topFillColor1, topFillColor2, topLineColor, bottomFillColor1, bottomFillColor2, bottomLineColor customValues
      });
    }
    if (types.includes('candlestick')) {
      candlestick.push({
        time,
        open: opening_price,
        high: high_price,
        low: low_price,
        close: closing_price,
        color: opening_price < closing_price ? colors.stock.up.strong : colors.stock.down.strong,
        borderColor:
          opening_price < closing_price ? colors.stock.up.strong : colors.stock.down.strong,
        wickColor: opening_price < closing_price ? colors.stock.up.strong : colors.stock.down.strong
        //   customValues
      });
    }
    if (types.includes('histogram')) {
      histogram.push({
        time,
        value: volume,
        color:
          opening_price < closing_price
            ? addTransparencyOnHexColor(colors.stock.up.normal, 70)
            : addTransparencyOnHexColor(colors.stock.down.normal, 70)
        //  customValues
      });
    }
    if (types.includes('line')) {
      let c;
      switch (signal) {
        case 'ascend':
          c = colors.stock.up.strong;
          break;
        case 'descend':
          c = colors.stock.down.strong;
          break;
        default:
          break;
      }
      const item = {
        time,
        value: value || price || closing_price
        // customValues
      };
      if (c) item.color = c;
      line.push(item);
    }
    if (types.includes('whitespace')) {
      whitespace.push({
        time
        //  customValues
      });
    }

    const pushBuySignal = () => {
      const buySignal = buySignals.find((value) => value === date);
      const item = { time };
      if (buySignal) {
        item.value = typeof targetPrice === 'number' ? targetPrice : 100;
        item.color =
          options?.buySignalColor || addTransparencyOnHexColor(colors.bg.default.success, 20);
      }
      histogramBuySignals.push(item);
    };
    pushBuySignal();
    const pushSellSignal = () => {
      const sellSignal = sellSignals.find((value) => value === date);
      const item = { time };
      if (sellSignal) {
        item.value = typeof stopLossPrice === 'number' ? stopLossPrice : 100;
        item.color =
          options?.sellSignalColor || addTransparencyOnHexColor(colors.bg.default.warning, 20);
      }
      histogramSellSignals.push(item);
    };
    pushSellSignal();
  }

  const result = {};
  if (types.includes('area')) result.area = area;
  if (types.includes('bar')) result.bar = bar;
  if (types.includes('baseline')) result.baseline = baseline;
  if (types.includes('candlestick')) result.candlestick = candlestick;
  if (types.includes('histogram')) result.histogram = histogram;
  if (types.includes('line')) result.line = line;
  if (types.includes('whitespace')) result.whitespace = whitespace;
  result.histogramBuySignals = histogramBuySignals;
  result.histogramSellSignals = histogramSellSignals;
  return result;
};
