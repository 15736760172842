import { HEADER_HEIGHT } from '@constants/ui';
import Icon from '@components/Icon';
import { colors } from '@constants/color';
import styled from '@emotion/styled';
import { useColorScheme } from '@contexts/ColorSchemeContext';

const shouldForwardProp = (prop) => !['isDarkMode', 'bgColor'].includes(prop);
const HeaderWrapper = styled('header', { shouldForwardProp })((props) => {
  return {
    position: 'sticky',
    top: 0,
    backgroundColor: props.bgColor ? props.bgColor : 'var(--color-bg)',
    zIndex: 'var(--z-index-2)',
    width: '100%',
    height: HEADER_HEIGHT,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    ...(props.isDarkMode && { color: 'white' })
  };
});
const BackButtonWrapper = styled('button')((props) => {
  return {
    position: 'absolute',
    width: '1.6rem',
    height: '1.6rem',
    marginTop: 0,
    marginLeft: '-0.5rem',
    left: '1rem',
    padding: 0,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    'span:first-of-type': {
      display: 'none'
    }
  };
});
const TitleWrapper = styled('h1', { shouldForwardProp })((props) => {
  return {
    fontSize: '1.1rem',
    fontWeight: 600,
    color: props.isDarkMode ? colors.white : colors.blacks.light
  };
});
const RightContentWrapper = styled('div')((props) => {
  return {
    position: 'absolute',
    marginTop: 0,
    right: '1rem'
  };
});
export default function Header({ title, onArrowClick, renderRightContent, bgColor }) {
  const { isDarkMode } = useColorScheme();
  return (
    <HeaderWrapper isDarkMode={isDarkMode} bgColor={bgColor}>
      <BackButton onClick={onArrowClick} />
      <Title text={title} />
      {typeof renderRightContent === 'function' && (
        <RightContentWrapper>{renderRightContent()}</RightContentWrapper>
      )}
    </HeaderWrapper>
  );
}
function BackButton({ onClick }) {
  return (
    <BackButtonWrapper type='button' onClick={onClick} aria-label='Go back'>
      <Icon.ChevronLeft strokeWidth={1.5} color='var(--color-f)' />
    </BackButtonWrapper>
  );
}
function Title({ text }) {
  if (!text) return null;
  return <TitleWrapper>{text}</TitleWrapper>;
}
