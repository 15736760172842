/** @jsxImportSource @emotion/react */

import 'dayjs/locale/ko';

import { Suspense, lazy, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Box from '@components/Box';
import Button from '@components/Button';
import Checkbox from '@components/Checkbox';
import CirclePercentage from '@components/CirclePercentage';
import ErrorBoundary from '@components/ErrorBoundary';
import ErrorElement from '@components/ErrorElement';
import { HEADER_HEIGHT } from '@constants/ui';
import { InfinitySpin } from 'react-loader-spinner';
import InfoSheet from '@components/InfoSheet';
import Loading from '@components/Loading';
import Page from '@components/Page';
import Tooltip from '@components/Tooltip';
import { colors } from '@constants/color';
import { css } from '@emotion/react';
import currencyConvertor from '@utils/currencyConvertor';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import getFontSize from '@utils/getFontSize';
import isValidHTMLElement from '@utils/isValidHTMLElement';
import { standardizeRawDataToSeriesData } from '@utils/standardizeRawDataToSeriesData';
import { useColorScheme } from '@contexts/ColorSchemeContext';
import useFetch from '@hooks/useFetch';
import { usePage } from '@contexts/PageContext';
import { useTutorial } from '@contexts/TutorialContext';

const LightweightChart = lazy(() => import('../components/LightweightChart'));

export default function AIExplorePage() {
  // react-router-dom
  const { ticker } = useParams();
  const navigate = useNavigate();
  // custom hooks
  const { setTutorialElement, tutorialCompleted } = useTutorial();
  const { isDarkMode, setColorSchemeLight } = useColorScheme();
  const { contentWidth, pageHTMLElement } = usePage();
  // fetch hook
  const { data, isFirstLoaded, fetching, getData, initializeData, resetFirstLoaded } = useFetch({
    initialData: {
      name: '',
      ticker,
      market: '',
      current_price: 0,
      daily_fluctuation_rate: 0,
      expected_return_20days: 0,
      increase_probability: 0,
      target_price: 0,
      stop_loss_price: 0,
      buy_signals: [],
      sell_signals: [],
      candles: []
    }
  });

  // state for UI
  const [currency] = useState('KRW'); // 환율
  const [interval, setInterval] = useState('day'); // 주가 차트 - 일봉, 주봉, 월봉
  const [showPrevPredictions, setShowPrevPredictions] = useState(false); // 과거 예측 내역 보기
  const [showNextPredictions, setShowNextPredictions] = useState(false); // 예측 시작 버튼
  // candles
  const [day, setDay] = useState([]); // 일봉
  const [week, setWeek] = useState([]); // 주봉
  const [month, setMonth] = useState([]); // 월봉
  // volumes
  const [dayVolumes, setDayVolumes] = useState([]); // 일
  const [weekVolumes, setWeekVolumes] = useState([]); // 주
  const [monthVolumes, setMonthVolumes] = useState([]); // 월
  // scenarios
  const [dayScenarios, setDayScenarios] = useState([[], [], []]); // 일
  const [weekScenarios, setWeekScenarios] = useState([[], [], []]); // 주
  const [monthScenario, setMonthScenarios] = useState([[], [], []]); // 월

  // interval 별로 data map 생성
  const candlestickSerieses = new Map([
    ['day', day],
    ['week', week],
    ['month', month]
  ]);
  const histogramSeriesesVolumes = new Map([
    ['day', dayVolumes],
    ['week', weekVolumes],
    ['month', monthVolumes]
  ]);
  const lineSeriesesScenarios = new Map([
    ['day', dayScenarios],
    ['week', weekScenarios],
    ['month', monthScenario]
  ]);

  const onHeaderArrowClick = () => {
    navigate('/main');
    setColorSchemeLight();
  };
  const set3TutorialElement = (ref) => setTutorialElement(3, ref, { direction: 'bottom' });
  const togglePrevPredictions = () => setShowPrevPredictions((prev) => !prev);
  const startPrediction = () => {
    setShowNextPredictions((prev) => !prev);
    if (isValidHTMLElement(pageHTMLElement)) {
      setTimeout(() => {
        const top = parseInt(HEADER_HEIGHT) * getFontSize(pageHTMLElement);
        pageHTMLElement?.scrollTo?.({
          top,
          left: 0,
          behavior: 'smooth'
        });
      }, 80);
    }
  };

  // lightweight chart 데이터 규격에 맞게 변경
  const handleDataForLightweightChart = useCallback(
    ({ data, targetPrice, stopLossPrice }) => {
      let candles, volumes, scenarios;
      try {
        const { candlestick, histogram, line } = standardizeRawDataToSeriesData(
          { data, targetPrice, stopLossPrice },
          ['candlestick', 'histogram', 'line']
        );

        candles = candlestick;
        volumes = histogram;

        // 차트 예측 시나리오 3가지 데모
        const lastUnix = line[line.length - 1].time;
        const lastValue = line[line.length - 1].value;
        let example1, example2, example3;
        const createTimeSlot = (arr) => {
          Array.from({ length: 6 }).reduce(
            (prevDayjs, _, idx) => {
              const isFriday = dayjs(prevDayjs).day() === 5;
              const nextDayjs = dayjs(prevDayjs).add(isFriday ? 3 : 1, 'day');
              arr[idx].time = new Date(nextDayjs).getTime() / 1000;
              return nextDayjs;
            },
            dayjs(lastUnix * 1000).add(1, 'day')
          );
          return arr;
        };
        if (ticker == '247540') {
          example1 = [
            { value: 191000 },
            { value: 193000 },
            { value: 189000 },
            { value: 191000 },
            { value: 212255 },
            { value: 225000 }
          ];
          example1 = createTimeSlot(example1);
          example2 = [
            { value: 180000 },
            { value: 185000 },
            { value: 190000 },
            { value: 185000 },
            { value: 255000 },
            { value: 275000 }
          ];
          example2 = createTimeSlot(example2);
          example3 = [
            { value: 191000 },
            { value: 195000 },
            { value: 200000 },
            { value: 200000 },
            { value: 191200 },
            { value: 225000 }
          ];
          example3 = createTimeSlot(example3);
        } else if (ticker == '055550') {
          example1 = [
            { value: 44500 },
            { value: 43000 },
            { value: 42700 },
            { value: 42500 },
            { value: 45400 },
            { value: 47500 }
          ];
          example1 = createTimeSlot(example1);
          example2 = [
            { value: 44500 },
            { value: 43000 },
            { value: 42700 },
            { value: 41000 },
            { value: 43000 },
            { value: 45000 }
          ];
          example2 = createTimeSlot(example2);
          example3 = [
            { value: 44500 },
            { value: 43000 },
            { value: 42700 },
            { value: 41500 },
            { value: 43500 },
            { value: 44200 }
          ];
          example3 = createTimeSlot(example3);
        } else {
          const createExample = (num) => {
            const example = [...line].slice(line.length - num - 10, line.length - num);

            Array.from({ length: 10 }).reduce(
              (prevDayjs, _, idx) => {
                const isFriday = dayjs(prevDayjs).day() === 5;
                const nextDayjs = dayjs(prevDayjs).add(isFriday ? 3 : 1, 'day');
                example[idx].time = new Date(nextDayjs).getTime() / 1000;
                if (idx === 0) example[idx].value = lastValue;
                return nextDayjs;
              },
              dayjs(lastUnix * 1000).add(1, 'day')
            );

            return example;
          };
          example1 = createExample(10);
          example2 = createExample(50);
          example3 = createExample(90);
        }
        scenarios = [example3, example2, example1];
      } catch (error) {
        console.error(error);
        enqueueSnackbar('데이터에 오류가 발생했습니다', { variant: 'error' });
      }
      return { candles, volumes, scenarios };
    },
    [ticker, interval]
  );
  const getStock = useCallback(async () => {
    await getData(`/stocks/${ticker}`, undefined, (prev, next) => {
      const { candles, volumes } = handleDataForLightweightChart({
        data: next.candles,
        targetPrice: next.target_price,
        stopLossPrice: next.stop_loss_price
      });
      const scenarioTypes = ['A', 'B', 'C'];
      const scenarios = [[], [], []];
      scenarioTypes.forEach((type, idx) => {
        if (type in next.scenarios && Array.isArray(next.scenarios[type])) {
          next.scenarios[type].sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
          );
          next.scenarios[type].forEach((o) => {
            scenarios[idx].push({
              time: new Date(o.date).getTime() / 1000,
              value: o.price
            });
          });
        }
      });

      switch (interval) {
        case 'day':
          setDay(candles);
          setDayVolumes(volumes);
          setDayScenarios(scenarios);
          break;
        case 'week':
          setWeek(candles);
          setWeekVolumes(volumes);
          break;
        case 'month':
          setMonth(candles);
          setMonthVolumes(volumes);
          break;
        default:
          break;
      }
      next.daily_fluctuation_rate = Math.ceil((next.daily_fluctuation_rate || 0) * 100) / 100;
      next.expected_return_20days = Math.ceil((next.expected_return_20days || 0) * 100) / 100;
      return next;
    });
  }, [ticker, interval]);
  const getCandlesOnly = useCallback(async () => {
    await getData(`/stocks/${ticker}/candles`, { interval }, (prev, next) => {
      const { candles, volumes } = handleDataForLightweightChart({ data: next });
      switch (interval) {
        case 'day':
          setDay(candles);
          setDayVolumes(volumes);
          break;
        case 'week':
          setWeek(candles);
          setWeekVolumes(volumes);
          break;
        case 'month':
          setMonth(candles);
          setMonthVolumes(volumes);
          break;
        default:
          break;
      }
      return prev;
    });
  }, [ticker, interval]);

  useEffect(() => {
    return () => {
      initializeData();
      resetFirstLoaded();
    };
  }, [ticker]);
  useEffect(() => {
    if (isFirstLoaded) {
      getCandlesOnly();
    } else {
      getStock();
    }
  }, [ticker, interval]);

  useLayoutEffect(() => {
    dayjs.locale('ko');
  }, []);

  if (!isFirstLoaded) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }
  return (
    <Page
      title='주가 예측'
      canonicalURI={'/ai-explore/' + ticker}
      forceUseHeightFull={showNextPredictions}
      disableBottomMargin
      onHeaderArrowClick={onHeaderArrowClick}
      headerBgColor={isDarkMode ? '#13182C' : '#F2F5FC'}
    >
      <Box
        bgColor='var(--color-bg)'
        fullHeight
        css={css`
          z-index: ${tutorialCompleted ? 'var(--z-index-2)' : 'auto'};
        `}
      >
        <Title data={data} currency={currency} />

        <Box>
          <Box px={1} pt={1} direction='row' vAlign='space-between'>
            <Checkbox
              checked={showPrevPredictions}
              onChange={togglePrevPredictions}
              label='과거 예측 내역 보기'
            />
            <Tooltip
              text={`주가예측 시나리오는\n예측 알고리즘이 새로 탐지될 때\n업데이트됩니다.`}
            />
          </Box>
          <ScenarioLegend />
          <Box px={1}>
            <ErrorBoundary fallback={<ErrorElement />}>
              <Box
                vAlign='center'
                css={css`
                  display: block;
                  position: relative;
                  min-height: ${contentWidth || 410}px;
                `}
              >
                {fetching ? (
                  <Box
                    hAlign='center'
                    direction='column'
                    css={css`
                      [data-testid='infinity-spin'] {
                        width: 150px;
                      }
                    `}
                  >
                    <InfinitySpin visible={true} color={colors.border.default.brand} />
                  </Box>
                ) : (
                  <Suspense>
                    <LightweightChart
                      interval={interval}
                      months={4}
                      height={contentWidth || 410}
                      histogramSeriesData={histogramSeriesesVolumes.get(interval)}
                      candlestickSeriesData={candlestickSerieses.get(interval)}
                      lineSeriesesData={lineSeriesesScenarios.get(interval)}
                      buySignals={data.buy_signals}
                      sellSignals={data.sell_signals}
                      targetPrice={data.target_price}
                      stopLossPrice={data.stop_loss_price}
                      showEntireCandles={showPrevPredictions}
                      showSignalMarkers={showPrevPredictions}
                      showPredictions={showNextPredictions}
                    />
                  </Suspense>
                )}
              </Box>
            </ErrorBoundary>
          </Box>
          {/* <IntervalSwitch interval={interval} setInterval={setInterval} /> */}
        </Box>

        {!showNextPredictions && (
          <Box ref={set3TutorialElement} p={1}>
            <Button text='예측 시작' onClick={startPrediction} size='lg' />
          </Box>
        )}
      </Box>

      <InfoSheet show={showNextPredictions} zIndex='var(--z-index-3)'>
        <InfoSheet.Content>
          <Box element='p' px={0.25} mb={1}>
            <b>A (1순위)</b>
          </Box>
          <Box direction='row' hAlign='center' gap={1}>
            <CirclePercentage text='상승 확률' percentage={data.increase_probability} />
            <Box
              element='dl'
              gap={0.5}
              css={css`
                dt {
                  color: ${colors.text.default.secondary};
                  font-weight: 500;
                }
                dd {
                  font-size: 1.2rem;
                  font-weight: bold;
                }
                dd > span {
                  color: ${Number(data.expected_return_20days) > 0
                    ? colors.text.default.dangerBold
                    : Number(data.expected_return_20days) === 0
                      ? colors.text.default.secondary
                      : colors.text.default.brand};
                }
              `}
            >
              <Box gap={0.25}>
                <dt>20일 기대수익률</dt>
                <dd>
                  <span>
                    {Number(data.expected_return_20days) > 0
                      ? `+${data.expected_return_20days}`
                      : data.expected_return_20days}
                    %
                  </span>
                </dd>
              </Box>
              <Box gap={0.25}>
                <dt>20일 예상 목표가</dt>
                <dd>
                  {!isNaN(data.target_price) ? (
                    <>
                      <span>
                        {currencyConvertor(data.target_price, {
                          withSign: true,
                          signType: 'localized',
                          currency
                        })}
                      </span>
                    </>
                  ) : (
                    '-'
                  )}
                </dd>
              </Box>
            </Box>
          </Box>
        </InfoSheet.Content>
      </InfoSheet>
    </Page>
  );
}

function Title({ data, currency }) {
  // react-router-dom
  const { state } = useLocation();
  // custom hooks
  const { isDarkMode } = useColorScheme();
  return (
    <Box gap={0.25} p={1} bgColor={isDarkMode ? '#13182C' : '#F2F5FC'}>
      <Box
        direction='row'
        gap={0.5}
        css={css`
          display: inline-flex;
          flex-wrap: wrap;
          h1 {
            font-size: 1.4rem;
            display: inline-block;
            color: ${isDarkMode ? colors.white : colors.blacks.light};
          }
          p {
            margin-top: 2px;
            display: inline-block;
            color: ${isDarkMode ? colors.text.default.tertiary : colors.text.default.secondary};
          }
        `}
      >
        <h1>{data.name || state?.kor_name || state?.name}</h1>
        <p>
          <span>{data.ticker}</span> / <span>{data.market}</span>
        </p>
      </Box>
      <Box
        direction='row'
        hAlign='end'
        gap={0.5}
        css={css`
          h2 {
            font-size: 1.4rem;
            color: ${isDarkMode ? colors.white : colors.blacks.light};
          }
          p {
            font-weight: 600;
            color: ${Number(data.daily_fluctuation_rate) > 0
              ? colors.text.default.dangerBold
              : Number(data.daily_fluctuation_rate) === 0
                ? colors.text.default.secondary
                : colors.text.default.brand};
          }
          p > span {
            vertical-align: baseline;
            font-size: 0.75rem;
          }
        `}
      >
        <h2>
          {currencyConvertor(data.current_price, {
            withSign: true,
            signType: 'localized',
            currency
          })}
        </h2>
        <p>
          <span>
            {Number(data.daily_fluctuation_rate) > 0
              ? '▲ '
              : Number(data.daily_fluctuation_rate) < 0
                ? '▼ '
                : ''}
          </span>
          {Math.abs(data.daily_fluctuation_rate)}%
        </p>
      </Box>
    </Box>
  );
}
function ScenarioLegend() {
  return (
    <Box direction='row' gap={0.6} p={1}>
      <Box
        direction='row'
        hAlign='center'
        gap={0.4}
        css={css`
          p {
            font-size: 0.85rem;
            font-weight: 600;
            white-space: nowrap;
          }
        `}
      >
        <p>A (1순위)</p>
        <Box
          css={css`
            height: 3px;
            width: 36px;
            border-top: 3px solid ${colors.text.default.danger};
          `}
        />
      </Box>
      <Box
        direction='row'
        hAlign='center'
        gap={0.4}
        css={css`
          p {
            font-size: 0.85rem;
            font-weight: 600;
            white-space: nowrap;
          }
        `}
      >
        <p>B (2순위)</p>
        <Box
          css={css`
            height: 3px;
            width: 36px;
            border-top: 3px dashed ${colors.text.default.warning};
          `}
        />
      </Box>
      <Box
        direction='row'
        hAlign='center'
        gap={0.4}
        css={css`
          p {
            font-size: 0.85rem;
            font-weight: 600;
            white-space: nowrap;
          }
        `}
      >
        <p>C (3순위)</p>
        <Box
          css={css`
            height: 3px;
            width: 36px;
            border-top: 3px dashed ${colors.text.default.disabled};
          `}
        />
      </Box>
    </Box>
  );
}
// function IntervalSwitch({ interval, setInterval }) {
//   const handleChangeInterval = (event) => setInterval(event.target.value);
//   const handleClickInterval = (event) => {
//     if (event.target.value !== 'day') {
//       enqueueSnackbar('현재 일봉 조회만 지원하고 있습니다.', {
//         variant: 'warning'
//       });
//     }
//   };
//   return (
//     <Box
//       p={1}
//       element='form'
//       name='interval'
//       onChange={handleChangeInterval}
//       onClick={handleClickInterval}
//     >
//       <Box element='fieldset' direction='row' gap={0.25} radius='12px'>
//         <Button
//           inputType='radio'
//           name='interval'
//           value='day'
//           color='text'
//           size='sm'
//           text='1 일'
//           checked={interval === 'day'}
//           readOnly
//         />
//         <Button
//           inputType='radio'
//           name='interval'
//           value='week'
//           color='text'
//           size='sm'
//           text='1 주'
//           checked={interval === 'week'}
//           readOnly
//           disabled
//         />
//         <Button
//           inputType='radio'
//           name='interval'
//           value='month'
//           color='text'
//           size='sm'
//           text='1 달'
//           checked={interval === 'month'}
//           readOnly
//           disabled
//         />
//       </Box>
//     </Box>
//   );
// }
