export const blues = {
  primary: '#215dff',
  strong: '#2f54eb',
  light: '#5b88ff',
  lighter: '#92b0ff',
  lightest: '#f0f6ff',
  dark: '#3965dd',
  dim: '#91abed',
  dimmer: '#3c67de'
};
export const reds = {
  primary: '#f5222d',
  light: '#ff7875',
  bright: '#ff5b57'
};
export const grays = {
  primary: '#f5f5f5',
  light: '#d6deeb',
  medium: '#d6deeb',
  dark: '#b2b9c6',
  darker: '#868f9f',
  third: '#6c6e75'
};
export const black = '#13182c';

const success = '#52c41a';
const danger = '#ff4d4f';
const warning = '#fa8c16';

const bg = {
  default: {
    // subtle -> 2로 바꿔서 네이밍함
    primary: '#ffffff',
    secondary: '#fafafa',
    tertiary: '#f5f5f5',
    brand: '#2f54eb',
    brand2: '#f1f5fe',
    brandSub: '#fbbfc1',
    brandSub2: '#ebfefb',
    success,
    success2: '#f8ffef',
    danger,
    danger2: '#fdf2f0',
    warning,
    warning2: '#fef8e8',
    // disable이라고 컬러차트를 뽑아놓으셨는데 disabled라고 바꿔서 네이밍함
    disabled: '#f5f5f5',
    inverse: '#1f1f1f'
  },
  interactive: {
    primary: '#4252e4',
    primaryPress: '#597ef7',
    primaryActive: '#1d39c4',
    secondary: '#ffffff',
    secondaryPress: '#fafafa',
    secondaryActive: '#f5f5f5'
  }
};
const text = {
  default: {
    primary: '#262626',
    secondary: '#595959',
    tertiary: '#bfbfbf',
    brand: '#2f54eb',
    brandBold: '#1d39c4',
    brandSub: '#13c2c2',
    brandSubBold: '#08979c',
    success,
    successBold: '#389e0d',
    danger,
    dangerBold: '#f5222d',
    warning,
    warningBold: '#d46b08',
    disabled: '#bfbfbf',
    inverse: '#ffffff'
  },
  interactive: {
    secondary: '#262626',
    secondaryPress: '#2f54eb',
    secondaryActive: '#1d39c4'
  }
};
const border = {
  default: {
    primary: '#d9d9d9',
    secondary: '#f5f5f5',
    brand: '#2f54eb',
    brandSub: '#13c2c2',
    success,
    danger,
    warning,
    disabled: '#d9d9d9',
    inverse: '#ffffff'
  },
  interactive: {
    secondary: '#d9d9d9d9',
    secondaryPress: '#597ef7',
    secondaryActive: '#1d39c4'
  }
};
const stock = {
  down: {
    strong: '#096DD9',
    normal: '#69C0FF'
  },
  up: {
    strong: '#F5222D',
    normal: '#FFA39E'
  }
};
export const color = {
  red: reds.primary,
  reds,
  blue: blues.primary,
  blues,
  gray: grays.primary,
  grays,
  white: 'white',
  black,
  blacks: {
    light: '#262626',
    lighter: '#595959'
  },
  bg,
  text,
  border,
  stock
};
export const colors = {
  red: reds.primary,
  reds,
  blue: blues.primary,
  blues,
  gray: grays.primary,
  grays,
  white: 'white',
  black,
  blacks: {
    light: '#262626',
    lighter: '#595959'
  },
  bg,
  text,
  border,
  stock
};
