import axios, { AxiosError } from 'axios';

import { enqueueSnackbar } from 'notistack';
import formatNetworkErrorMessage from '@utils/formatNetworkErrorMessage';

let BASE_URL_DEV = 'http://34.47.95.227/v1';
let BASE_URL_STAGE = 'http://34.47.95.227/v1';
let BASE_URL_PROD = 'https://api.highsignal.kr/v1';

let URL = process.env.REACT_APP_ENV === 'production' ? BASE_URL_PROD : BASE_URL_DEV;

// Authentication X
const axiosWithoutAuthentication = (baseURL, config) => {
  const instance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json'
    },
    ...config
  });
  return instance;
};

// Authentication O
const axiosWithAuthentication = (baseURL, config) => {
  const token = localStorage.getItem('HIGHSIGNAL.token');
  const instance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    ...config
  });
  return instance;
};

const config = {
  timeout: 5000
};

const instance = axiosWithoutAuthentication(URL, config);
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    const customMessage = error?.response.data?.detail?.[0]?.msg || error?.response?.data?.detail;
    if (typeof enqueueSnackbar === 'function') {
      enqueueSnackbar(
        formatNetworkErrorMessage({
          message: error?.message,
          customMessage,
          code: error?.code,
          status: error?.response?.status
        }),
        {
          variant: 'error'
        }
      );
    }
    if (error instanceof AxiosError) error.customMessage = customMessage;
    console.error(error);
    return Promise.reject(error);
  }
);
const instanceAuth = axiosWithAuthentication(URL, config);
instanceAuth.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    const customMessage = error?.response.data?.detail?.[0]?.msg || error?.response?.data?.detail;
    if (typeof enqueueSnackbar === 'function') {
      enqueueSnackbar(
        formatNetworkErrorMessage({
          message: error?.message,
          customMessage,
          code: error?.code,
          status: error?.response?.status
        }),
        {
          variant: 'error'
        }
      );
    }
    if (error instanceof AxiosError) error.customMessage = customMessage;
    console.error(error);
    return Promise.reject(error);
  }
);

export { instance, instanceAuth };
